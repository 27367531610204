import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { SubscriptionService } from '../services/subscription.service';

import dropin from 'braintree-web-drop-in';
import { PaymentService } from '../services/payment.service';

@Component({
  selector: 'app-account-payment',
  templateUrl: './account-payment.component.html',
  styleUrls: ['./account-payment.component.css'],
})
export class AccountPaymentComponent implements OnInit {
  @Input() accountInformation;
  @Input() editing;
  public formLoaded = false;
  @Output() pending = new EventEmitter<Object>();
  @Output() startEdit = new EventEmitter<Boolean>();

  public paymentToken: string;
  public dropInDoneLoading = false;
  public updating = false;
  public hasExpired = false
  public updatingPayment = false;
  public nonce;
  public paymentInformation;
  private savePaymentInfo;
  private account_id;
  private paymentValid = false;
  public loading = false;
  private editingPayment = false;
  private none;
  
  constructor(private toastCtrl: ToastController, 
    private subscriptionService: SubscriptionService, 
    private paymentService: PaymentService,
    private elementRef: ElementRef) { 
      this.loading = true;
    }

  ngOnInit() {
    this.loading = true;
    if(this.accountInformation) {
      this.account_id = this.accountInformation.general.external_id;
      this.paymentService.getPaymentInfo(this.account_id).then((payment_response) => {
        this.paymentInformation = payment_response.payment;
        
        this.paymentService.getToken(this.account_id).then((response) => {
          this.setPaymentToken(response.client_token);                 
          this.showPaymentForm()             
        })
      })      
    }
  }

  showPaymentForm() {
    if(!this.formLoaded) {
      this.loadPaymentContainer();
    }
  }

  public save() {
    this.savePaymentInfo()
    this.pending.emit({is_pending: true});
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 1000,
      position: 'top'
    });

    toast.present();
  }

  public isValid() {
    return this.paymentValid;
  }

  private setPaymentToken(token) {
    this.paymentToken = token;
  }

  async loadPaymentContainer() { 
    try {
      var dropinContainer = this.elementRef.nativeElement.querySelector('#dropin-container');
      const component = this;

      dropinContainer.innerHTML = '';
      dropin.create({
        authorization: this.paymentToken,
        container: dropinContainer,
        vaultManager: true,
        card: {
          cardholderName: {
            required: true
          }
        }
      }, (err, dropinInstance) => {
        if(err) { console.log(err) }

        if (dropinInstance && dropinInstance.isPaymentMethodRequestable()) {
          dropinInstance.requestPaymentMethod(function (err, payload) {
            component.paymentService.setNonce(payload.nonce)
          })
          component.paymentValid = true;
        }

        component.savePaymentInfo = function () {        
          return new Promise((paymentUpdated) => {
            dropinInstance.requestPaymentMethod(function (err, payload) {
              if(payload) {
                component.paymentService.update(component.account_id, payload.nonce).then((payment_update_response) => {
                  component.paymentInformation = payload;
                  component.paymentService.setNonce(payload.nonce)
                  paymentUpdated({nonce: payload.nonce});
                })          
              }
            });
          })        
        };

        if(dropinInstance) {
          dropinInstance.on('paymentMethodRequestable', function (event) {
            // console.log(`paymentMethodRequestable`)
            // console.log(event)
            dropinInstance.requestPaymentMethod(function (err, payload) { 
              // console.log(payload)
              component.nonce = payload.nonce
              component.paymentService.setNonce(payload.nonce)
            })
            component.paymentValid = true;
          });
          
          dropinInstance.on('noPaymentMethodRequestable', function () {
            component.paymentValid = false;
            component.editingPayment = false;
          });

          dropinInstance.on('paymentOptionSelected', function(event) {
            // component.paymentValid = true;
            // if(event) {
            //   console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
            //   console.log(event.paymentMethodIsSelected); // True if a customer has selected a payment method when paymentMethodRequestable fires.
            // }
          })

          dropinInstance.on('changeActiveView', function(event) {
            // console.log(event.type); // The type of Payment Method, e.g 'CreditCard', 'PayPalAccount'.
            // console.log(event.paymentMethodIsSelected); // True if a customer has selected a payment method when paymentMethodRequestable fires.
            // console.log(event)
            component.editingPayment = ['card', 'methods'].includes(event.newViewId);
            component.startEdit.emit(component.editingPayment);
          })

          dropinInstance.on('blur', function (event) {
            console.log(event.emittedBy, 'lost focus');
          });
        }

        component.formLoaded = true;
        component.loading = false;
      });
    } catch(e) {
      console.log(`error loading payment form`)
      console.log(e)
    }
  }

  public sendPaymentNonce(nonce) {
    this.nonce = nonce;
  }
}
