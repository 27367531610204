import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { KilnService, kilnInfo, alarmType } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { NavigationExtras, ActivatedRoute } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-kiln-status-component',
  templateUrl: './kiln-status.component.html',
  styleUrls: ['./kiln-status.component.scss'],
})
export class KilnStatusComponent implements OnInit {
  @Input() kilnId;
  @Input() kiln_name;
  @Input() kiln;
  public email;
  public kiln_id;
  public token;
  public hasElevatedPermissions = false;
  public alarm: alarmType = {
    active: false,
    text:'',
    class:''
  };

  public update_class;
  public errorText = '';
  public alarmFlashClass: string = 'item-alarm-off';
  public editingName = false;
  public kilnNameForm;
  public role = 'user';
  public gotData = false;
  public kilnName;
  public updatedName;
  public notConnected = false;
  public updating = false;

  constructor(public navCtrl: NavController,
      public toastCtrl: ToastController,
      public kilnService: KilnService,
      public userService: UserService
  ) {
    this.hasElevatedPermissions = this.userService.hasElevatedPermissions();
    this.alarm = this.kilnService.selectedKilnAlarm;
    this.editingName = false;
    this.updating = true;

    this.kilnService.dataFetchingStatus.subscribe((fetching) => { if(fetching) { this.onTimerTick(); }})
    this.kilnService.timerStatus.subscribe( (data) => this.flashAlarm(data));
  }

  ngOnInit() {
    this.notConnected = false;
    this.updating = true;
    this.onGetKilnDataSuccess(this.kiln);
  }

  ngOnChanges(changes) {
    this.notConnected = false;
    this.updating = true;
    this.onGetKilnDataSuccess(this.kiln);
  }

  ngOnLeave() {
    this.updating = false;
    this.gotData = false;
    this.kiln = {
      name: this.kiln_name,
      serial_number: this.kilnId,
      status: {
        firing: {fire_hour: '', fire_min: '', set_pt: '', step: '', hold_hour: '', hold_min: '', cost: ''},
        mode: '',
        errorText: '',
        t1: '', t2: '', t3: '',
        error: null
      },
      config: {t_scale: 'F', num_zones: 1},
      program: {name: '', steps: [], alarm_t: ''},
      updatedAt: '',
      mode: 'default'
    };
    this.kilnId = "";
    this.kilnName = "";
  }

  public getKilnData() {
    let kilnName = new UntypedFormControl(this.kiln.name, [Validators.required]);
    this.kilnNameForm = new UntypedFormGroup({
      kilnName: kilnName
    });
  }

  public flashAlarm (alarmClass){
    this.alarmFlashClass = alarmClass;
  }

  private onGetKilnDataSuccess(kiln) {
    this.gotData = true;
    this.updating = false;
    this.update_class = ''; //temp
    this.alarm = this.kilnService.selectedKilnAlarm;
    this.errorText = ''; //clear any previous errors

    var to_update = kiln;
    // console.log(to_update);
    var hasUpdatedStatus = (to_update && to_update.status && to_update.status.mode != null);
    var hasUpdatedProgram = (to_update && to_update.program && to_update.program.alarm_t != null);

    let status = hasUpdatedStatus ? to_update.status : this.kiln.status;
    let program = hasUpdatedProgram ? to_update.program : this.kiln.program;
    this.kiln = {...to_update, ...{status: status, program: program}};
    this.role = this.kiln ? this.kiln['role'] : 'viewer';

    var isNotConnected = true;
    if(this.kiln) {
      isNotConnected = this.isNotConnected(this.kiln['updatedAt'])
      // console.log(moment(this.kiln.updatedAt).format('MM/DD/YYYY HH:mm:ss'));
    }

    if(isNotConnected && (this.kiln && this.kiln['status'])) {
      // this.kiln['status'].mode = 'not-connected';
      this.notConnected = true;
    }
  }

  public viewUsers() {
    let navigationExtras: NavigationExtras = {
      queryParams: {kiln: this.kiln, kiln_id: this.kiln_id}
    };
    this.navCtrl.navigateForward('kiln-users', navigationExtras);
  }

  public viewSettings() {
    let navigationExtras: NavigationExtras = {
      queryParams: {kiln: this.kiln, kiln_id: this.kiln.serial_number}
    };
    this.navCtrl.navigateForward('kiln-settings', navigationExtras);
  }

  public onTimerTick(){
    this.update_class = 'show-updating'; //temp
  }

  public nameEditSave(id) {
    this.kilnService.editName(this.kilnNameForm.value.kilnName, id)
        .then((data) => this.nameEdited({success: true, name: data}))
        .catch((error) => this.nameEdited({success: false, error: error}));
  }

  async nameEdited(data) {
    this.editingName = false;
    var msg = data['success'] ? 'Name changed successfully' : 'Something went wrong with the name change, please try again later.'
    if(data['success']) {
      this.kiln.name = this.kilnNameForm.value.kilnName;
    }

    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'middle'
    });

    toast.present();
  }

  public isNotConnected(updatedAt) {
    if(updatedAt) {
      var timePassed = moment.duration(moment().diff(moment(updatedAt)));
      var fiveMinutes = moment.duration(5, 'minutes');
      return timePassed > fiveMinutes;
    } else {
      return true;
    }
  }

  public viewRawKilnStatus() {
    let navigationExtras: NavigationExtras = {
      queryParams: {kiln: this.kiln, kiln_id: this.kiln.serial_number}
    };
    this.navCtrl.navigateForward('raw-kiln-status', navigationExtras);
  }

  public getDelayStartTime(kiln) {
    let current_delay = kiln.status && kiln.status.firing && `${kiln.status.firing.hold_hour || '00'}:${kiln.status.firing && kiln.status.firing && (kiln.status.firing.hold_min ? kiln.status.firing.hold_min.toString().padStart(2, '0') : '00')}`
    let total_delay = kiln.status && kiln.status.firing && `${kiln.status.firing.start_hour || '00'}:${kiln.status.firing && kiln.status.firing && (kiln.status.firing.start_min ? kiln.status.firing.start_min.toString().padStart(2, '0') : '00')}`
    return (current_delay === '00:00') ? total_delay: current_delay;
  }
}
