import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-app-premium-popup',
  templateUrl: './app-premium-popup.component.html',
  styleUrls: ['./app-premium-popup.component.scss'],
})
export class AppPremiumPopupComponent implements OnInit {
  @Input() onDismiss;

  constructor() { }

  ngOnInit() {}

}
