import { Component, OnInit, Input } from '@angular/core';
import * as d3 from 'd3';
import * as d3Axis from 'd3-axis';
import * as d3TimeFormat from 'd3-time-format';
import { select } from 'd3-selection';
import moment, { duration, min } from 'moment';

@Component({
  selector: 'app-firing-graph',
  templateUrl: './firing-graph.component.html',
  styleUrls: ['./firing-graph.component.scss'],
})
export class FiringGraphComponent implements OnInit {
  @Input() data;
  @Input() maxWidth;
  @Input() maxHeight;
  @Input() zones;
  @Input() smallScreen;
  @Input() dataLength;

  private svg;
  private xScale;
  private xScaleOriginal;
  private yScale;
  private yScaleOriginal;
  private zoom;
  private topMargin = 70;
  private bottomMargin = 30;
  private leftMargin = 50;
  private width;
  private height;
  public graphData = undefined;
  public drawingGraph = false;
  private tipHtml = "";
  private isFirefox: boolean;

  private graph_set_point_line;
  private graph_t1_line;
  private graph_t2_line;
  private graph_t3_line;
  private graph_bottom_axis;
  private graph_y_axis;
  private graph_board_temp_line;
  private bottomAxis
  private yAxis;

  constructor() {
    this.width = parseInt(this.maxWidth) - this.leftMargin;
    this.height = parseInt(this.maxHeight) - this.topMargin - this.bottomMargin;
  }

  ngOnInit() {
    this.width = parseInt(this.maxWidth) - this.leftMargin;
    this.height = this.smallScreen ? this.width : (parseInt(this.maxHeight) - this.topMargin - this.bottomMargin);
    // Firefox 1.0+
    this.isFirefox = navigator.userAgent.indexOf("Firefox") != -1;
    // console.log('firefox: ' + this.isFirefox);
    this.changeIndex = 0;    
  }

  private changeIndex;
  ngOnChanges(changes) {
    this.width = parseInt(this.maxWidth) - this.leftMargin;
    this.height = this.smallScreen ? this.width : (parseInt(this.maxHeight) - this.topMargin - this.bottomMargin);
    if(this.data && this.width && this.height && this.width > 0 && this.height > 0) {
      this.drawingGraph = true;
      this.createSvg()
        .then((response) => {
          this.drawGraph(this.data);
          this.graphData = this.data;
          this.drawingGraph = false;
        })
        .catch((e) => {
          console.log(e);
          this.drawingGraph = false;
        })
    } else {
      console.log(`no width, height, data`)
    }
  }  

  handleZoom(this, event) {
    try {
      let t = event.transform;
      let newX = t.rescaleX(this.xScaleOriginal)
      this.bottomAxis.scale(newX)
      select('.bottom-axis').call(this.bottomAxis);
      let newY = t.rescaleX(this.yScaleOriginal)
      this.yAxis.scale(newY)
      select('.left-axis').call(this.yAxis);

      this.drawSetPointLine(newX, newY, this.data);
      if(this.zones === 3) { this.drawZone3Line(newX, newY, this.data); }
      if(this.zones >= 2) { this.drawZone2Line(newX, newY, this.data); }
      this.drawZone1Line(newX, newY, this.data);
      this.drawBoardTempLine(newX, newY, this.data);     
    } catch(e) {
      console.log(e);
    }
  }

  public createSvg(): Promise<Boolean> {
    return new Promise((resolve, reject) => {
      if(this.svg) {
        d3.select("figure#bar svg")
          .attr("viewBox", `0 0 ${this.width + this.leftMargin} ${this.height + this.topMargin + this.bottomMargin}`)

        if(this.yScale) this.yScale.range([this.height, 0])
        if(this.xScale) this.xScale.range([ 0, this.width ]);
        d3.select(".bottom-axis")
          .attr("transform", "translate(0," + this.height + ")")          
        resolve(false);
      } else {
        this.svg = d3.select("figure#bar")
          .append("svg")          
          .attr("width", '97%')
          .attr("height", '97%')
          .attr("viewBox", `0 0 ${this.width + this.leftMargin} ${this.height + this.topMargin + this.bottomMargin}`)
          .classed('svg-content-responsive', true)
          .attr('preserveAspectRatio', 'xMinYMin meet')          
            .append("g")
            .attr('name', 'svg')
            .attr('class', 'chart')
            .attr("transform", "translate(" + this.leftMargin + "," + this.topMargin + ")")
                
        let percent = (this.height - this.topMargin - this.bottomMargin) / this.height * 100;
        let heightPercent = (percent < 70) ? 75 : 80;
        this.svg
          .append("clipPath")
          .attr("id", "clip")
          .append("rect")
          .attr("width", "100%")
          .attr("height", `${heightPercent}%`)
        
        resolve(true);
      }
    })
  }

  drawSetPointLine(xScale, yScale, data) {
      // Add the set point line
      var set_point_line = d3.line()
          .x(function(d: any, i) {return xScale(d3.isoParse(d.date));})
          .y(function(d: any) {return yScale(d.set_pt);});

      if(select('.set_pt').size() > 0) {
        select('.set_pt')
          .transition()
          .duration(250)
          .attr("d", set_point_line)
      } else {
        this.graph_set_point_line = this.svg.append("path")
          .datum(data)
          .attr("name","set_pt")
          .attr("class","set_pt")
          .attr("stroke", "#db360d")
          .attr("stroke-width", 2)
          .attr("fill", "none")
          .attr("clip-path", "url(#clip)")
          .attr("d", set_point_line);
      }
  }

  drawZone1Line(xScale, yScale, data) {
    // Add the main temperature (t2) line
    var zone_2_line = d3.line<any>()
        .curve(d3.curveBasis)                
        .x(function(d: any, i) { return xScale(d3.isoParse(d.date)); })
        .y(function(d: any) { return yScale(d.t2); })  
        .defined((d) => {
          return d && d['t2'] && yScale(d['t2']) && d['date'] && xScale(d3.isoParse(d['date'])) !== undefined;
        })

    if(select('.t2').size() > 0) {      
      select('.t2')
        .transition()
        .duration(500)
        .attr("d", zone_2_line)      
    } else {
      this.graph_t2_line = this.svg.append("path")
        .datum(data)
        .attr("name","t2")
        .attr("class","t2")
        .attr("stroke", "#1bc43d")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("clip-path", "url(#clip)")
        .attr("d", zone_2_line);
    }
  }
  
  drawZone2Line(xScale, yScale, data) {
    var zone_1_line = d3.line<any>()
          .curve(d3.curveBasis)
          .x(function(d: any, i) {return xScale(d3.isoParse(d.date));})
          .y(function(d: any) { return yScale(d.t1); })
          .defined((d) => { 
            return d['t1'] && yScale(d['t1']) && d['date'] && xScale(d3.isoParse(d['date'])) !== undefined;
          })

          this.graph_t1_line = this.svg.append("path")
            .datum(data)
            .attr("name","t2")
            .attr("stroke", "#159771")
            .attr("stroke-width", 2)
            .attr("fill", "none")
            .attr("clip-path", "url(#clip)")
            .attr("d", zone_1_line);
  }

  drawZone3Line(xScale, yScale, data) {    
    var zone_3_line = d3.line<any>()
      .curve(d3.curveBasis)
      .x(function(d: any, i) {return xScale(d3.isoParse(d.date));})
      .y(function(d: any) {return yScale(d.t3);})
      .defined((d:any) => { 
        return d['t3'] && yScale(d['t3']) && d['date'] && xScale(d3.isoParse(d['date'])) !== undefined
      })

      this.graph_t3_line = this.svg.append("path")
        .datum(data)
        .attr("name","t2")
        .attr("stroke", "#0f6e52")
        .attr("stroke-width", 2)
        .attr("fill", "none")
        .attr("clip-path", "url(#clip)")
        .attr("d", zone_3_line);
  }

  drawBoardTempLine(xScale, yScale, data) {
    // Add the board temperature line
    var board_temp_line = d3.line<any>()
    .x(function(d: any, i) { return xScale(d.date); })
    .y(function(d: any) { return yScale(d.board_t); })
    .defined((d) => { 
      return d['board_temp'] && yScale(d['board_temp']) && d['date'] && xScale(d3.isoParse(d['date'])) !== undefined;
    })

  this.graph_board_temp_line = this.svg.append("path")
    .datum(data)
    .attr("stroke", "#e3941e")
    .attr("stroke-width", 2)
    .attr("fill", "none")
    .attr("clip-path", "url(#clip)")
    .attr("d", board_temp_line);
  }

  public drawGraph(data: any[]): void {
    var width = this.width;
    var height = this.height;    

    // Draw the X-axis on the DOM
    if(this.svg && width && height && data && data.length > 0) {
      if(this.graph_y_axis) this.graph_y_axis.remove();
      if(this.graph_t1_line) this.graph_t1_line.remove();
      if(this.graph_t2_line) this.graph_t2_line.remove();
      if(this.graph_t3_line) this.graph_t3_line.remove();
      if(this.graph_bottom_axis) this.graph_bottom_axis.remove();
      if(this.graph_set_point_line) this.graph_set_point_line.remove();
      if(this.graph_board_temp_line) this.graph_board_temp_line.remove();
      this.svg.exit().remove();
       // path.exit().remove()
      //initial tooltip text
      if (data[0] && typeof(data[0].date) != "undefined") this.tipHtml = moment(data[0].date).format("M/D HH:mm");
      if (data[0] && typeof(data[0].step) != "undefined") this.tipHtml += '<br/>' + data[0].step;
      if (data[0] && typeof(data[0].set_pt) != "undefined") this.tipHtml += '<br/>' + "Set Pt: " + data[0].set_pt;
      if (this.zones === 1 && data[0] && typeof(data[0].t2) != "undefined") this.tipHtml += '<br/>' + "Temp: " + data[0].t2;
      if (this.zones >= 2 && data[0] && typeof(data[0].t1) != "undefined") this.tipHtml += '<br/>' + "T1: " + data[0].t1;
      if (this.zones >= 2 && data[0] && typeof(data[0].t2) != "undefined") this.tipHtml += '<br/>' + "T2: " + data[0].t2;
      if (this.zones === 3 && data[0] && typeof(data[0].t3) != "undefined") this.tipHtml += '<br/>' + "T3: " + data[0].t3;

      try {
        select('.tooltip')
         .html(this.tipHtml)
         .transition()
         .duration(0)
         .style('opacity', 0);
       } catch(e) { console.log(`error on tooltip drawGraph`); }


      var minDate = d3.min(data, function(d) {return d.date});
      console.log(`LOAD - ZOOM - MIN DATE - ${minDate}`)
      var maxDate = d3.max(data, function(d) {return d.date});
      let minMoment = moment(minDate).seconds(0).milliseconds(0)
      let getElapsedTime = (d) => {
        if(d) {
          d = Date.parse(d);
          let localMoment = moment(d)
          let duration = moment.duration(localMoment.diff(minMoment))
          let days = duration.days() ? `${duration.days()}d` : null
          let hours = duration.hours() ? `${duration.hours().toString()}h` : null
          let minutes = duration.minutes() ? `${duration.minutes()}m` : null
          // let seconds = duration.seconds() ? `${duration.seconds()}s` : null
    
          let expiredTime = ''
          if(days) { expiredTime = `${days}${hours || minutes ? ', ' : ''}`}
          if(hours) {expiredTime = expiredTime.concat(`${hours}${minutes ? ', ' : ''}`)}
          if(minutes) {expiredTime = expiredTime.concat(`${minutes}`)}
          return expiredTime;
        } else { return '0'; }
      }
      
      // Create the X-axis and scale
      this.xScale = d3.scaleTime()
        .domain([d3.isoParse(minDate),d3.isoParse(maxDate)])
        .range([0, this.width]);
      
      let hourDiff = moment(maxDate).diff(moment(minDate), 'hours');
      let hoursBetween = hourDiff > 10 ? Math.ceil(hourDiff/10) : 1;
      let tickValues = Array.from({length: 10}, (_, i) => i * hoursBetween).map((i) => { 
        return moment(minDate).clone().seconds(0).milliseconds(0).add(i, 'hours').toDate() 
      })
      
      if(hourDiff > 0) {
        this.bottomAxis = d3.axisBottom(this.xScale).ticks(tickValues.length).tickValues(tickValues).tickFormat((d, i) => {return getElapsedTime(d)})
      } else { 
        this.bottomAxis = d3.axisBottom(this.xScale).ticks(this.smallScreen ? 5 : 10).tickFormat((d, i) => {return getElapsedTime(d)})
      }         
      this.xScaleOriginal = this.xScale.copy();
      if(select('.bottom-axis').size() === 0) {
        this.graph_bottom_axis = this.svg.append("g")
            .attr("transform", "translate(0," + this.height + ")")
            .attr('name', 'bottom-axis')
            .attr('class', 'bottom-axis')
            .call(this.bottomAxis)
            .selectAll("text")
              .attr('fill', "#000")
              .style("text-anchor", "start");
      } 

      // Add Y axis
      this.yScale = d3.scaleLinear()
        .domain(d3.extent(
          [].concat(data.map(function (d) {
              return (d.t2);
          }), data.map(function (d) {
              return (d.set_pt);
          }))))
        .range([ this.height, 0])
        .nice();
      this.yScaleOriginal = this.yScale.copy();
      
      this.yAxis = d3.axisLeft(this.yScale)
      this.graph_y_axis = this.svg.append("g")
        .attr('class', 'left-axis')
        .call(this.yAxis);

      this.drawSetPointLine(this.xScale, this.yScale, data);
      if(this.zones === 3) { this.drawZone3Line(this.xScale, this.yScale, data); }
      if(this.zones >= 2) { this.drawZone2Line(this.xScale, this.yScale, data); }
      this.drawZone1Line(this.xScale, this.yScale, data);
      this.drawBoardTempLine(this.xScale, this.yScale, data)

      this.zoom = d3.zoom()
        .scaleExtent([1, 5])
        .translateExtent([[0, 0], [this.width, this.height]])
        .extent([[0, 0], [this.width, this.height]])
        .on("zoom", (event) => { 
          this.handleZoom(event) 
        })
        // .on("zoom", (event) => { this.handleZoom(event) });
      this.svg.call(this.zoom)

      // Three function that change the tooltip when user hover / move / leave a cell
      let mouseOut = (event) => {
        d3.select(".mouse-line")
          .style("opacity", 0)

        try {
          select('.tooltip')
            .transition()
            .duration(250)
            .style('opacity', 0);
        } catch(e) { console.log(`error on tooltip mouseOut`); }
      }

      let mouseOver = (event) => {
          d3.select(".mouse-line")
          .style("opacity", 1)

          try {
            select('.tooltip')
              .transition()
              .duration(200)
              .style('opacity', 1);
          } catch(e) { console.log(`error on tooltip mouseOver`); }
      }

      let mouseMove = (event) => {
        var mx = this.isFirefox ? event.offsetX:event.offsetX - this.leftMargin;
        var my = event.offsetY - this.topMargin;
        var bisectDate = d3.bisector(function(d: any) { return d.date; }).left;
        var xDate = this.xScale.invert(mx).toISOString();
        var i = bisectDate(data, xDate);
        var d = data[i];
        this.tipHtml = '<B>' + moment(this.xScale.invert(mx)).format("M/D HH:mm") + '</B>';
        if (d) this.tipHtml += '<br/>' + getElapsedTime(d.date);
        if (d && typeof(d.step) != "undefined") this.tipHtml += '<br/>' + d.step;
        if (d && typeof(d.set_pt) != "undefined") this.tipHtml += '<br/>' + "Set Pt: " + d.set_pt;
        if (this.zones === 1 && d && typeof(d.t2) != "undefined") this.tipHtml += '<br/>' + "Temp: " + d.t2;
        if (this.zones >= 2 && d && typeof(d.t2) != "undefined") this.tipHtml += '<br/>' + "T1: " + d.t1;
        if (this.zones >= 2 && d && typeof(d.t2) != "undefined") this.tipHtml += '<br/>' + "T2: " + d.t2;
        if (this.zones === 3 && d && typeof(d.t2) != "undefined") this.tipHtml += '<br/>' + "T3: " + d.t3;

        d3.select(".mouse-line")
          .attr("d", function() {
            var d = "M" + mx + "," + height; //-40
            d += "V" + (-50);
            return d;
          });

        d3.select('.tooltip')
          .html(this.tipHtml)
          .style('left', mx + 'px')//+100
          .style('top', (this.topMargin - 64) + 'px');

      }

      var mouseG = this.svg.append("g")
        .attr("class", "mouse-over-effects");

      mouseG.append('svg:rect') // append a rect to catch mouse movements on canvas
        .attr('width', "100%") // can't catch mouse events on a g element
        .attr('height', "100%")
        .attr('fill', 'none')
        .attr('pointer-events', 'all')
        .on('mouseout', (event) => {mouseOut(event)})
        .on("mouseover", (event) => {mouseOver(event)})
        .on("mousemove", (event) => {mouseMove(event)})


        mouseG.append("path") // this is the black vertical line to follow mouse
        .attr("class", "mouse-line")
        .style("pointer-events", "none")
        .style("stroke", "black")
        .style("stroke-width", "1px")
        .style("opacity", "0");

      // Define the div for the tooltip
      mouseG.append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0);
    } 
  }

  zoomIn() {
    this.svg.transition().call(this.zoom.scaleBy, 1.1);
  }

  zoomOut() {
    this.svg.transition().call(this.zoom.scaleBy, 0.9);
  }

  reset() {
    this.svg.transition()
    .duration(750)
    .call(this.zoom.transform, d3.zoomIdentity);
  }
}
