<div>
  <form [formGroup]="generalAccountInfoForm" *ngIf="formLoaded">
    <ion-item>
      <ion-label slot="start">Account Type:</ion-label>
      <ion-label slot="end" *ngIf="!editing">{{type}}</ion-label>
      <ion-select slot="end" formControlName="type" *ngIf="editing" [placeholder]="type">
        <ion-select-option value="Company">Company</ion-select-option>
        <ion-select-option value="Personal">Personal</ion-select-option>
        <ion-select-option value="School">School</ion-select-option>
        <ion-select-option value="College / University">College / University</ion-select-option>
        <ion-select-option value="School District">School District</ion-select-option>
      </ion-select>
    </ion-item>
    
    <ion-item >
      <ion-label slot="start">Number of Kilns:</ion-label>
      <ion-label *ngIf="!editing" slot="end">{{numberKilns}}</ion-label>
      <ion-input *ngIf="editing" formControlName="numberControllers"></ion-input>
    </ion-item>  

    <ion-item *ngIf="!editing">
      <ion-label [class.danger]="isExpired" slot="start">Expiration:<sup *ngIf=isExpired [class.danger]="isExpired">*</sup></ion-label>
      <ion-label *ngIf="expiration" [class.danger]="isExpired" slot="end">{{expiration | date:'mediumDate'}}</ion-label>
    </ion-item>

    <ion-item>
      <ion-label slot="start">Subscription Frequency</ion-label>
      <ion-select slot="end" formControlName="frequency" [disabled]="!editing">
        <ion-select-option value="kiln_annual">Annual</ion-select-option>
        <ion-select-option value="kiln_monthly">Monthly</ion-select-option>
      </ion-select>
    </ion-item>

    <ion-item *ngIf="editing">
      <ion-label slot="start"># Renewals</ion-label>
      <ion-input slot="end" type="number" formControlName="renewals" *ngIf="editing"></ion-input>
    </ion-item>  

    <ion-item>
      <ion-label>Time Zone:</ion-label>
      <ion-select formControlName="timezone" [class.invalid]="generalAccountInfoForm && generalAccountInfoForm.controls.timezone.valid && generalAccountInfoForm.controls.timezone.touched">
        <ion-select-option class="default-us-timezone" *ngFor="let zone of getTopUSTimezones()" [value]="zone">{{zone}}</ion-select-option>
        <ion-select-option *ngFor="let zone of getTimezones()" [value]="zone">{{zone}}</ion-select-option>
      </ion-select>
    </ion-item>    
    <ion-item *ngIf="!payment ? editing : true">
      <app-account-payment #paymentsForm (startEdit)="startEditing(true)"
        [accountInformation]="accountInformation" [canAdminister]="canAdminister" [editing]="editing">
      </app-account-payment>
    </ion-item>
  </form>  
</div>