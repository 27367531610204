import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { KilnService } from 'src/app/services/kiln.service';
import { MaintenanceNoteService } from 'src/app/services/maintenance_notes.service';
import { AlertController, PickerController, Platform } from '@ionic/angular';
import moment from 'moment';

@Component({
  selector: 'app-maintenance-tab',
  templateUrl: './maintenance-tab.component.html',
  styleUrls: ['./maintenance-tab.component.scss'],
})
export class MaintenanceTabComponent implements OnInit {
  @Input() kilnId;
  @Input() viewingKilnlinkFreeKilns;
  public is_editing = undefined;
  public maintenanceNoteForm: UntypedFormGroup;
  public maintenance_notes = [];
  public small_screen = false;
  public selectedMaintenanceNote;
  public creatingNew = false;
  public retrievingNotes = false;

  constructor(
    public kilnService: KilnService,
    public maintenanceNoteService: MaintenanceNoteService,
    public alertController: AlertController,
    public platform: Platform,
    private pickerController: PickerController
  ) {
    let defaultValidation = () => { return new UntypedFormControl('', [Validators.required]);}
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb')) : false;
    this.small_screen = this.platform.width() < 875 || is_device;

    this.retrievingNotes = this.small_screen;
    this.maintenanceNoteForm = new UntypedFormGroup({
      date: defaultValidation(),
      technician: defaultValidation(),
      type: defaultValidation(),
      notes: defaultValidation(),
      note_id: defaultValidation()
    });
  }

  ngOnInit() {
    this.is_editing = undefined;
    this.retrievingNotes = true;
    if(!this.viewingKilnlinkFreeKilns) {      
      this.maintenanceNoteService.list(this.kilnId)
          .then((response) => { this.onListSuccess(response) })
          .catch((error) => {
            this.retrievingNotes = false;
            this.showError("Could not retrieve maintenance notes at this time.")
          })
    }
  }

  ngOnChanges() {
    this.is_editing = undefined;
  }

  public maintenance_types = [
    {value:"vacuum_interior", option_text: "Vacuum interior"},
    {value:"check_thermocouple_using_cones", option_text: "Check Thermocouple using cones"},
    {value:"check_all_hardware", option_text: "Check all hardware around kiln, lid brace, screws, handles, etc."},
    {value:"review_diagnostics", option_text: "Review diagnostic data"},
    {value:"check_envirovent", option_text: "Check EnviroVent for proper draw through lid hole"},
    {value:"replace_all_elements", option_text: "Replace all elements"},
    {value:"replace_one_element", option_text: "Replace one element"},
    {value:"replace_relays", option_text: "Replace relays"},
    {value:"replace_wiring_harness", option_text: "Replace wiring harness"},
    {value:"replace_bricks", option_text: "Replace bricks (note location)"},
    {value:"replace_reverse_lid", option_text: "Replace / reverse lid"},
    {value:"replace_reverse_bottom_slab", option_text: "Replace or reverse bottom slab"},
    {value:"environlink", option_text: "Install EnviroLink"},
    {value:"replace_thermocouple", option_text: "Replace thermocouple"},
    {value:"other", option_text: "Other"}
  ]

  public getFriendlyType(val) {
    let maintenance_type = this.maintenance_types.find((t) => { return t.value === val; })
    return maintenance_type ? maintenance_type.option_text : "N/A";
  }

  onSubmit(value) {
    if(this.is_editing) {
      this.maintenanceNoteService.update(this.kilnId, value)
          .then((response) => { this.onUpdateSuccess(response) })
          .catch((error) => { this.onUpdateError(error) })
    } else {
      this.maintenanceNoteService.create(this.kilnId, value)
          .then(() => {
            this.onCreateSuccess({
              technician: value.technician,
              type: value.type,
              note_date: value.date,
              notes: value.notes
            });
          })
          .catch((error) => { this.onCreateError(error) })
    }
  }

  onCreateSuccess(data) {
    this.maintenanceNoteService.list(this.kilnId)
        .then((response) => { this.onListSuccess(response) })
        .catch((error) => this.showError("Could not retrieve maintenance notes at this time."))
    this.clearForm();
  }

  onUpdateSuccess(data) {
    this.maintenanceNoteService.list(this.kilnId)
        .then((response) => { this.onListSuccess(response) })
        .catch((error) => this.showError("Could not retrieve maintenance notes at this time."))
    this.clearForm();
  }

  onListSuccess(data) {
    this.retrievingNotes = false;
    this.maintenance_notes = data.sort((a, b) => {
      let device1_greater = moment(a.note_date).isAfter(moment(b.note_date));
      let device2_greater = moment(a.note_date).isAfter(moment(b.note_date));
      if(device1_greater) { return -1 }
      else if(device2_greater) { return 1 }
      else return 0;
    });
    if(!this.selectedMaintenanceNote) {
      this.selectedMaintenanceNote = this.maintenance_notes[0]
    }
  }

  deleteNoteSuccess(data) {
    this.maintenanceNoteService.list(this.kilnId)
        .then((response) => { this.onListSuccess(response) })
        .catch((error) => this.showError("Could not retrieve maintenance notes at this time."))
  }

  formatErrorMessages(messages) {
    return messages.map((msg) => {
      msg = msg.replace('\"', '');
      msg = msg.replace('"', '');
      msg = msg.replace('_', ' ');
      msg = `${msg.charAt(0).toUpperCase()}${msg.slice(1)}.`;
      return msg;
    }).join(" ");
  }

  onCreateError(error) {
    this.showError(error.error ? `${error.error.message} - ${this.formatErrorMessages(error.error.msgs)}` : "Could not create maintenance note.");
  }

  onUpdateError(error) {
    this.showError(error.error ? `${error.error.message} - ${this.formatErrorMessages(error.error.msgs)}` : "Could not update maintenance note.");
  }

  async showError(message) {
    let alert = await this.alertController.create({
       message: message,
       buttons: [{
         text:'Ok',
         role: 'cancel',
       }]
     });

   await alert.present();
  }

  clearForm() {
    this.maintenanceNoteForm.reset();
    this.is_editing = undefined;
  }

  loadToMaintenanceForm(note) {
    if(note) {
      this.is_editing = note.id;
      let maintenance_type = this.maintenance_types.find((t) => { return t.value === note.type; })

      this.maintenanceNoteForm = new UntypedFormGroup({
        date: new UntypedFormControl(note.note_date ? moment(note.note_date).format("YYYY-MM-DD") : '', [Validators.required]),
        technician: new UntypedFormControl(note.technician, [Validators.required]),
        type: new UntypedFormControl(note.type, [Validators.required]),
        notes: new UntypedFormControl(note.notes, [Validators.required]),
        note_id: new UntypedFormControl(note.id)
      });
    }    
  }

  async deleteNote(note) {
    let alert = await this.alertController.create({
       message: "Are you sure you want to delete this note?",
       buttons: [{
         text:'Cancel',
         role: 'cancel',
       }, {
         text: "Yes",
         handler: () => {
           this.maintenanceNoteService.delete(this.kilnId, note.id)
               .then((response) => { this.deleteNoteSuccess(response) })
               .catch((error) => this.showError("Note could not be deleted"))
         }
       }]
     });

   await alert.present();
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        this.selectedMaintenanceNote = selection.notes.value;
      },
    },
  ];

  async showPicker() {
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'notes',
        options: this.maintenance_notes.map((note) => { return {text: moment(note.note_date).utc().format('MM/DD/YY').toString(), value: note}})
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }
}
