import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { UpdateAccountInfoComponent } from './update-account-info/update-account-info.component';
import { KilnStatusComponent } from './kiln-status/kiln-status.component';
import { ControllerDiagnosticsTabComponent } from './controller-diagnostics-tab/controller-diagnostics-tab.component';
import { FiringHistoryComponent } from './firing-history/firing-history.component';
import { MaintenanceTabComponent } from './maintenance-tab/maintenance-tab.component';
import { KilnDetailsSidebarComponent } from './kiln-details-sidebar/kiln-details-sidebar.component';
import { PremiumMenuPopupComponent } from './premium-menu-popup/premium-menu-popup.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ManageNetworkComponent } from './manage-network/manage-network.component';
import { AccountInvitesComponent } from './account-invites/account-invites.component';
import { KilnAlertsComponent } from './kiln-alerts/kiln-alerts.component';
import { FiringStatusGraphComponent } from './firing-status-graph/firing-status-graph.component';
import { FiringGraphComponent } from './firing-graph/firing-graph.component';
import { LoginAsPopupComponent } from './login-as-popup/login-as-popup.component';
import { SwitchAccountsComponent } from './switch-accounts/switch-accounts.component';
import { UserInfoComponent } from './user-info/user-info.component';
import { DiagnosticDataPointComponent } from './diagnostic-data-point/diagnostic-data-point.component';

let components_list = [
  UpdateAccountInfoComponent,
  KilnStatusComponent,
  ControllerDiagnosticsTabComponent,
  FiringHistoryComponent,
  MaintenanceTabComponent,
  KilnDetailsSidebarComponent,
  PremiumMenuPopupComponent,
  ChangePasswordComponent,
  ManageNetworkComponent,
  AccountInvitesComponent,
  KilnAlertsComponent,
  FiringStatusGraphComponent,
  FiringGraphComponent,
  LoginAsPopupComponent,
  SwitchAccountsComponent,
  UserInfoComponent,
  DiagnosticDataPointComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule
    ],
    declarations: components_list,
    exports: components_list,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule {}
