import { Component, OnInit, Input } from '@angular/core';
import { KilnService } from 'src/app/services/kiln.service';

@Component({
  selector: 'app-kiln-details-sidebar',
  templateUrl: './kiln-details-sidebar.component.html',
  styleUrls: ['./kiln-details-sidebar.component.scss'],
})
export class KilnDetailsSidebarComponent implements OnInit {
  @Input() kilnId;
  @Input() kilnMac;
  @Input() kilnFirmware: String;
  @Input() viewingKilnlinkFreeKilns;
  public gettingDetails = true;
  public showUpdateAvailableIcon = false;
  public latestFirmwareVersion;
  public error = undefined;
  public details;
  public default_details = {
    model: "Other",
    voltage: "N/A",
    phase: "N/A",
    serial_number: "N/A",
    network_id: "N/A",
    install_date: "N/A"
  }

  constructor(public kilnService: KilnService) {}

  ngOnInit() {
    if(!this.viewingKilnlinkFreeKilns) {
      this.getDetails();
      this.showUpdateAvailable();
    }    
  }

  ngOnChanges() {
    if(!this.viewingKilnlinkFreeKilns) {
      this.getDetails();
      this.showUpdateAvailable();
    }
  }

  public getDetails() {
    this.error = undefined;
    if(this.kilnMac && this.kilnId) {
      this.gettingDetails = true;
      this.kilnService.getKilnSidebarDetails(this.kilnMac, this.kilnId)
          .then((response) => { this.successResponse(response); })
          .catch((error) => { this.errorResponse(error) })
    } else {
      this.error = "Sorry we cannot get details for this kiln."
      // console.log(`does not have mac ${this.kilnMac} and id: ${this.kilnId}`)
    }
  }

  public successResponse(data) {
    this.gettingDetails = false;
    let required_fields = ['model', 'voltage', 'phase', 'serial_number', 'network_id', 'install_date']
    let all_fields_exist = required_fields.every((field) => { return data && data.hasOwnProperty(field); })
    if(all_fields_exist) {
      this.details = data;
    } else {
      this.details = {...this.default_details, ...data};
    }
  }

  public errorResponse(error) {
    this.gettingDetails = false;
    this.details = this.default_details;
    this.error = true;
  }

  public async showUpdateAvailable() {
    if(this.kilnFirmware) {
      let current_version = this.kilnFirmware.replace('SDI', '').replace('-', '').replace(".", '').replace(".", '')
      if(!this.latestFirmwareVersion) {
        let firmware_version_observable = this.kilnService.checkFirmwareVersion(current_version);
        firmware_version_observable.subscribe(async (latest_version: String) => {          
          this.latestFirmwareVersion = latest_version.replace('SDI', '').replace('-', '').replace(".", '').replace(".", '');
          if(this.latestFirmwareVersion !== current_version) {
            this.showUpdateAvailableIcon = true;
          }        
        }, (e) => {
          console.log(`error getting latest firmware version`);
        })
      } else {
        this.showUpdateAvailableIcon = this.latestFirmwareVersion !== current_version;
      }    
    }    
  }
}
