<div id="kiln-details-sidebar-container">
  <h4 class="title">Kiln Details</h4>
  <p *ngIf="viewingKilnlinkFreeKilns">You are viewing a KilnLink Free kiln. Upgrade to get kiln details.</p>
   <ion-list *ngIf="details && !viewingKilnlinkFreeKilns">
    <ion-item>
       <ion-label>Model Name </ion-label>
       <ion-label><p>{{details.model}}</p></ion-label>
     </ion-item>
     <ion-item>
       <ion-label>Voltage </ion-label>
       <ion-label><p>{{details.voltage}}</p></ion-label>
     </ion-item>
     <ion-item>
       <ion-label>Phase </ion-label>
       <ion-label><p>{{details.phase}}</p></ion-label>
     </ion-item>
     <ion-item>
       <ion-label>Serial # </ion-label>
       <ion-label><p>{{details.kiln_serial_number}}</p></ion-label>
     </ion-item>
     <ion-item *ngIf="!details.is_kmt">
       <ion-label>Kiln ID </ion-label>
       <ion-label><p>{{details.kiln_id}}</p></ion-label>
     </ion-item>
     <ion-item *ngIf="details.is_kmt">
       <ion-label>Controller Serial # </ion-label>
       <ion-label><p>{{details.serial_number}}</p></ion-label>
     </ion-item>
     <ion-item *ngIf="!details.is_kmt">
       <ion-label>Network Device ID </ion-label>
       <ion-label><p>{{details.network_id}}</p></ion-label>
     </ion-item>
     <ion-item *ngIf="details">
       <ion-label>Date Installed </ion-label>
        <ion-label *ngIf="details.install_date !== 'N/A'"><p>{{details.install_date | date:'short'}}</p></ion-label>
        <ion-label *ngIf="details.install_date === 'N/A'"><p>{{details.install_date}}</p></ion-label>
     </ion-item>
     <ion-item class="no-pointer">
        <ion-label>Firmware</ion-label>        
        <ion-label *ngIf="showUpdateAvailableIcon" title="Firmware Update Available">
          <ion-icon color="danger" name="warning"></ion-icon>
          {{kilnFirmware}}
        </ion-label>        
        <ion-label *ngIf="!showUpdateAvailableIcon" title="Firmware Up To Date">
          <ion-icon color="secondary" name="checkmark-circle-outline"></ion-icon>
          {{kilnFirmware}}
        </ion-label>
     </ion-item>
   </ion-list>
  <p *ngIf="!viewingKilnlinkFreeKilns && !details && error && !gettingDetails">Oops, we had trouble retrieving the details for this kiln.</p>
  <p *ngIf="!viewingKilnlinkFreeKilns && gettingDetails && !error">
    <span>We're retrieving kiln details.</span>
    <ion-spinner name="bubbles"></ion-spinner>
  </p>
</div>
