import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { states } from '@ewarren/unitedstatesofamerica';

@Component({
  selector: 'app-account-settings-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss'],
})
export class AccountSettingsOwnerComponent implements OnInit {
  @Input() accountInformation;
  @Output() pending = new EventEmitter<Object>();
  @Output() buttonText = new EventEmitter<String>();

  public ownerForm: UntypedFormGroup;
  public statesMappings;

  constructor(public accountService: AccountService) {
    this.createForm();
    this.statesMappings = Object.keys(states).map((s) => { return {abbreviation: s, name: states[s] }; })
  }

  ngOnInit() {
    this.createForm();
    this.statesMappings = Object.keys(states).map((s) => { return {abbreviation: s, name: states[s] }; })
  }

  async successfulUpdate() {
    this.pending.emit({
      is_pending: false,
      message: 'Account owner successfully updated.',
      success: true
    });
  }

  createForm() {
    if(!this.ownerForm && this.accountInformation) {
      let company_info = this.accountInformation && this.accountInformation.account_owner;
      let address = company_info.address;

      let name = new UntypedFormControl(company_info ? company_info.name.first_name : 'Please enter a first name', [Validators.required]);
      let last_name = new UntypedFormControl(company_info ? company_info.name.last_name : 'Please enter a last name', [Validators.required]);
      let line_one = new UntypedFormControl(address ? address.line_one : 'Line 1', [Validators.required]);
      let line_two = new UntypedFormControl(address ? address.line_two : 'Line 2', []);
      let city = new UntypedFormControl(address ? address.city : 'City', [Validators.required]);
      let state = new UntypedFormControl(address ? address.state : 'OR', [Validators.required]);
      let postal = new UntypedFormControl(address ? address.postal : '12345', [Validators.required]);

      this.ownerForm = new UntypedFormGroup({
        first_name: name,
        last_name: last_name,
        line_one: line_one,
        line_two: line_two,
        city: city,
        state: state,
        postal_code: postal
      });
    }
  }

  getStates() {
    return this.statesMappings;
  }

  public save() {
    let form_value = this.ownerForm.value
    this.accountService.updateOwnerInfo(this.accountInformation.general.external_id, {
      line_one: form_value.line_one,
      line_two: form_value.line_two,
      city: form_value.city,
      state: form_value.state,
      postal: form_value.postal_code
    }, {first_name: form_value.first_name, last_name: form_value.last_name})
        .then((data) => { this.successfulUpdate(); })
        .catch((error) => {
          this.pending.emit({
            is_pending: false,
            message: 'Sorry, we could not update the account owner information at this time..',
            success: true
          });
        })

    this.pending.emit({is_pending: true});
  }

  public isValid() {
    return this.ownerForm && this.ownerForm.touched && this.ownerForm.valid;
  }
}
