import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { PickerController, Platform, ToastController } from '@ionic/angular';
import { KilnService } from 'src/app/services/kiln.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import moment from 'moment';

@Component({
  selector: 'app-firing-history',
  templateUrl: './firing-history.component.html',
  styleUrls: ['./firing-history.component.scss'],
})
export class FiringHistoryComponent implements OnInit {
  @Input() mac;
  @Input() serial;
  @Input() kilnId;
  @Input() t_scale;
  @Input() viewingKilnlinkFreeKilns;
  public error = false;
  public retrieving = false;
  public firing_history = [];
  public details = undefined;
  public subtab = 'program';
  public selected_firing = undefined;
  public editingNote = false;
  public submittingNote;
  public program_steps = [];
  public hasMore = false;
  private page = 0;
  public retrieving_more = false;
  public small_screen;
  public firingHistoryPickers = [];
  public table_height;
  public graphDimensions = {height: 380, width: 950};

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        if(selection.historical_firings.value && selection.historical_firings.value === 'load_more') {
          this.getMoreFiringHistory();
        } else if(selection.historical_firings.value) {
          this.selectFiring(selection.historical_firings.value.id)
        }        
      },
    },
  ];

  public firingNotesForm: UntypedFormGroup;

  constructor(
    public kilnService: KilnService,
    public toastCtrl: ToastController,
    private platform: Platform,
    private pickerController: PickerController
  ) {
    this.error = false;
    if(!this.viewingKilnlinkFreeKilns) {
      this.getFiringHistory()
    }
  }

  ngOnInit() {
    this.program_steps = [];
    this.submittingNote = false;
    this.editingNote = false;

    if(!this.viewingKilnlinkFreeKilns) {
      this.getFiringHistory();
    }
    this.subtab = 'program';

    let width = this.platform.width();
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb') || this.platform.is('desktop')) : false;
    this.small_screen = width < 875 && is_device;

    this.table_height = this.platform.height() * 0.75;
    this.graphDimensions = {width: this.platform.width() > 800 ? 900 : this.platform.width(), height: this.platform.height() < 400 ? this.platform.height() : 380}
  }

  ngOnChanges() {
    this.program_steps = [];
    this.details = undefined;
    this.selected_firing = undefined;

    if(!this.viewingKilnlinkFreeKilns) {
      this.getFiringHistory();
    }

    let width = this.platform.width();
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb') || this.platform.is('desktop')) : false;
    this.small_screen = width < 875 && is_device;
  }

  async showPicker() {
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'historical_firings',
        options: this.firingHistoryPickers
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }

  unsubscribe() {
    this.program_steps = [];
  }
  ngOnDestroy() { this.unsubscribe(); }
  ngOnLeave() { this.unsubscribe(); }

  getNotesSuccess(data) {
    // console.log(`getNotesSuccess: ${JSON.stringify(data)} ${typeof data}`)
    let note = (data && data.length >= 1) ? data[0] : data;
    this.createForm(note);
  }

  createForm(data) {
    if(this.selected_firing && data) {
      // console.log(`creating edit form: ${JSON.stringify(data)}, ${typeof data} ${data.load_density || data.load_description || data.comments}`)
      this.editingNote = data.load_density || data.load_description || data.comments;
      let load_density = new UntypedFormControl(data.load_density || '', [Validators.required]);
      let load_description = new UntypedFormControl(data.load_description || '', [Validators.required]);
      let comments = new UntypedFormControl(data.comments || '', [Validators.required]);

      this.firingNotesForm = new UntypedFormGroup({
        load_density: load_density,
        load_description: load_description,
        comments: comments
      });
    } else {
      let load_density = new UntypedFormControl('', [Validators.required]);
      let load_description = new UntypedFormControl('', [Validators.required]);
      let comments = new UntypedFormControl('', [Validators.required]);
      this.firingNotesForm = new UntypedFormGroup({
        load_density: load_density,
        load_description: load_description,
        comments: comments
      });
    }
  }

  getFiringHistory() {
    if(this.mac && this.serial && !this.retrieving) {
      this.error = false;
      this.retrieving = true;
      this.kilnService.getFiringHistory(this.mac, this.serial)
          .then((response) => { this.getFiringHistorySuccess(response) })
          .catch((error) => { this.getFiringHistoryError(error) })
    }
  }

  getMoreFiringHistory() {
    if(this.mac && this.serial && !this.retrieving && !this.retrieving_more) {
      this.retrieving_more = true;
      this.page = this.page + 1;
      this.kilnService.getFiringHistory(this.mac, this.serial, this.page)
          .then((response) => { this.getFiringHistorySuccess(response) })
          .catch((error) => {this.getFiringHistoryError(error) })
    }
  }

  getFiringHistorySuccess(data) {
    let firings = data.firings;
    this.hasMore = data.has_more;
    let unique_firing_ids = [];
    let reducedFirings = firings.reduce((accumulator, history) => {
      if(!unique_firing_ids.includes(history.id)) {
        unique_firing_ids.push(history.id);
        accumulator.push(history);
      }

      return accumulator;
    }, []);
    if(this.retrieving_more) {
      this.firing_history = this.firing_history.concat(reducedFirings);
      this.retrieving_more = false;
    } else {
      this.firing_history = reducedFirings;
    }

    if(this.small_screen) {
      this.firingHistoryPickers = this.firing_history.map((history) => {
        return {
          text: `${moment(history.started).format('M/DD/YY, h:mm a')} - ${history.name}`,
          value: history
        }
      })

      if(this.hasMore && !this.retrieving_more) {
        this.firingHistoryPickers.push({
          text: "Load More",
          value: 'load_more'
        })
      }
    }

    if(this.firing_history && this.firing_history.length > 0) {
      this.selectFiring(this.selected_firing || this.firing_history[0].id);
    } else {
      this.retrieving = false;
      this.details = undefined;
      this.selected_firing = undefined;
      console.log("no firings to select")
      // console.log(this.firing_history)
    }

    this.error = false;
  }

  getFiringHistoryError(error) {
    console.log(error);
    this.error = true;
    this.retrieving = false;
  }

  getFiringDetailsError(error) {
    if(this.subtab === 'firing-data') {
      this.error = true;
    }
  }

  selectFiring(firing_id) {    
    if(firing_id !== this.selected_firing) {
      this.retrieving = true;
      this.details = undefined;
      this.selected_firing = firing_id;
      this.kilnService.getFiringDetails(firing_id, this.t_scale, true)
          .then((response) => {this.getDetails(response); })
          .catch((error) => { this.getFiringHistoryError(error) })
      this.kilnService.getFiringNotes(firing_id)
          .then((response) => { this.getNotesSuccess(response) })
          .catch((error) => { this.getFiringHistoryError(error) })
    }
  }

  getFiringStatuses(subtab) {
    this.subtab = subtab;
    this.retrieving = true;
    this.kilnService.getPaginatedStatuses(this.selected_firing, this.t_scale)
        .then((response) => {this.updateStatusInformation(response); })
        .catch((error) => { this.getFiringDetailsError(error); })
  }

  getDetails(data) {
    var dateObject = new Date();

    this.details = data;
    this.updateStatusInformation(data);

    if(data && data.statuses && data.statuses.length > 0) {
      if(this.hasEnded(this.details)) {
        this.program_steps[this.program_steps.length - 1].end_time = this.details.updatedAt;
      }
    } else {
      this.kilnService.getPaginatedStatuses(this.selected_firing, this.t_scale)
          .then((response) => { this.updateStatusInformation(response); })
          .catch((error) => { this.getFiringDetailsError(error); })
    }

    this.retrieving = false;
    this.error = false;
  }

  updateStatusInformation(data) {
    if(data && data.statuses && data.statuses.length > 0) {
      this.program_steps = Object.values(data.statuses.reduce((accumulator, stat, current_index) => {
        if(!accumulator.hasOwnProperty(stat.step)) {
          let last_status_for_previous_segment = (current_index > 0 && stat.createdAt) ? data.statuses[current_index - 1] : undefined;
          if(last_status_for_previous_segment) {
            accumulator[last_status_for_previous_segment.step].end_time = last_status_for_previous_segment.createdAt;
          }

          accumulator[stat.step] = {
            name: stat.step,
            start_time: stat.createdAt,
            end_time: '---'
          }
        }

        return accumulator;
      }, {}));
    }
  }

  async showMessage(message, error) {
    let toast = await this.toastCtrl.create({
     message: message,
     duration: 3000,
     position: 'middle',
     cssClass: error ? 'error' : 'success'
    });

    await toast.present();
  }

  submitFiringNote(formValue) {
    if(!this.submittingNote && !this.editingNote) {
      this.kilnService.createFiringNote(this.selected_firing, {...formValue, ...{kiln_id: this.kilnId, external_id: this.selected_firing}})
          .then((data) => {
            this.submittingNote = false;
            this.createForm(data);
            this.showMessage("Successfully created note for firing", false);
          })
          .catch((error) => {
            this.submittingNote = false;
            this.showMessage("Note could not be created at this time. Please try again.", true);
          })
    } else if(!this.submittingNote && this.editingNote) {
      this.kilnService.updateFiringNote(this.selected_firing, {...formValue, ...{kiln_id: this.kilnId, external_id: this.selected_firing}})
          .then((data) => {
            this.submittingNote = false;
            this.createForm(data);
            this.showMessage("Successfully updated note for firing", false);
          })
          .catch((error) => {
            this.submittingNote = false;
            this.showMessage("Note could not be updated at this time. Please try again.", true);
          })
    }
  }

  getHour(details, step) {
    let older_format_firing_information = moment('2021-01-01T00:00')
    if(moment(details.createdAt).isBefore(older_format_firing_information)) {
      return (step.hr === step.mn) ? '00': step.hr;
    } else {
      return step.hr
    }
  }

  hasEnded(details) {
    let last_status = details.updatedAt;
    var timePassed = moment.duration(moment().diff(moment(last_status)));
    var twoHours = moment.duration(2, 'hours');
    return (details && details.ended) ? true : (timePassed > twoHours);
  }

  subTabChange(selectedTabEvent) {
    if(selectedTabEvent && selectedTabEvent.detail) {
      this.subtab = selectedTabEvent.detail.value;
      // if(['results-graph', 'firing-data'].includes(this.subtab)) {
      //   this.getFiringStatuses(this.subtab)
      // }
    } 
  }

  getElapsedTime(details) {
    return moment.utc(moment.duration(moment(details.updatedAt).diff(moment(details.createdAt))).asMilliseconds()).format("HH:mm");    
  }
}
