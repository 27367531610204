<div id="update-account-info">
  <h4 class='title'>Account Settings <ion-icon name="close" (click)="onDismiss()"></ion-icon></h4>
  <ul class="tabs">
    <li (click)="mainSection = 'users'"
      [class.selected]="mainSection === 'users'">Users</li>
    <li (click)="mainSection = 'general'" *ngIf="canAdminister"
      [class.selected]="mainSection === 'general'">General</li>
    <li (click)="mainSection = 'company'" *ngIf="canAdminister"
      [class.selected]="mainSection === 'company'">Company</li>
    <!-- <li (click)="mainSection = 'payment'" *ngIf="canAdminister"
    [class.selected]="mainSection === 'payment'">Payment</li> -->
    <!-- <li (click)="mainSection = 'invitedUsers'" [class.selected]="mainSection === 'invitedUsers'">Invited Users</li> -->
  </ul>

  <div class="account-settings-main-section">
    <ion-spinner *ngIf="retreivingInfo"></ion-spinner>
    <app-account-settings-general #generalForm *ngIf="mainSection === 'general' && !retreivingInfo"
      [accountInformation]="accountInformation" (changeSection)="changeSection($event)"
      (pending)="setPending($event)" (buttonText)="setButtonName($event)">
    </app-account-settings-general>

    <app-account-settings-company #companyForm *ngIf="mainSection === 'company' && !retreivingInfo"
      [accountInformation]="accountInformation"
      (pending)="setPending($event)" (buttonText)="setButtonName($event)">
    </app-account-settings-company>

    <app-account-settings-users #usersForm *ngIf="mainSection === 'users' && !retreivingInfo"
      [accountInformation]="accountInformation" [canAdminister]="canAdminister"
      (pending)="setPending($event)" (buttonText)="setButtonName($event)">
    </app-account-settings-users>

    <!-- <app-account-payment #paymentsForm *ngIf="mainSection === 'payment' && !retreivingInfo"
      [accountInformation]="accountInformation" [canAdminister]="canAdminister"
      (pending)="setPending($event)" (buttonText)="setButtonName($event)">
    </app-account-payment> -->

    <div id="buttons">
      <input value="Close" type="button" class='close' (click)="onDismiss()" />
      <input [value]="buttonText" type="submit" id="account-submit-button"
        [disabled]="!currentFormIsValid()"
        (click)="save()" *ngIf="!updatePending" />
      <div *ngIf="updatePending" class="submitpending"><ion-spinner name='bubbles'></ion-spinner></div>
      <!-- <input (click)="logCurrentFormValidity()" type="button" value="log form val"/> -->
    </div>
  </div>
</div>
