import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { KilnService } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { ToastController } from '@ionic/angular';

@Component({
  selector: 'app-switch-accounts',
  templateUrl: './switch-accounts.component.html',
  styleUrls: ['./switch-accounts.component.scss'],
})
export class SwitchAccountsComponent implements OnInit {
  @Input() onDismiss;

  public accountForm: UntypedFormGroup;
  public users_accounts = [];
  public default_account;
  public current_account;
  public changingCurrent = false;
  public changingDefault = false;
  public retrievingAccountInformation;
  public hasKilnlinkFreeKilns;

  constructor(
    private accountService: AccountService,
    private kilnService: KilnService,
    private userService: UserService,
    public toastController: ToastController
  ) {
    this.hasKilnlinkFreeKilns = this.userService.hasKilnlinkFreeKilns();
    let account = new UntypedFormControl('', [Validators.required]);
    this.accountForm = new UntypedFormGroup({
      account: account
    });

    this.retrievingAccountInformation = true;
    this.current_account = this.accountService.getCurrentAccount();
    this.accountService.getAllAccounts()
        .then( (response) => {
      this.retrievingAccountInformation = false;
      this.users_accounts = response;
      if(this.users_accounts) {
        let dft = this.users_accounts.find((acct) => acct.default_account);
        this.default_account = (dft && dft.id) || this.current_account;
      }
    })
        .catch((err) => {
          this.retrievingAccountInformation = false;
          console.log(err);
        })
  }

  ngOnInit() {}

  getAccountName(id) {
    let defaultName = (id) => {
      let unavailable_name = "Account Name Unavailable";
      return (id === "0" || id === 0) ? "KilnLink Free Kilns" : unavailable_name;
    }

    if(this.users_accounts) {
      let id_as_int = ((typeof id) === "string") ? parseInt(id) : id;
      let found = this.users_accounts.find((acct) => { return acct.id === id_as_int; })
      return found ? found.name : defaultName(id);
    } else {
      return defaultName(id);
    }
  }

  save(formValue) {
    let selected_kilnlink_free = (parseInt(formValue.account) === 0)
    if(this.changingCurrent) {
      this.accountService.view(formValue.account)
          .then((response) => {
            this.kilnService.setTimerSecond(-1);
            this.onDismiss();
            this.showMessage(`Now viewing, ${this.getAccountName(this.accountForm.value.account)}`)
          })
    } else if(this.changingDefault && !selected_kilnlink_free) {
      this.accountService.setDefault(formValue.account)
          .then(() => {
            this.kilnService.setTimerSecond(-1);
            this.onDismiss();
            this.showMessage(`${this.getAccountName(this.accountForm.value.account)} is now your default account.`)
          })
          .catch(() => { this.showMessage('There was an error changing your default account'); })
    } else {
      this.showMessage("Sorry, you cannot set KilnLink Free kilns as your default.")
    }
  }

  updateForm(account_id) {
    let account = new UntypedFormControl(account_id, [Validators.required]);
    this.accountForm = new UntypedFormGroup({
      account: account
    });
  }

  async showMessage(message) {
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'middle'
    });

    toast.present();
  }
}
