<form [formGroup]="companyForm">
  <ion-item>
    <ion-label>Company Name:</ion-label>
      <ion-input class="ion-text-right" formControlName="name" placeholder="Company Name"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>Contact Name:</ion-label>
    <ion-grid class="first-and-last">
      <ion-row class="ion-justify-content-end">
        <!-- <ion-col offset="4"> -->
          <ion-input class="ion-text-right" formControlName="first_name" placeholder="Please enter a first name" ></ion-input>
          <ion-input formControlName="last_name" placeholder="Please enter a last name"></ion-input>
        <!-- </ion-col> -->
      </ion-row>
    </ion-grid>
  </ion-item>

  <ion-item>
    <ion-label>Weekly Summaries:</ion-label>
    <ion-checkbox class="ion-text-right" formControlName="weekly_summary"></ion-checkbox>
  </ion-item>

  <h6>Mailing Address:</h6>
  <ion-item>
    <ion-label>Line 1:</ion-label>
      <ion-input class="ion-text-right" formControlName="line_one" placeholder="Address Line 1"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>Line 2:</ion-label>
      <ion-input class="ion-text-right" formControlName="line_two" placeholder="Address Line 2"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>City:</ion-label>
      <ion-input class="ion-text-right" formControlName="city" placeholder="City"></ion-input>
  </ion-item>

  <ion-item>
    <ion-label>State:</ion-label>
      <select formControlName="state" class="small">
        <option *ngFor="let st of getStates()" value="{{st.abbreviation}}">{{st.name}} ({{st.abbreviation}})</option>
      </select>
  </ion-item>

  <ion-item>
    <ion-label>Zip Code:</ion-label>
    <ion-input class="ion-text-right" formControlName="postal_code" placeholder="Postal Code"></ion-input>
  </ion-item>
</form>
