import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { ModalController, PopoverController } from '@ionic/angular';
import { KilnService } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';

import { environment } from '../../environments/environment';

@Component({
  selector: 'app-premium-menu-popup',
  templateUrl: './premium-menu-popup.component.html',
  styleUrls: ['./premium-menu-popup.component.scss'],
})
export class PremiumMenuPopupComponent implements OnInit, OnChanges {
  @Input() is_premium = false;
  @Input() hasElevatedPermissions = false;
  @Input() is_on_admin_pages = false;
  @Input() onDismiss: any;
  @Input() is_logged_in_as;
  @Input() viewingKilnlinkFreeKilns;
  @Input() readonlyUser;
  @Input() accountExpired;
  @Input() smallScreen = false;
  @Input() select;

  public show_premium = false;

  constructor(public navCtrl: NavController,
    public kilnService: KilnService,
    public userService: UserService,
    public alertService: AlertService,
    public accountService: AccountService,
    public modalController: ModalController,
    public popoverController: PopoverController
  ) {
    this.show_premium = environment.show_premium && !this.viewingKilnlinkFreeKilns;
  }

  ngOnInit() {
    this.show_premium = environment.show_premium && !this.viewingKilnlinkFreeKilns;
    if(this.select) {
      if(this.select === 'account') this.showModal('update-account-info')
      if(this.select === 'network') this.showModal('manage-network')
      if(this.select === 'user-alerts') this.showModal('alert-settings')
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngAfterViewInit() {}

  async togglePremium() {
    if(this.hasElevatedPermissions && this.show_premium) {
      var is_premium = document.body.classList.contains("premium")
      document.body.classList.toggle('premium', !is_premium);
      this.is_premium = !is_premium;
      this.onDismiss({is_premium: this.is_premium});
    }
  }

  public logOut() {
    this.userService.tryLogOut();
    this.kilnService.logOut();
    this.onDismiss();
    this.navCtrl.navigateForward('/');
  }

  public addKilnClick() {
    this.onDismiss();
    this.navCtrl.navigateForward('add-kiln');
  }

  public registerControllers() {
    this.onDismiss();
    this.navCtrl.navigateForward('register-controller');
  }

  public goToKilnSearch() {
    this.onDismiss();
    this.navCtrl.navigateForward('kiln-search');
  }

  public showModal(modal_name, navigateForward = false) {
    let premium_only_modals = ['alert-settings', 'manage-network', 'update-account-info']
    if(premium_only_modals.includes(modal_name) && this.viewingKilnlinkFreeKilns && !this.accountExpired) {
      this.onDismiss({showKilnlinkFreeAlert: true});
    } else {
      this.onDismiss({modal: modal_name, navigateForward: this.smallScreen || navigateForward});
    }
  }

  public exitLoginAs() {
    this.kilnService.logInAs();
    this.userService.unsetLoginAs();
    this.accountService.unsetLoginAs();
    this.onDismiss({unsetLoginAs: true});
  }
}
