<ion-spinner name="bubbles" *ngIf="!viewingKilnlinkFreeKilns && !gotData"></ion-spinner>
<div class="no-data" *ngIf="!viewingKilnlinkFreeKilns && kiln_diagnostics && kiln_diagnostics.length === 0 && gotData">
  We didn't retrieve any diagnostic information for this kiln.
</div>
<div class="table" *ngIf="!small_screen && (viewingKilnlinkFreeKilns || kiln_diagnostics && kiln_diagnostics.length > 0 && gotData)">
  <div class="thead">
    <div class="tr">
      <div class="th">Date/Time</div>
      <div class="th">Status</div>
      <div class="th">Amps 1</div>
      <div class="th">Amps 2</div>
      <div class="th">Amps 3</div>
      <div class="th">Voltage - No Load</div>
      <div class="th">Voltage - Full Load</div>
      <div class="th">Voltage 1</div>
      <div class="th">Voltage 2</div>
      <div class="th">Voltage 3</div>
    </div>
  </div>
  <div="tbody" *ngIf="viewingKilnlinkFreeKilns">
    <div>You are viewing a KilnLink Free kiln. Upgrade today to view diagnostic information.</div>
  </div>
  <div class="tbody" *ngIf="!viewingKilnlinkFreeKilns">
    <div class="tr" *ngFor="let diag of kiln_diagnostics">
      <div class="td">{{ (diag.date) | date:'short'}}</div>
      <div class="td">{{ diag.status || kiln && kiln.status && kiln.status.mode }}</div>
      <div class="td">{{ diag.a1 }}</div>
      <div class="td">{{ diag.a2 }}</div>
      <div class="td">{{ diag.a3 }}</div>
      <div class="td">{{ diag.nl }}</div>
      <div class="td">{{ diag.fl }}</div>
      <div class="td">{{ diag.v1 }}</div>
      <div class="td">{{ diag.v2 }}</div>
      <div class="td">{{ diag.v3 }}</div>
    </div>
  </div>
</div>

<div class="table" [class.small]="small_screen"
  *ngIf="small_screen && (viewingKilnlinkFreeKilns || kiln_diagnostics && kiln_diagnostics.length > 0 && gotData)">
  <div class="tbody" *ngIf="viewingKilnlinkFreeKilns">
    <div>You are viewing a KilnLink Free kiln. Upgrade today to view diagnostic information.</div>
  </div>
  <div class="tbody" *ngIf="!viewingKilnlinkFreeKilns">
    <ion-item *ngIf="small_screen">
      <ion-label *ngIf="selected_diagnostic">{{selected_diagnostic.date | date:'short'}}</ion-label>
      <ion-button slot="end" (click)="showPicker()" *ngIf="!viewingKilnlinkFreeKilns && (kiln_diagnostics && kiln_diagnostics.length > 0 && gotData) && small_screen">Choose</ion-button>
    </ion-item>

    <ion-card *ngIf="selected_diagnostic">
      <ion-card-header>
        <ion-card-title>{{ selected_diagnostic.status || kiln && kiln.status && kiln.status.mode }}</ion-card-title>          
        <!-- <ion-card-subtitle>{{selected_diagnostic.date | date:'short'}}</ion-card-subtitle> -->
      </ion-card-header>
      <ion-card-content>
        <ion-list>
          <ion-item-divider>Amperage</ion-item-divider>
          <ion-item>
            <ion-label>Amps 1</ion-label>
            <p>{{selected_diagnostic.a1}}</p>
          </ion-item>

          <ion-item>
            <ion-label>Amps 2</ion-label>
            <p>{{selected_diagnostic.a2}}</p>
          </ion-item>

          <ion-item>
            <ion-label>Amps 3</ion-label>
            <p>{{selected_diagnostic.a3}}</p>
          </ion-item>

          <ion-item-divider>Voltage</ion-item-divider>
          <ion-item>
            <ion-label>No Load</ion-label>
            <p>{{selected_diagnostic.nl}}</p>
          </ion-item>

          <ion-item>
            <ion-label>Full Load</ion-label>
            <p>{{selected_diagnostic.fl}}</p>
          </ion-item>

          <ion-item>
            <ion-label>1</ion-label>
            <p>{{selected_diagnostic.v1}}</p>
          </ion-item>

          <ion-item>
            <ion-label>2</ion-label>
            <p>{{selected_diagnostic.v2}}</p>
          </ion-item>

          <ion-item>
            <ion-label>3</ion-label>
            <p>{{selected_diagnostic.v3}}</p>
          </ion-item>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-card *ngIf="!selected_diagnostic">
      <ion-card-header>
        <ion-card-title>No Diagnostic Selected</ion-card-title>                  
      </ion-card-header>
      <ion-card-content>
        <p>Please select a diagnostic from the list to view details.</p>
      </ion-card-content>
    </ion-card>
<!--     
    <div *ngFor="let diag of kiln_diagnostics" class="diag-detail">
      <div class="tr title">
        <h6>{{ (diag.date) | date:'short'}}</h6>
        <label>{{ diag.status || kiln && kiln.status && kiln.status.mode }}</label>
      </div>

      <div class="thead">
        <div class="tr thead">
          <div class="th">Amps 1</div>
          <div class="th">Amps 2</div>
          <div class="th">Amps 3</div>
        </div>
      </div>
      <div class="tr" >
        <div class="td">{{ diag.a1 }}</div>
        <div class="td">{{ diag.a2 }}</div>
        <div class="td">{{ diag.a3 }}</div>
      </div>

      <div class="thead second">
        <div class="tr">
          <div class="th">Voltage - No Load</div>
          <div class="th">Voltage - Full Load</div>
          <div class="th">Voltage 1</div>
          <div class="th">Voltage 2</div>
          <div class="th">Voltage 3</div>
        </div>
      </div>

      <div class="tr">
        <div class="td">{{ diag.nl }}</div>
        <div class="td">{{ diag.fl }}</div>
        <div class="td">{{ diag.v1 }}</div>
        <div class="td">{{ diag.v2 }}</div>
        <div class="td">{{ diag.v3 }}</div>
      </div>
    </div> -->
  </div>
</div>
