import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(
    private router: Router,
    private userService: UserService
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {      
      const currentUser = this.userService.isLoggenIn;
      if (currentUser) {
        return true;
      }

      this.userService.setRedirect(next.routeConfig.path, next.fragment)
      this.router.navigate([`/`]);
      return false;
  }
}
