// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  claimApi: "https://stagingapi.kilnlink.com",
  kilnApi: "https://bart-kiln-status-api-staging.herokuapp.com",
  show_premium: true,
  package_name: 'com.skutt.kilnlinkprem',
  clientId: '$2b$10$n8aVufFUov9SlJsZVVrAfuySAj774hXtez8w9DyT2GrGb/92..k7q',
  firmware_url: 'https://www.bartinst.com',
  freeToPremium: true
};
