import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, AfterContentInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ModalController, ToastController } from '@ionic/angular';
import { UserService } from 'src/app/services/user.service';
import { KilnService } from 'src/app/services/kiln.service';
import { AccountService } from 'src/app/services/account.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-login-as-popup',
  templateUrl: './login-as-popup.component.html',
  styleUrls: ['./login-as-popup.component.scss'],
})
export class LoginAsPopupComponent implements OnInit {
  public onDismiss;

  public users = [];
  public users_by_company = [];
  public retrieving;
  public searching = false;
  public filteredUsers;
  public filteredUsersByCompany;

  public searchForm: UntypedFormGroup;
  @ViewChild('searchTermId') searchTermId: ElementRef;

  constructor(private userService: UserService,
    private kilnService: KilnService,
    private accountService: AccountService,
    private toastCtrl: ToastController,
    private ref: ChangeDetectorRef,
    private router: Router)
  {
    this.getUsers();
  }

  ngOnInit() {
    this.getUsers();
    this.startSearch();
  }

  getUsers() {
    if(!this.retrieving) {
      this.retrieving = true;
      this.userService.getUsersAsAdmin()
          .then((data) => {
            this.users_by_company = data;
            data.forEach((company) => {
              this.users = this.users.concat(company.users);
            })
            this.retrieving = false;
          })
          .catch((error) => {
            this.retrieving = false;
            this.showMessage("There was an error getting the list of users, please try again.");
          });
    }
  }

  loginAs(user, account_id) {
    this.userService.setLoginAs(user, account_id)
        .then((data) => {
          this.kilnService.logInAs();
          this.accountService.setCurrent(data.account);
          this.showMessage(`You are now logged in as ${data.email || data.username}`);
          this.dismiss(data);
        })
        .catch((error) => {
          console.log(error)
          this.showMessage("There was an error logging in as the user, please try again.");
          this.dismiss();
        })
  }

  exitLoginAs() {
    this.userService.unsetLoginAs();
    this.accountService.unsetLoginAs();
    this.dismiss();
  }

  startSearch() {
    this.searching = true;
    this.searchForm = new UntypedFormGroup({
      searchTerm: new UntypedFormControl('')
    });
    this.ref.detectChanges();
    if(this.searchTermId) { this.searchTermId.nativeElement.focus(); }
  }

  filter(value) {
    let filterRegex = new RegExp(value.searchTerm && value.searchTerm.toLowerCase());
    this.filteredUsers = this.users.filter((a) => {
      return a && filterRegex.test(a.username && a.username.toLowerCase())
        || filterRegex.test(a.email && a.email.toLowerCase())
        || filterRegex.test(a.first_name && a.first_name.toLowerCase())
        || filterRegex.test(a.last_name && a.last_name.toLowerCase());
    })
    
    this.filteredUsersByCompany = this.users_by_company.reduce((aggregate, c) => {
      let company_copy = Object.assign({}, c)
      let company_name_match = company_copy && filterRegex.test(company_copy.name && company_copy.name.toLowerCase());
      if(!company_name_match) {
        company_copy.users = company_copy.users.filter((a) => {
          return a &&
            (filterRegex.test(a.username && a.username.toLowerCase())
              || filterRegex.test(a.email && a.email.toLowerCase())
              || filterRegex.test(a.first_name && a.first_name.toLowerCase())
              || filterRegex.test(a.last_name && a.last_name.toLowerCase())
            );
        })
      }

      if(company_name_match || (company_copy.users && company_copy.users.length > 0 )) {
        aggregate.push(company_copy);
      }
      return aggregate;
    }, [])
  }

  cancelSearch() {
    this.searching = false;
    this.filteredUsers = undefined;
  }

  getUsersToShow() {
    return this.filteredUsersByCompany ? this.filteredUsersByCompany : this.users_by_company;
  }

  async showMessage(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      duration: 3000,
      position: 'middle'
    });

    toast.present();
  }

  showUserNotAssociatedToAccountMessage() {
    this.showMessage("User is not associated to an account yet");
  }

  dismiss(data = null) {
    this.onDismiss ? this.onDismiss(data) : this.router.navigate(['kiln-list']);
  }
}
