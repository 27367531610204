import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-account-invites',
  templateUrl: './account-invites.component.html',
  styleUrls: ['./account-invites.component.scss'],
})
export class AccountInvitesComponent implements OnInit {
  @Input() onDismiss;

  constructor() { }

  ngOnInit() {}

}
