<form [formGroup]="ownerForm">
  <div>
    <label>Contact Name:</label>
    <div class="first-and-last">
      <input formControlName="first_name"/>
      <input formControlName="last_name"/>
    </div>
  </div>

  <h6>Mailing Address:</h6>
  <div>
    <label>Line 1:</label>
    <input formControlName="line_one"/>
  </div>

  <div>
    <label>Line 2:</label>
    <input formControlName="line_two"/>
  </div>

  <div>
    <label>City:</label>
    <input formControlName="city"/>
  </div>

  <div>
    <label>State:</label>
    <select formControlName="state">
      <option *ngFor="let st of getStates()" value="{{st.abbreviation}}">{{st.name}} ({{st.abbreviation}})</option>
    </select>
  </div>

  <div>
    <label>Zip Code:</label>
    <input formControlName="postal_code"/>
  </div>
</form>
