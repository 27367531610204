import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { AccountSettingsCompanyComponent } from './company.component';
import { AccountSettingsGeneralComponent } from './general.component';
import { AccountSettingsOwnerComponent } from './owner.component';
import { AccountSettingsUsersComponent } from './users.component';
import { AccountPaymentComponent } from '../account-payment/account-payment.component';

let components_list = [
  AccountSettingsCompanyComponent,
  AccountSettingsGeneralComponent,
  AccountSettingsOwnerComponent,
  AccountSettingsUsersComponent,
  AccountPaymentComponent
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule
    ],
    declarations: components_list,
    exports: components_list,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AccountSettingsComponentModule {}
