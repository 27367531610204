import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NavigationExtras, ActivatedRoute } from '@angular/router';
import { NavController, ToastController } from '@ionic/angular';
import { KilnService } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { AccountService } from 'src/app/services/account.service';
import { environment } from '../../environments/environment';
import { StorageService } from '../services/storage.service';

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage implements OnInit {
  public errorText: string = null;
  public loginUsername: string;
  public loginEmail: string;
  public loginPassword: string;
  public loginRemember: boolean = true;
  public loginBtnText: string;
  public isLoggedIn: boolean;
  public loginData = {
    "status":"",
    "email":"",
    "token":"",
    "needs_to_accept_new_terms_and_conditions": true,
    "new_terms_and_conditions":"",
    "role": ""
  };
  public isLoggingIn = false;
  public logoUrl = "assets/kilnlink_logo_transparent.png";
  public successMessage;
  public show_premium = false;
  public hasLoginInfo = false;
  private redirectRoute;

  constructor(public navCtrl: NavController,
    public userService: UserService,
    public kilnService: KilnService,
    public accountService: AccountService,
    private storageService: StorageService,
    private route: ActivatedRoute,
    private toastCtrl: ToastController,
    private elementRef: ElementRef
  ) {
    this.userService.loginStatus.subscribe( (data) => {
      if(data.newPasswordRequired) {
        this.needsPasswordChanged(data)
      } else {
        this.onLoginSuccess(data)
      }
    });

    this.userService.storageUpdate.subscribe((data) => {
      if(data && data['email']) { this.onStorageEmail(data['email']) }
      if(data && data['password']) { this.onStorageEmail(data['password']) }
    })

    // this.events.subscribe('needsLoginAgain', (data) => {
    //   this.showSuccessMessage("Your session has expired, please log in again");
    //   this.onLogoutSuccess(data);
    // })

    this.route.queryParams.subscribe(params => {
      this.successMessage = params['successMessage'];
      if(this.successMessage) {
        this.showSuccessMessage(this.successMessage)
      }
    });
    this.show_premium = environment.show_premium;
    if(this.show_premium) {
      document.body.classList.toggle('premium', true);
    }
  }

  ngOnInit() {
    this.userService.getStoredEmail();
    this.userService.getStoredPassword();
    this.kilnService.startTimerSecond(); //start the second timer which will always run
    let page = this;

    page.storageService.get('email').then(function(val) {
      page.loginEmail = val;
      page.storageService.get('password').then(function(val) {
        page.loginPassword = val;
        if(page.loginRemember) {
          if(page.loginEmail && page.loginPassword) {
            page.hasLoginInfo = true;
            page.isLoggingIn = true;
            page.userService.tryLogin(page.loginEmail, page.loginPassword)
                .subscribe(
                    function(response) { page.onLoginSuccess(response)},
                    function(error) { page.onLoginError(error) })
          }
        }
      });
    });
  }

  public ngOnDestroy () {
    this.kilnService.killTimerSecond(); //stop the second timer at close
    this.userService.killTimerSecond();
    this.isLoggingIn = false;
  }

  public ngAfterViewInit(){
    if (this.userService.LoggedIn()){
      this.loginBtnText = 'Logout';
      this.isLoggedIn = true;
    } else {
      this.loginBtnText = 'Sign In';
      this.isLoggedIn = false;
      this.isLoggingIn = false;
    }
  }

  async showSuccessMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message || this.successMessage,
      duration: 3000,
      position: 'middle',
      cssClass: 'error'
    });

    toast.onDidDismiss().then(() => {
      this.successMessage = null;
    });

    toast.present();
  }

  public onStorageEmail(email){
    if (email){
      this.loginEmail = email
    };
  }

  public onStoragePassword(password){
    if (password){
      this.loginPassword = password
    };
  }

  public loginClick() {
    if (this.loginRemember) {
      //store the user info if "remember me" is checked
      this.userService.setStoredUserData(this.loginEmail, this.loginPassword);
    } else {
      //remove the stored user info if "remember me" is not checked
      this.userService.removeStoredUserData();
    }
    if (!this.userService.LoggedIn()) {
      this.isLoggingIn = true;
      this.userService.tryLogin(this.loginEmail, this.loginPassword)
          .subscribe((response) => { this.onLoginSuccess(response); },
              (error) => { this.onLoginError(error) })
    } else {
      this.isLoggingIn = false;
      this.userService.tryLogOut(this.loginData.token).then((response) => { this.onLoginSuccess(response)} ).catch((error) => { this.onLoginError(error)})
    }
  }

  cancelLogin() {
    this.isLoggingIn = false;
  }

  private needsPasswordChanged(response) {
    console.log('needs new password');
    this.loginData = response;
    this.isLoggedIn = true;
    this.isLoggingIn = false;
    let navigationExtras: NavigationExtras = {
        queryParams: {
          userAttributes: response.userAttributes,
          email: this.loginEmail,
          old_password: this.loginPassword
        }
    };
    this.navCtrl.navigateForward('new-password', navigationExtras);
  }

  private onLoginSuccess(response) {
    if(response.account && this.accountService) {
      this.accountService.setCurrent(response.account);
      // if(response.account === '0' || response.account === 0 && response.kiln_info && response.kiln_info.length === 0) { this.kilnService.getKilnData()}
    }

    this.loginData = response;
    this.isLoggedIn = true;
    this.isLoggingIn = false;
    this.errorText = null;
    this.successMessage = null;
    if (this.loginData.needs_to_accept_new_terms_and_conditions){
      let navigationExtras: NavigationExtras = {
          queryParams: {
            email: this.loginData.email,
            token: this.loginData.token,
            newTerms: this.loginData.new_terms_and_conditions
          }
      };
      this.navCtrl.navigateForward('accept-terms', navigationExtras);
    } else {
      this.kilnService.setTimerSecond(1);
      this.userService.setTimerSecond(1);

      if(["super-admin", "premium-user"].includes(this.loginData.role) && this.show_premium) {
        document.body.classList.toggle('premium', true);
      }

      console.log(`ON LOGIN SUCCESS`)
      let nextRoute = this.userService.getRedirect() ? this.userService.getRedirect() : {path: 'kiln-list'};      
      this.userService.clearRedirect()
      let navigationExtras: NavigationExtras = {
          queryParams: {
            token: this.loginData.token,
            email: this.loginData.email,
            role: this.loginData.role,
            onLogin: true            
          }, fragment: nextRoute.fragment
      };
      
      console.log(`navigating to - ${JSON.stringify(nextRoute.path)}`)
      this.navCtrl.navigateForward(nextRoute.path, navigationExtras);
    }
  }

  private onLoginError(error) {
    this.isLoggingIn = false;
    if(error.newPasswordRequired) {
      let navigationExtras: NavigationExtras = {
          queryParams: {
            attributes: error.attributes,
            required: error.requiredAttributes
          }
      };
      this.navCtrl.navigateForward('new-password', navigationExtras);
    } else {
      this.errorText = (error.error && error.error.message) || 'Login error! Please check your username and password and try again.';
    }
  }

  private onLogoutSuccess (response) {
    this.successMessage = null;
    if(!this.loginRemember) {
      this.loginEmail = '';
      this.loginPassword = '';
    }

    this.userService.removeStoredUserData();
    this.loginBtnText = 'Sign In';
    this.isLoggedIn = false;
    this.isLoggingIn = false;
    this.loginData.token = '';
  }

  // private onLogoutError (error){
  //   this.errorText = 'Logout error! ' + error;
  // }

  public toKilnList(){
    if (this.loginData.needs_to_accept_new_terms_and_conditions){
      let navigationExtras: NavigationExtras = {
          queryParams: {
            token: this.loginData.token,
            newTerms: this.loginData.new_terms_and_conditions
          }
      };
      this.navCtrl.navigateForward('accept-terms', navigationExtras);
    } else {
      console.log(`TO KILN LIST`)
      let nextRoute = this.userService.getRedirect() ? this.userService.getRedirect() : {path: 'kiln-list'};
      this.userService.clearRedirect()
      
      let navigationExtras: NavigationExtras = {
        queryParams: {
          token: this.loginData.token,
          email: this.loginData.email,
          role: this.loginData.role          
        }, fragment: nextRoute.fragment
      };
      
      console.log(`navigating to - ${nextRoute.path}`)
      this.navCtrl.navigateForward(nextRoute.path, navigationExtras);
    }
  }

  public createUser(){
    this.navCtrl.navigateForward('new-user');
  }

  public forgotPassword() {
    this.navCtrl.navigateForward('forgot-password');
  }

  public focusInput(input) {
    var el = this.elementRef.nativeElement.querySelector(`ion-input[id="${input}"] input`);
    el && el.focus();
  }
}
