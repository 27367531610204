import {
 HttpEvent,
 HttpInterceptor,
 HttpHandler,
 HttpRequest,
 HttpResponse,
 HttpErrorResponse,
 HttpHeaders
} from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

import { UserService } from 'src/app/services/user.service';
import { NavController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { environment } from '../environments/environment';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
 constructor(
   public userService: UserService,
   public navCtrl: NavController,
   private nativeHttp: HTTP,
   private platform: Platform,
   private appVersion: AppVersion
 ) {}

intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   if (!this.platform.is('cordova')) {
     let token = this.userService.getAuthToken();
     let headers = {
       'Content-Type': 'application/json',
       'Accept': 'application/json'
     }

     if(request.url.includes(environment.kilnApi)) {
       headers["x-app-info"] = `${environment.package_name},${environment.clientId}`;
       if(token) {
         // headers["Authorization"] = `Bearer ${token}`;
         headers["x-app-auth"] = "token";
         headers["x-access-token"] = token;
       }
     }

     let authenticatedRequest = request.clone({setHeaders: headers})
     return next.handle(authenticatedRequest)
       .pipe(
         retry(1),
         catchError((error) => this.handleError(error, this.userService))
       )
   } else {
     return from(this.handleNativeRequest(request))
       .pipe(
         retry(1),
         catchError((error) => this.handleError(error, this.userService))
       );
   }
 }

 private async addHeaders(request) {
   let token = this.userService.getAuthToken();
   request.headers = request.headers.append("x-app-info", this.appToken());
   // request.headers = request.headers.append("Authorization", `Bearer ${token}`);
   request.headers = request.headers.append("x-access-token", token);
   request.headers = request.headers.append("x-app-auth", "token");
 }

 private async appToken() {
   let name;
   if(!this.platform.is('cordova')) {
     name = environment.package_name;
   } else {
     name = await this.appVersion.getPackageName();
   }

   return `${name},${environment.clientId}`;
 }

 handleError(error: HttpErrorResponse, userService) {
   let error_info = error.error;
   let is_token_error = error_info && error_info.hasOwnProperty("error_type") && error_info["error_type"] && error_info["error_type"] === "TokenExpiredError";

   if(is_token_error || (error_info && error_info.message === "Invalid Token" || error_info.message === 'Invalid authentication')) {
     if(this.userService) {
       this.userService.tryLogOut();
     } else {
       userService && userService.tryLogOut();
     }
     console.log(error);
     this.navCtrl.navigateForward('/');
   }

   return throwError(error);
 }

 private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
   let token = this.userService.getAuthToken();
   let app_token = await this.appToken();
   const headerKeys = request.headers.keys();
   const headers = {
     "x-app-info": app_token
   };

   if(token) {
     // headers["Authorization"] = `Bearer ${token}`;
     headers["x-app-auth"] = token;
   }

   headerKeys.forEach((key) => {
     headers[key] = request.headers.get(key);
   });

   try {
     await this.platform.ready();

     const method = <HttpMethod> request.method.toLowerCase();
     const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url, {
       method: method,
       data: request.body,
       headers: headers,
       serializer: 'json',
     });

     let body;
     try {
       body = JSON.parse(nativeHttpResponse.data);
     } catch (error) {
       body = { response: nativeHttpResponse.data };
     }

     const response = new HttpResponse({
       body: body,
       status: nativeHttpResponse.status,
       headers: new HttpHeaders(nativeHttpResponse.headers),
       url: nativeHttpResponse.url,
     });

     // console.log(`returning from http request, body: ${JSON.stringify(body)}`)
     return Promise.resolve(response);
   } catch (error) {
     if (!error.status) { return Promise.reject(error); }

     const response = new HttpResponse({
       body: JSON.parse(error.error),
       status: error.status,
       headers: error.headers,
       url: error.url,
     });

     return Promise.reject(response);
   }
 }
}
