import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'myskutt', loadChildren: () => import('./myskutt/myskutt.module').then(m => m.MyskuttPageModule) },
  { path: 'home', loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)},
  { path: 'add-kiln', loadChildren: () => import('./add-kiln/add-kiln.module').then(m => m.AddKilnPageModule),
    canActivate: [AuthGuard]  },
  { path: 'add-user', loadChildren: () => import('./add-user/add-user.module').then(m => m.AddUserPageModule)},
  { path: 'forgot-password', loadChildren: () => import('./forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)},
  { path: 'kiln-list', loadChildren: () => import('./kiln-list/kiln-list.module').then(m => m.KilnListPageModule),
    canActivate: [AuthGuard]  },
  { path: 'kiln-search', loadChildren: () => import('./kiln-search/kiln-search.module').then(m => m.KilnSearchPageModule),
    canActivate: [AuthGuard]  },
  { path: 'kiln-settings', loadChildren: () => import('./kiln-settings/kiln-settings.module').then(m => m.KilnSettingsPageModule),
    canActivate: [AuthGuard]  },
  { path: 'kiln-status', loadChildren: () => import('./kiln-status/kiln-status.module').then(m => m.KilnStatusPageModule),
    canActivate: [AuthGuard]  },
  { path: 'kiln-users', loadChildren: () => import('./kiln-users/kiln-users.module').then(m => m.KilnUsersPageModule),
    canActivate: [AuthGuard]  },
  { path: 'new-user', loadChildren: () => import('./new-user/new-user.module').then(m => m.NewUserPageModule) },
  { path: 'kilnlink-premium-new-user', loadChildren: () => import('./new-user-premium/new-user-premium.module').then(m => m.NewUserPremiumPageModule) },
  { path: 'reset-password', loadChildren: () => import('./reset-password/reset-password.module').then(m => m.ResetPasswordPageModule)},
  { path: 'confirm-user', loadChildren: () => import('./confirm-user/confirm-user.module').then(m => m.ConfirmUserPageModule) },
  { path: 'new-password', loadChildren: () => import('./new-password/new-password.module').then(m => m.NewPasswordPageModule) },
  { path: 'user-search-results', loadChildren: () => import('./user-search-results/user-search-results.module').then(m => m.UserSearchResultsPageModule), canActivate: [AuthGuard] },
  { path: 'raw-kiln-status', loadChildren: () => import('./raw-kiln-status/raw-kiln-status.module').then(m => m.RawKilnStatusPageModule), canActivate: [AuthGuard] },
  { path: 'register-controller', 
      canActivate: [AuthGuard],
      loadChildren: () => import('./register-controller/register-controller.module').then(m => m.RegisterControllerPageModule) },
  { path: 'admin-account-list', 
      canActivate: [AuthGuard],
      loadChildren: () => import('./admin-account-list/admin-account-list.module').then(m => m.AdminAccountListPageModule), 
  },
  { path: 'admin-network-device', 
      canActivate: [AuthGuard],
      loadChildren: () => import('./admin-network-device/admin-network-device.module').then(m => m.AdminNetworkDevicePageModule), 
   },
  { path: 'admin-users-page', 
      canActivate: [AuthGuard],
      loadChildren: () => import('./admin-users-page/admin-users-page.module').then(m => m.AdminUsersPagePageModule) },
  { path: 'kiln/:kiln_id/program',
    canActivate: [AuthGuard],
    loadChildren: () => import('./current-program/current-program.module').then(m => m.CurrentProgramPageModule) },
  {
    path: 'user/info',
    canActivate: [AuthGuard],
    loadChildren: () => import('./user-info/user-info.module').then( m => m.UserInfoPageModule)
  },
  {
    path: 'manage-network',
    canActivate: [AuthGuard],
    loadChildren: () => import('./manage-network/manage-network.module').then( m => m.ManageNetworkPageModule)
  },
  {
    path: 'update-account-info',
    canActivate: [AuthGuard],
    loadChildren: () => import('./update-account-info/update-account-info.module').then(m => m.UpdateAccountInfoPageModule)
  },
  {
    path: 'alert-settings',
    canActivate: [AuthGuard],
    loadChildren: () => import('./alert-settings-popup/alert-settings-popup.module').then( m => m.AlertSettingsPopupPageModule)
  },
  {
    path: 'alert-settings/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./alert-settings-popup/alert-settings-popup.module').then( m => m.AlertSettingsPopupPageModule)
  },
  {
    path: 'change-password',
    canActivate: [AuthGuard],
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'login-as',
    canActivate: [AuthGuard],
    loadChildren: () => import('./login-as/login-as.module').then( m => m.LoginAsPageModule)
  },
  {
    path: 'switch-account',
    canActivate: [AuthGuard],
    loadChildren: () => import('./switch-accounts/switch-accounts.module').then( m => m.SwitchAccountsPageModule)
  },
  {
    path: 'update-account-info',
    canActivate: [AuthGuard],
    loadChildren: () => import('./update-account-info/update-account-info.module').then( m => m.UpdateAccountInfoPageModule)
  },
  {
    path: 'kiln-details/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./kiln-details-sidebar/kiln-details-sidebar.module').then( m => m.KilnDetailsSidebarPageModule)
  },
  {
    path: 'kiln-diagnostics/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./controller-diagnostics/controller-diagnostics.module').then( m => m.ControllerDiagnosticsPageModule)
  },
  {
    path: 'firing-history/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./firing-history/firing-history.module').then( m => m.FiringHistoryPageModule)
  },
  {
    path: 'maintenance/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./maintenance/maintenance.module').then( m => m.MaintenancePageModule)
  },
  {
    path: 'sent-alerts/:kiln_id',
    canActivate: [AuthGuard],
    loadChildren: () => import('./sent-alerts/sent-alerts.module').then( m => m.SentAlertsPageModule)
  },
  {
    path: 'start-trial',
    canActivate: [AuthGuard],
    loadChildren: () => import('./start-trial/start-trial.module').then( m => m.StartTrialPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
