<div id="manage-network-container">
  <h4 class="network-popup-title">Manage My Network <ion-icon name="close" (click)="onDismiss()"></ion-icon></h4>
  <ul class="tabs">
    <li (click)="selected_tab = 'kilns'"
     [class.selected]="selected_tab === 'kilns'">
     Kilns
   </li>
    <li (click)="selected_tab = 'network'" *ngIf="network_devices && network_devices.length > 0 && hasElevatedPermissions"
     [class.selected]="selected_tab === 'network'">
     Network Devices
   </li>
  </ul>

  <div class="main">
    <div *ngIf="selected_tab === 'kilns'">
      <ion-item color="dark" id="list-title">
        <ion-label><h6>Kilns in My Network</h6></ion-label>
        <ion-label slot="end" class="title-buttons">
          <ion-button *ngIf="small_screen" (click)="showPicker()">Select</ion-button>
          <ion-icon class="ion-padding-end icon" *ngIf="!kilnLimitMet && canAdminister" name="add" color="light" (click)="addKiln()"></ion-icon>
          <ion-icon class="ion-padding-end icon" *ngIf="kilnLimitMet && canAdminister" [disabled]='true' name="add" color="medium"></ion-icon>
          <ion-icon name="remove" *ngIf="canAdminister" class="icon" color="light" (click)="removeKiln()"></ion-icon>
        </ion-label>
      </ion-item>      
      <ion-list *ngIf="kilns && kilns.length > 0 && selectedKiln && !small_screen">
        <ion-item *ngFor="let kiln of kilns" (click)="selectKiln(kiln)"
          [color]="selectedKiln ? ((selectedKiln.serial_number ? (selectedKiln.serial_number === kiln.serial_number) : (selectedKiln.kiln_id === kiln.kiln_id)) ? 'success': 'light') : 'light'">
          <ion-label>{{(kiln && kiln.name) ? kiln.name : 'Name Unavailable'}}</ion-label>
        </ion-item>
      </ion-list>
      <div *ngIf="retrievingInformation"><ion-spinner name="bubbles"></ion-spinner></div>
      <div *ngIf="!retrievingInformation && (!kilns || (kilns && kilns.length === 0))">Sorry, you do not have any kilns yet.</div>
    </div>
    <div *ngIf="selected_tab === 'kilns'" class="form">
      <ion-item>
        <ion-label><h6>Edit Kiln Information</h6></ion-label>
      </ion-item>
      <form [formGroup]="manageKilnForm" *ngIf="selectedKiln || new_kiln" (ngSubmit)="submitKiln(manageKilnForm.value)">
        <ion-item>
          <ion-label>Kiln Model</ion-label>
          <!-- <input *ngIf="canAdminister" autofocuse="true" type="text"
            formControlName="model" placeholder="Please enter a kiln model"/> -->
          <!-- <input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" formControlName="model"/> -->
          <ion-select class="kiln_model" *ngIf="canAdminister" formControlName="model">
            <ion-select-option *ngFor="let model of model_options"
              [disabled]="model.blank"
              value="{{model.modelName}}">
              {{model.modelName}}
            </ion-select-option>
          </ion-select>

          <!-- <ion-label class="right">Is KMT</ion-label>
          <input class="right" *ngIf="canAdminister" type="checkbox" formControlName="is_kmt"/>
          <input class="right" *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="checkbox" formControlName="is_kmt"/> -->
        </ion-item>

        <!-- <div *ngIf="!manageKilnForm.value.model || (manageKilnForm.value.model && (manageKilnForm.value.model === 'Other' || manageKilnForm.value.model === ''))"> -->
        <ion-item>
          <ion-label>Is the controller a KMT (touchscreen control)?</ion-label>
          <ion-checkbox class="right" *ngIf="canAdminister" type="checkbox" formControlName="is_kmt"></ion-checkbox>
          <ion-checkbox class="right" *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="checkbox" formControlName="is_kmt"></ion-checkbox>
        </ion-item>

        <ion-item>
          <ion-label>Kiln Name</ion-label>
          <ion-input class="ion-text-right"*ngIf="canAdminister" type="text" formControlName="name" placeholder="Please enter a kiln name"></ion-input>
          <ion-input class="ion-text-right"*ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Voltage</ion-label>
          <!-- <input *ngIf="canAdminister" type="text" formControlName="voltage" placeholder="Voltage"/> -->
          <!-- <input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" formControlName="voltage"/> -->
          <ion-select *ngIf="canAdminister" formControlName="voltage">
            <ion-select-option *ngFor="let voltage of voltage_options" value="{{voltage}}">{{voltage}}</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label>Phase</ion-label>
          <!-- <input *ngIf="canAdminister" type="text" formControlName="phase"/> -->
          <!-- <input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" formControlName="phase" placeholder="Phase"/> -->
          <ion-select *ngIf="canAdminister" formControlName="phase">
            <ion-select-option *ngFor="let phase of phase_options" value="{{phase}}">{{phase}}</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label>Serial Number</ion-label>
          <ion-input class="ion-text-right" *ngIf="canAdminister" type="text" formControlName="kiln_serial_number" placeholder="Kiln Serial Number"></ion-input>
          <ion-input class="ion-text-right" *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" formControlName="kiln_serial_number"></ion-input>
        </ion-item>

        <ion-item *ngIf="manageKilnForm.value.is_kmt">
          <ion-label>Controller Serial Number</ion-label>
          <ion-input *ngIf="canAdminister" type="text" class="ion-text-right" formControlName="serial_number" placeholder="Controller Serial Number (KMTs only)"></ion-input>
          <ion-input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" class="ion-text-right" formControlName="serial_number" ></ion-input>
        </ion-item>

        <ion-item *ngIf="manageKilnForm.value.is_kmt">
          <ion-label>Mac Address</ion-label>
          <ion-input *ngIf="canAdminister" type="text" class="ion-text-right" formControlName="mac_address" placeholder="Mac Address"></ion-input>
          <ion-input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="text" class="ion-text-right" formControlName="mac_address" ></ion-input>
        </ion-item>

        <ion-item *ngIf="!manageKilnForm.value.is_kmt">
          <ion-label>Attached to Network Device</ion-label>
          <!-- dev: {{manageKilnForm.network_device}}
          <p *ngFor="let nd of network_devices">
            is eq: {{nd.id === manageKilnForm.network_device}},
            mac: {{nd.id}}
          </p> -->
          <ion-select *ngIf="canAdminister" formControlName="network_device">
            <ion-select-option *ngFor="let nd of network_devices" value="{{nd && nd.id}}"
              [selected]="nd && nd.id === manageKilnForm.value.network_device">
              {{nd ? nd.name : 'Network Device Name Unavailable'}}
            </ion-select-option>
          </ion-select>
          <ion-select *ngIf="!canAdminister" formControlName="network_device">
            <ion-select-option *ngFor="let nd of network_devices"
              value="{{nd && nd.id}}" disabled="true"
              [selected]="nd && nd.id === manageKilnForm.value.network_device">
              {{nd ? nd.name : 'Network Device Name Unavailable'}}
            </ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item *ngIf="!manageKilnForm.value.is_kmt">
          <ion-label>Kiln ID</ion-label>
          <ion-input *ngIf="canAdminister" type="number" formControlName="kiln_id" placeholder="Kiln ID"></ion-input>
          <ion-input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="number" formControlName="kiln_id" min="1" ></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Date Installed</ion-label>
          <ion-input *ngIf="canAdminister" type="date" formControlName="install_date"></ion-input>
          <ion-input *ngIf="!canAdminister" disabled="true" readonly="true" [class.readonly]="true"
            type="date" formControlName="install_date"></ion-input>
        </ion-item>
      </form>
    </div>

    <div *ngIf="selected_tab === 'network' && network_devices && network_devices.length > 0 && hasElevatedPermissions">
      <ion-item class="title">
        <ion-label><h6>My Network Devices</h6></ion-label>
        <!-- <div> -->
          <!-- <ion-icon name="add" class="icon" color="light" (click)="addNetworkDevice()"></ion-icon> -->
          <!-- <ion-icon *ngIf="!gettingUserData" name="remove" class="icon" (click)="removeNetworkDevice()"></ion-icon> -->
        <!-- </div> -->
        <ion-button *ngIf="small_screen" (click)="showPicker()">Select</ion-button>
      </ion-item>      
      <ion-list *ngIf="network_devices && network_devices.length > 0 && !small_screen">
        <ion-item *ngFor="let nd of network_devices" (click)="selecteNetworkDevice(nd)"
           [class.selected]="selectedNetworkDevice ? (selectedNetworkDevice.serial_number === nd.serial_number) : false">
          {{ getNdName(nd) }}
        </ion-item>
      </ion-list>
    </div>
    <div *ngIf="selected_tab === 'network'" class="form">
      <h6>Edit Network Device Information</h6>
      <form [formGroup]="manageNetworkDeviceForm" *ngIf="selectedNetworkDevice" (ngSubmit)="submitNetworkDevice(manageNetworkDeviceForm.value)">
        <ion-item>
          <ion-label>Location / Friendly Name</ion-label>
          <ion-input type="text" class="ion-text-right" disabled="true" readonly="true" formControlName="name"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>MAC Address</ion-label>
          <ion-input type="text" class="ion-text-right" disabled="true" readonly="true" formControlName="mac_address"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Device Model</ion-label>
          <ion-select disabled="true" formControlName="device_manufacturer">
            <ion-select-option value="1">NetBurner RF1</ion-select-option>
            <ion-select-option value="2">NetBurner SF1</ion-select-option>
          </ion-select>
        </ion-item>

        <ion-item>
          <ion-label>Serial Number <sup>*</sup></ion-label>
          <ion-input type="text" class="ion-text-right" disabled="true" readonly="true" formControlName="serial_number"></ion-input>
        </ion-item>

        <ion-item class="vertical"><ion-note><sup>*</sup> The Serial Number will be found in the same general location as the Model Name and MAC address.</ion-note></ion-item>
      </form>
    </div>
  </div>

  <div class="buttons">
    <ion-button (click)="onDismiss ? onDismiss() : goBack()">Cancel</ion-button>
    <ion-button *ngIf="(selected_tab !== 'network') && !updating && new_kiln" (click)="submit()" type="submit">Create Kiln</ion-button>
    <ion-button *ngIf="(selected_tab !== 'network') && !updating && !new_kiln" (click)="submit()" type="submit">Submit</ion-button>
    <button *ngIf="updating"><ion-spinner name="bubbles"></ion-spinner></button>
  </div>
</div>
