import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { states } from '@ewarren/unitedstatesofamerica';

@Component({
  selector: 'app-account-settings-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class AccountSettingsCompanyComponent implements OnInit {
  @Input() accountInformation;
  @Output() pending = new EventEmitter<Object>();
  @Output() buttonText = new EventEmitter<String>();

  public companyForm: UntypedFormGroup;
  public statesMappings;

  constructor(public accountService: AccountService) {
    this.createForm();
    this.statesMappings = Object.keys(states).map((s) => { return {abbreviation: s, name: states[s] }; })
  }

  ngOnInit() {
    this.createForm();
    this.statesMappings = Object.keys(states).map((s) => { return {abbreviation: s, name: states[s] }; })
  }

  async successfulUpdate() {
    this.pending.emit({
      is_pending: false,
      message: 'Company successfully updated',
      success: true
    });
  }

  createForm() {
    if(!this.companyForm && this.accountInformation) {
      let company_info = this.accountInformation && this.accountInformation.company;
      let account_owner_info = this.accountInformation && this.accountInformation.account_owner;
      let address = company_info.address;

      let name = new UntypedFormControl(company_info ? company_info.name : '', [Validators.required]);
      let line_one = new UntypedFormControl(address ? address.line_one : '', [Validators.required]);
      let line_two = new UntypedFormControl(address ? address.line_two : '', []);
      let city = new UntypedFormControl(address ? address.city : '', [Validators.required]);
      let state = new UntypedFormControl(address ? address.state : '', [Validators.required]);
      let postal = new UntypedFormControl(address ? address.postal : '', [Validators.required]);
      let first_name = new UntypedFormControl(account_owner_info ? account_owner_info.name.first_name : '', [Validators.required]);
      let last_name = new UntypedFormControl(account_owner_info ? account_owner_info.name.last_name : '', [Validators.required]);

      this.companyForm = new UntypedFormGroup({
        name: name,
        weekly_summary: new UntypedFormControl(false),
        line_one: line_one,
        line_two: line_two,
        city: city,
        state: state,
        postal_code: postal,
        first_name: first_name,
        last_name: last_name
      });
    }
  }

  getStates() {
    return this.statesMappings;
  }

  public save() {
    let form_value = this.companyForm.value
    this.accountService.updateCompanyInfo(this.accountInformation.general.external_id, form_value.name, form_value.weekly_summary, {
      line_one: form_value.line_one,
      line_two: form_value.line_two,
      city: form_value.city,
      state: form_value.state,
      postal: form_value.postal_code
    }, {
      first_name: form_value.first_name,
      last_name: form_value.last_name
    })
        .then((data) => { this.successfulUpdate(); })
        .catch((error) => {
          this.pending.emit({
            is_pending: false,
            message: 'Sorry we could not update the company information at this time.',
            success: true
          });
        })
    this.pending.emit({is_pending: true});
  }

  public isValid() {
    return this.companyForm && this.companyForm.touched && this.companyForm.valid;
  }
}
