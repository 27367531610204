import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController, NavParams } from '@ionic/angular';
import { KilnService } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-users-menu',
  templateUrl: './users-menu.component.html',
  styleUrls: ['./users-menu.component.scss'],
})
export class UsersMenuComponent implements OnInit {
  @Input() hasElevatedPrivileges = false;
  @Input() onDismiss;

  constructor(public navCtrl: NavController,
    public popoverController: ModalController,
    public kilnService: KilnService,
    public userService: UserService,
    private route: ActivatedRoute,
    private navParams: NavParams
  ) {
    let params = navParams.data;
    this.hasElevatedPrivileges = params['hasElevatedPrivileges'];
    this.onDismiss = params['onDismiss'];
  }

  ngOnInit() {
    if(!this.hasElevatedPrivileges || !this.onDismiss) {
      let params = this.navParams.data;
      this.hasElevatedPrivileges = params['hasElevatedPrivileges'];
      this.onDismiss = params['onDismiss'];
    }
  }
}
