export const Options = {
  all: [
    {
      "kilnModelId": 1,
      "modelName": "Skutt KMT-1627-3PK",
      "pics": "KM1627.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 2,
      "modelName": "Skutt KMT-1231-3PK",
      "pics": "KM1231.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 3,
      "modelName": "Skutt KMT-1227-3PK",
      "pics": "KM1227.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 4,
      "modelName": "Skutt KMT-1227-3",
      "pics": "KM1227.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 5,
      "modelName": "Skutt KMT-822",
      "pics": "KM822.gif",
      "group":"kmt"
    },
    {
      "kilnModelId": 6,
      "modelName": "Skutt KMT-1022",
      "pics": "KM1022.gif",
      "group":"kmt"
    },
    {
      "kilnModelId": 7,
      "modelName": "Skutt KMT-1222-3",
      "pics": "KM1222.gif",
      "group":"kmt"
    },
    {
      "kilnModelId": 8,
      "modelName": "Skutt KMT-1027",
      "pics": "KM1027.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 9,
      "modelName": "Skutt KMT-1218-3",
      "pics": "KM1218.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 10,
      "modelName": "Skutt KMT-1027-3",
      "pics": "KM1027.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 11,
      "modelName": "Skutt KMT-1022-3",
      "pics": "KM1022.gif",
      "group":"kmt"
    },
    {
      "kilnModelId": 12,
      "modelName": "Skutt KMT-1018",
      "pics": "KM1018.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 13,
      "modelName": "Skutt KMT-822-3",
      "pics": "KM822.gif",
      "group":"kmt"
    },
    {
      "kilnModelId": 14,
      "modelName": "Skutt KMT-818",
      "pics": "KM818.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 15,
      "modelName": "Skutt KMT-818-3",
      "pics": "KM818.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 14,
      "modelName": "Skutt KMT-714",
      "pics": "KM714.jpg",
      "group":"kmt"
    },
    {
      "kilnModelId": 16,
      "modelName": "Skutt KMT-614-3",
      "pics": "KM614.jpg",
      "group":"kmt"
    },
    {
      "blank": true,
      "kilnModelId": -1,
      "modelName": "",
    },
    {
      "kilnModelId": 16,
      "modelName": "Skutt KM-1627-3PK",
      "pics": "KM1627.jpg"
    },
    {
      "kilnModelId": 17,
      "modelName": "Skutt KM-1231-3PK",
      "pics": "KM1231.jpg"
    },
    {
      "kilnModelId": 18,
      "modelName": "Skutt KM-1227-3PK",
      "pics": "KM1227.jpg"
    },
    {
      "kilnModelId": 2,
      "modelName": "Skutt KM-1227-3",
      "pics": "KM1227.jpg"
    },
    {
      "kilnModelId": 3,
      "modelName": "Skutt KM-822",
      "pics": "KM822.gif"
    },
    {
      "kilnModelId": 4,
      "modelName": "Skutt KM-1022",
      "pics": "KM1022.gif"
    },
    {
      "kilnModelId": 5,
      "modelName": "Skutt KM-1222-3",
      "pics": "KM1222.gif"
    },
    {
      "kilnModelId": 6,
      "modelName": "Skutt KM-1027",
      "pics": "KM1027.jpg"
    },
    {
      "kilnModelId": 7,
      "modelName": "Skutt KM-1218-3",
      "pics": "KM1218.jpg"
    },
    {
      "kilnModelId": 8,
      "modelName": "Skutt KM-1027-3",
      "pics": "KM1027.jpg"
    },
    {
      "kilnModelId": 9,
      "modelName": "Skutt KM-1022-3",
      "pics": "KM1022.gif"
    },
    {
      "kilnModelId": 10,
      "modelName": "Skutt KM-1018",
      "pics": "KM1018.jpg"
    },
    {
      "kilnModelId": 11,
      "modelName": "Skutt KM-822-3",
      "pics": "KM822.gif"
    },
    {
      "kilnModelId": 12,
      "modelName": "Skutt KM-818",
      "pics": "KM818.jpg"
    },
    {
      "kilnModelId": 13,
      "modelName": "Skutt KM-818-3",
      "pics": "KM818.jpg"
    },
    {
      "kilnModelId": 14,
      "modelName": "Skutt KM-714",
      "pics": "KM714.jpg"
    },
    {
      "kilnModelId": 15,
      "modelName": "Skutt KM-614-3",
      "pics": "KM614.jpg"
    },
    {
      "blank": true,
      "kilnModelId": -1,
      "modelName": "",
    },
    {
      "kilnModelId": 21,
      "modelName": "Skutt GMT-22CS",
      "pics": "GM22CS.gif"
    },
    {
      "kilnModelId": 22,
      "modelName": "Skutt GMT-10F",
      "pics": "GM10F.jpg"
    },
    {
      "kilnModelId": 24,
      "modelName": "Skutt GMT-814",
      "pics": "GM814.gif"
    },
    {
      "kilnModelId": 25,
      "modelName": "Skutt GMT-1014",
      "pics": "GM1014.gif"
    },
    {
      "kilnModelId": 26,
      "modelName": "Skutt GMT-1214-3",
      "pics": "GM1214.gif"
    },
    {
      "kilnModelId": 27,
      "modelName": "Skutt GMT-1414",
      "pics": "GM1414.gif"
    },
    {
      "kilnModelId": 28,
      "modelName": "Skutt GMT-818",
      "pics": "GM818.gif"
    },
    {
      "kilnModelId": 29,
      "modelName": "Skutt GMT-1018",
      "pics": "GM1018.gif"
    },
    {
      "kilnModelId": 30,
      "modelName": "Skutt GMT-1227",
      "pics": "GM1227.gif"
    },
    {
      "blank": true,
      "kilnModelId": -1,
      "modelName": "",
    },
    {
      "kilnModelId": 21,
      "modelName": "Skutt GM-22CS",
      "pics": "GM22CS.gif"
    },
    {
      "kilnModelId": 22,
      "modelName": "Skutt GM-10F",
      "pics": "GM10F.jpg"
    },
    {
      "kilnModelId": 24,
      "modelName": "Skutt GM-814",
      "pics": "GM814.gif"
    },
    {
      "kilnModelId": 25,
      "modelName": "Skutt GM-1014",
      "pics": "GM1014.gif"
    },
    {
      "kilnModelId": 26,
      "modelName": "Skutt GM-1214-3",
      "pics": "GM1214.gif"
    },
    {
      "kilnModelId": 27,
      "modelName": "Skutt GM-1414",
      "pics": "GM1414.gif"
    },
    {
      "kilnModelId": 28,
      "modelName": "Skutt GM-818",
      "pics": "GM818.gif"
    },
    {
      "kilnModelId": 29,
      "modelName": "Skutt GM-1018",
      "pics": "GM1018.gif"
    },
    {
      "kilnModelId": 30,
      "modelName": "Skutt GM-1227",
      "pics": "GM1227.gif"
    },
    {
      "kilnModelId": 1,
      "modelName": "Other",
      "pics": "other.png"
    }
  ]
}
