import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { NavController,
    AlertController
  } from '@ionic/angular';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  public error;
  public email;

  constructor(
    private alertController: AlertController,
    private userService: UserService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.error = null;
    this.userService.getStoredEmail();
    this.userService.storageUpdate.subscribe((data) => {
      if(data && data['email']) { this.email = (data['email']) }
    })
  }

  trialAvailable() {
    return environment.freeToPremium && !this.userService.accountExpired();
  }

  startTrial() {
    this.navCtrl.navigateForward('start-trial');
  }

  async deleteAccount() {
    let alert = await this.alertController.create({
      header: "Deactivate Confirmation",
      message: `Please confirm you want to deactivate your KilnLink user. <b>This action is not reversible.</b>`,
      buttons: [
        {
          text: 'Cancel',
          cssClass: 'primary',
          role: 'cancel'
        }, {
          text: "Deactivate",
          role: 'delete',
          cssClass: 'secondary',
          handler: (data) => {
            this.userService.deleteUser()
                .then((data) => { this.navCtrl.navigateForward('home'); })
                .catch(() => { this.error = "There was an error deleting your account. Please contact support for assistance."; })
          }
        }
      ]
    });
    alert.present();
  }
}
