<ul>
  <li *ngIf="!is_logged_in_as" (click)="showModal('change-password')">
    <ion-icon name="lock-closed-outline"></ion-icon>
    <p>Change Password</p>
  </li>
  <li *ngIf="!is_on_admin_pages && !is_logged_in_as" (click)="showModal('switch-account')">
    <ion-icon name="swap-horizontal-outline"></ion-icon>
    <p>Switch Accounts</p>
  </li>
  <li *ngIf="is_premium && !is_on_admin_pages || accountExpired" (click)="showModal('update-account-info')">
    <ion-icon name="person"></ion-icon>
    <p [class.viewing-as-free]="viewingKilnlinkFreeKilns">Update Account Info</p>
  </li>
  <li *ngIf="is_premium && !is_on_admin_pages && !readonlyUser && !accountExpired"  (click)="showModal('manage-network')">
    <ion-icon name="cog-outline"></ion-icon>
    <p [class.viewing-as-free]="viewingKilnlinkFreeKilns">Manage Network</p>
  </li>
  <li *ngIf="is_premium && !is_on_admin_pages && !readonlyUser && !accountExpired" (click)="showModal('alert-settings')">
    <ion-icon name="alert"></ion-icon>
    <p [class.viewing-as-free]="viewingKilnlinkFreeKilns">Alert Settings</p>
  </li>
  <li *ngIf="!is_premium || viewingKilnlinkFreeKilns && !is_logged_in_as && !is_on_admin_pages && !accountExpired" (click)="addKilnClick()">
    <ion-icon name="add" class="icon"></ion-icon>
    <p>Add Controller</p>
  </li>
  <li *ngIf="hasElevatedPermissions && !is_on_admin_pages"
    class="elevated-permissions"
    (click)="showModal('login-as')">
    <ion-icon name="people"></ion-icon>
    <p>Login As</p>
  </li>
  <li *ngIf="is_logged_in_as && hasElevatedPermissions && !is_on_admin_pages"
    class="elevated-permissions"
    (click)="exitLoginAs()">
    <ion-icon name="people"></ion-icon>
    <p>Exit Login As</p>
  </li>
  <li *ngIf="!is_logged_in_as && hasElevatedPermissions && !is_on_admin_pages"
    class="elevated-permissions"
    (click)="showModal('admin-account-list', true)">
    <ion-icon name="business"></ion-icon>
    <p>Admin Portal</p>
  </li>
  <li *ngIf="!is_logged_in_as && is_on_admin_pages" class="elevated-permissions"
    (click)="showModal('kiln-list', true)">
    <ion-icon name="list"></ion-icon>
    <p>Exit Admin Portal</p>
  </li>
  <!-- <li *ngIf="is_premium" (click)="showModal('account-invites')">
    <ion-icon name="mail-unread"></ion-icon>
    <p>Account Invites</p>
  </li> -->
  <li *ngIf="!is_logged_in_as && is_premium && hasElevatedPermissions" (click)="registerControllers()" class="elevated-permissions">
    <ion-icon name="cloud-upload" class="icon"></ion-icon>
    <p>Register KMT Controller</p>
  </li>
  <li *ngIf="!is_logged_in_as && hasElevatedPermissions" (click)="goToKilnSearch()" class="elevated-permissions">
    <ion-icon name="search" class="icon"></ion-icon>
    <p>Search KMT Controller(s)</p>
  </li>
  <li *ngIf="!is_logged_in_as && hasElevatedPermissions && !smallScreen" (click)="togglePremium()" class="elevated-permissions">
    <ion-icon *ngIf="!is_premium" class="icon" name="flask"></ion-icon>
    <ion-icon *ngIf="is_premium" class="icon" name="phone-portrait"></ion-icon>
    <p>Toggle to KilnLink {{is_premium ? 'Free' : 'Premium'}}</p>
  </li>
  <!-- <li *ngIf="!is_logged_in_as && is_premium && hasElevatedPermissions" class="elevated-permissions">
    <ion-icon name="key"></ion-icon>
    <p><a href="http://skutt-kilnlink-admin.s3-website-us-west-2.amazonaws.com/home">KilnLink Free Admin</a></p>
  </li> -->
  <li (click)="logOut()">
    <ion-icon name="log-out"></ion-icon>
    <p>Logout</p>
  </li>
</ul>
