import { Component, OnInit, Input } from '@angular/core';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';
import { KilnService } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators, FormControl } from '@angular/forms';
import { ToastController, AlertController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-alert-settings-popup',
  templateUrl: './alert-settings-popup.page.html',
  styleUrls: ['./alert-settings-popup.page.scss'],
})
export class AlertSettingsPopupPage implements OnInit {
  @Input() onDismiss;

  public selectedKiln = null;
  public selectedAlert = null;
  public kilns = [];
  public alertSettings = [];
  public selectedAlertSettings;
  public alerts = [
    {name: "Kiln back online", type: 'back-online'},
    {name: "Firing has started", type: 'firing-started'},
    {name: "Successful firing", type: 'successful-firing'},
    {name: "Failed firing", type: 'failed-firing'},
    {name: "Kiln has heated to [n] degrees", type: 'heated-to'},
    {name: "Kiln has cooled to [n] degrees", type: 'cooled-to'},
    {name: "Ready to unload", type: 'unload-ready'},
    {name: "Receiving bad data", type: 'bad-data'},
    {name: "MySkutt network device offline", type: 'network-device-offline'},
    {name: "No messages received from kiln", type: 'no-messages'},
    {name: "Network device back online", type: 'network-device-online'}
  ];

  public alertSettingsForm: UntypedFormGroup;
  public gettingUsers;
  private arrayItems = [];
  public savingSettings;
  private accountInformation;
  public canAdminister;
  private loginData;
  private kiln_id;

  constructor(public accountService: AccountService,
              public alertService: AlertService,
              public userService: UserService,
              public kilnService: KilnService,
              public toastController: ToastController,
              public alertController: AlertController,
              public router: Router,
              private route: ActivatedRoute
            ) {
    let login_data = this.userService.getLoginData();
    this.loginData = (login_data.hasOwnProperty('loggedInAs') && login_data.loggedInAs) ? login_data.loggedInAs : login_data;
    // this.loginData = userService.getLoginData();
    accountService.getAccountInformation().then( (data) => {
      this.accountInformation = data;
      this.setUpCanAdminister(data);
    });

    this.route.params.subscribe(params => {
      console.log(`params - ${params['kiln_id']}`)
      this.kiln_id = params["kiln_id"];
    })
  }

  ngOnInit() {
    this.accountService.getDevicesForAccount()
        .then((data) => {
          if(!this.selectedKiln) {
            this.kilns = data.kilns.sort((a, b) => (a.name > b.name) ? 1 : -1);

            let id = this.kiln_id || this.kilns[0].serial_number || `${this.kilns[0].mac_address}${this.kilns[0].kiln_id}`;            
            this.selectedKiln = this.kilns.find((k) => { return (k.serial_number === id) || (`${k.network_device_id}${k.kiln_id}`) === id; });            
            this.selectKiln(id, this.selectedKiln.mac_address, this.selectedKiln.kiln_id)            
            this.alertSettingsForm.get('selectedKiln').setValue(this.selectedKiln || this.kilns[0]);            
          }          
        })

    let alertEnabled = new UntypedFormControl(false, [Validators.required]);
    let enabled_users = new UntypedFormArray([]);
    this.alertSettingsForm = new UntypedFormGroup({
      selectedKiln: new FormControl(),
      selectedAlert: new FormControl('back-online'),
      alertEnabled: alertEnabled,
      enabled_users: enabled_users,
      temp_variable: new UntypedFormControl(null)
    });
    this.savingSettings = false;
  }

  async showMessage(message, success) {
    this.savingSettings = false;
    let toast = await this.toastController.create({
      message: message,
      duration: 3000,
      position: 'middle'
    });

    toast.present();
  }

  checkboxChange(val, index) {
    let user = this.enabledUsers().controls[index];
    if(val.target.value || (val.target.value && val.target.value === "on")) {
      this.arrayItems.push(user);
      user.patchValue({checked: true});
    } else {
      this.arrayItems.pop();
      user.patchValue({checked: false});
    }
  }

  enabledUsers() {
    let formArr = (this.alertSettingsForm.get("enabled_users") as UntypedFormArray);
    return formArr;
  }

  userHasEmail(index) {
    let userControls = (this.enabledUsers().value[index] as UntypedFormGroup);
    return userControls && userControls["email_label"];
  }

  emailCanAlert(index) {
    let userControls = (this.enabledUsers().value[index] as UntypedFormGroup);
    return userControls && userControls["can_be_checked"];
  }

  userPhoneControls(index) {
    let phoneFormArray = (this.enabledUsers().controls[index].get("phones") as UntypedFormArray);
    return phoneFormArray.controls;
  }

  userPhoneControlsAt(index) {
    let phoneFormArray = (this.enabledUsers().controls[index].get("phones") as UntypedFormArray);
    return phoneFormArray.controls[index];
  }

  async showPopupIfFormUnsaved(callback) {
    callback();   
  }

  goBack() {
    this.router.navigate(["/kiln-list"]);
  }

  selectKilnFromDropdown(kiln) {
    let id = this.kiln_id || this.selectedKiln.serial_number || `${this.selectedKiln.mac_address}${this.selectedKiln.kiln_id}`;            
    this.selectKiln(id, kiln.mac_address, kiln.kiln_id);
  }

  selectKiln(id, kiln_id, mac_address) {
    // kiln = this.alertSettingsForm.value.selectedKiln;
    console.log(`selecting kiln - ${id}`)
    let actuallySelect = () => {      
      this.kilnService.getUsersForKiln(id, mac_address, kiln_id)
          .then((data) => {
            this.gettingUsers = false;
            if(this.selectedKiln) {
              this.selectedKiln.users = data;
              this.alertSettingsForm.get('selectedAlert').setValue('back-online');
              this.selectAlert();
            }
          })
          .catch(() => { this.gettingUsers = false; })

      this.gettingUsers = true;
      this.alertSettings = [];
      this.alertService.getAlertSettingsForKiln(id)
          .then((data) => {
            this.alertSettings = data;
            this.alertSettingsForm.get('selectedAlert').setValue('back-online');
            this.selectAlert();
          })
    }

    // if(this.alertSettingsForm.dirty) {
    //   this.showPopupIfFormUnsaved(actuallySelect);
    // } else {
      actuallySelect();
    // }
  }

  saveAlertSettings(formValue) {
    if(!this.savingSettings) {
      this.savingSettings = true;
      let users = formValue.enabled_users && formValue.enabled_users.reduce((acc, user) => {
        if(user.email_label) {
          let email_lbl = user.email_label.replace("*", '')
          if(user.email_checked) {
            acc.enabled.push(email_lbl)
          } else {
            acc.disabled.push(email_lbl)
          }
        }

        if(user.phones && user.phones.length > 0) {
          user.phones.forEach((p) => {
            if(p.label) {
              let label = p.label.replace("*", '');
              if(p.checked) {
                acc.enabled.push(label); }
              else {
                acc.disabled.push(label);
              }
            }
          })
        }

        return acc;
      }, {enabled: [], disabled: []})
      let data = {
        enable: formValue.alertEnabled,
        enabled_users: users.enabled,
        disabled_users: users.disabled,
        type: this.selectedAlert.type
      };

      if(formValue.temp_variable) {
        data["temp_variable"] = formValue.temp_variable
      }

      // console.log(data);
      if(this.canAdminister && this.selectedKiln.serial_number) {
        // console.log(`updating settings for ${this.selectedKiln.serial_number}`)
        this.alertService.updateAlertSettings(this.selectedKiln.serial_number || `${this.selectedKiln.mac_address}${this.selectedKiln.kiln_id}`, data)
            .then((response) => {
              this.alertSettings = response;
              this.alertSettingsForm.markAsPristine();
              this.alertSettingsForm.markAsUntouched();
              this.showMessage("Alert setting was updated successfully", true);
            })
            .catch((error) => { this.showMessage("Alert setting was not updated successfully", false); })
      } else {
        // console.log(`updating settings for ${this.selectedKiln.serial_number}`)
        this.alertService.updateSingleUserAlertSettings(this.selectedKiln.serial_number || `${this.selectedKiln.mac_address}${this.selectedKiln.kiln_id}`, data)
            .then((response) => {
              this.selectedAlertSettings = response;
              let alert_setting_index = this.alertSettings.findIndex((setting) => setting.type === response.type)
              this.alertSettings.splice(alert_setting_index, 1, response);
              this.showMessage("Alert setting was updated successfully", true);
            })
            .catch((error) => { this.showMessage("Alert setting was not updated successfully", false); })
      }
    } else {
      this.showMessage("An update to this alert's settings is already underway. Please wait till it is complete.", false);
    }
  }

  showTempVariable() {
    return this.selectedAlert ? ["heated-to", "cooled-to"].includes(this.selectedAlert.type) : false;
  }

  selectAlert() {
    let type = this.alertSettingsForm.value.selectedAlert;
    let actuallySelectAlert = () => {
      this.selectedAlert = this.alerts.find((a) => { return a.type === type; });
      this.selectedAlertSettings = this.alertSettings.find((setting) => {
        return setting.type === type;
      }) || this.defaultAlertSettings(type);

      let alertEnabled = new UntypedFormControl(this.selectedAlertSettings.enabled, [Validators.required]);
      let all_enabled = this.selectedAlertSettings.enabled_phones.concat(this.selectedAlertSettings.enabled_emails);
      let enabled = (val) => {
        return all_enabled.includes(val);
      }

      if(this.canAdminister) {
        let enabled_users_groups = [];
        if(this.selectedKiln && this.selectedKiln.users) {
          // should add email, but also phone options
          let createPhoneArray = (u) => {
            if(u && u.phones && u.phones.length > 0 && u.phones[0] !== null) {
              return new UntypedFormArray(u.phones.map((p) => {
                let enabled_and_verified = (p.enabled && p.verified);
                return new UntypedFormGroup({
                  checked: new UntypedFormControl(enabled(p.call_number)),
                  label: new UntypedFormControl(`${p.call_number}${enabled_and_verified ? '' : '*'}`),
                  can_be_checked: new UntypedFormControl(enabled_and_verified),
                })
              }))
            } else {
              return new UntypedFormArray([])
            }
          }

          enabled_users_groups = this.selectedKiln.users.map((u) => {
            let enabled_and_verified = (u.email_enabled && u.email_verified);
            let has_first_last_name = u.first_name || u.last_name;
            return new UntypedFormGroup({
              username: new UntypedFormControl(u.email || (has_first_last_name ? `${u.first_name} ${u.last_name}` : u.username)),
              email_checked: new UntypedFormControl(u.email ? enabled(u.email) : false),
              email_label: u.email ? new UntypedFormControl(`${u.email}${enabled_and_verified ? '': '*'}`) : new UntypedFormControl(null),
              can_be_checked: u.email ? new UntypedFormControl(enabled_and_verified) : new UntypedFormControl(false),
              phones: createPhoneArray(u)
            });
          })
        }

        let enabled_users = new UntypedFormArray(enabled_users_groups);

        let tempVariableControl = () => {
          if(this.showTempVariable()) {
            return new UntypedFormControl(this.selectedAlertSettings ? this.selectedAlertSettings.temp_variable : "", [Validators.required])
          } else { return new UntypedFormControl(null) }
        }

        this.alertSettingsForm = new UntypedFormGroup({
          selectedKiln: this.alertSettingsForm.controls.selectedKiln,
          selectedAlert: this.alertSettingsForm.controls.selectedAlert,
          alertEnabled: alertEnabled,
          enabled_users: enabled_users,
          temp_variable: tempVariableControl()
        });
      } else {
        let enabled_users_groups = [];
        if(this.selectedKiln && this.selectedKiln.users) {
          // should add email, but also phone options
          let createPhoneArray = (u) => {
            if(u && u.phones && u.phones.length > 0 && u.phones[0] !== null) {
              return new UntypedFormArray(u.phones.map((p) => {
                let enabled_and_verified = (p.enabled && p.verified);
                return new UntypedFormGroup({
                  checked: new UntypedFormControl(enabled(p.call_number)),
                  label: new UntypedFormControl(`${p.call_number}${enabled_and_verified ? '' : '*'}`),
                  can_be_checked: new UntypedFormControl(enabled_and_verified),
                })
              }))
            } else {
              return new UntypedFormArray([])
            }
          }
          enabled_users_groups = this.selectedKiln.users.filter((u) => u.email === this.loginData.email).map((u) => {
            let enabled_and_verified = (u.email_enabled && u.email_verified);
            return new UntypedFormGroup({
              username: new UntypedFormControl(u.username),
              email_checked: new UntypedFormControl(enabled(u.email)),
              email_label: u.email ? new UntypedFormControl(`${u.email}${enabled_and_verified ? '': '*'}`) : new UntypedFormControl(null),
              can_be_checked: new UntypedFormControl(enabled_and_verified),
              phones: createPhoneArray(u)
            });
          })
        }

        let enabled_users = new UntypedFormArray(enabled_users_groups);
        
        this.alertSettingsForm = new UntypedFormGroup({
          selectedKiln: this.alertSettingsForm.controls.selectedKiln,
          selectedAlert: this.alertSettingsForm.controls.selectedAlert,
          alertEnabled: alertEnabled,
          enabled_users: enabled_users,
          temp_variable: new UntypedFormControl(this.selectedAlertSettings ? this.selectedAlertSettings.temp_variable : "")
        });
      }
    }

    if(this.alertSettingsForm.dirty) {
      this.showPopupIfFormUnsaved(actuallySelectAlert);
    } else {
      actuallySelectAlert();
    }
  }

  defaultAlertSettings(type) {
    return {
      type: type,
      enabled_emails: [],
      enabled_phones: [],
      enabled: false,
      temp_variable: null
    }
  }

  setUpCanAdminister(data) {
    if(this.accountInformation && this.accountInformation.users && this.loginData) {
      let loggedInUserPermissions = this.accountInformation.users.find((u) => {
        return u.email === this.loginData.email
      })
      this.canAdminister = loggedInUserPermissions ? (loggedInUserPermissions.type === 'admin') : false;
    }
  }

  testAlert(mac, serial, type) {
    this.alertService.sendTestAlert(mac, serial, type)
        .then(function(response) {this.showMessage(response.message, false)})
        .catch(function() { this.showMessage("The test message could not be sent", false); })
  }
}
