import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import {BehaviorSubject} from "rxjs";

@Injectable({ providedIn: 'root' })
export class AccountService {
  public current_account_id;
  public elasticbeanstalk_uri = environment.claimApi;
  public local_uri = environment.claimApi;
  public accountInformation;
  public loginAsStatus = new BehaviorSubject<boolean>(false);

  constructor(
    public http: HttpClient,
    public userService: UserService) {
      this.local_uri = this.elasticbeanstalk_uri;
      this.current_account_id = undefined;
  }

  public getCurrentAccount() {
    return this.current_account_id ? this.current_account_id : this.userService.getLoginData() && this.userService.getLoginData().account;    
  }

  public getAccountInformation(): Promise<any>{
    var accountUrl = `${this.local_uri}/account/${this.getCurrentAccount()}`;
    let getAccountPromise = this.http.get(accountUrl, this.httpOptions())
      .toPromise();

    getAccountPromise
      .then(response => {
        this.accountInformation = response;
        if(this.accountInformation && this.accountInformation.general) {
          // console.log(`switch current account from ${this.current_account_id} to ${this.accountInformation.general.external_id}`)
          this.current_account_id = this.accountInformation.general.external_id;
        }
      });

    return getAccountPromise;
  }

  public getAllAccounts(): Promise<any>{
    let is_logged_in_as = this.userService.isLoggedInAsAnotherUser();
    let loginInfo = this.userService.getLoginData().loggedInAs;
    var accountUrl = `${this.local_uri}/accounts${(is_logged_in_as && loginInfo && (loginInfo.email || loginInfo.username)) ? `?loginAs=${loginInfo.email || loginInfo.username}` : ''}`;
    let getAllAccountsPromise = this.http.get(accountUrl, this.httpOptions())
      .toPromise()

    getAllAccountsPromise
    .then(response => {
      this.accountInformation = response;
    });

    return getAllAccountsPromise;
  }

  public setDefault(account_id): Promise<any>{
    var accountUrl = `${this.local_uri}/accounts`;
    let setDefaultPromise = this.http.post(accountUrl, {default: account_id}, this.httpOptions())
      .toPromise()

    setDefaultPromise
      .then(response => {
        this.accountInformation = response;
        this.view(account_id);
      })

    return setDefaultPromise;
  }

  public view(account_id): Promise<any> {
    this.setCurrent(account_id);
    return this.userService.setCurrentAccount(account_id);
  }

  public unsetLoginAs() {
    this.current_account_id = undefined;
    this.loginAsStatus.next(false);
  }

  public setCurrent(account_id) {
    console.log(`switch current account from ${this.current_account_id} to ${account_id}`)
    this.current_account_id = account_id;
  }

  public listAccountInformation(): Promise<any>{
    var accountUrl = `${this.local_uri}/admin/account/list`;
    let accountPromise = this.http.get(accountUrl, this.httpOptions())
      .toPromise()

    accountPromise
      .then(response => {
        this.accountInformation = response;
      })
    return accountPromise;
  }

  public listDeviceInformation(): Promise<any>{
    var accountUrl = `${this.local_uri}/admin/device/list`;
    let devicePromise = this.http.get(accountUrl, this.httpOptions())
      .toPromise()

    devicePromise
      .then(response => {
        this.accountInformation = response;
      })
    return devicePromise;
  }

  public listPremiumUserInformation(): Promise<any>{
    var accountUrl = `${this.local_uri}/admin/users/list`;
    return this.http.get(accountUrl, this.httpOptions())
      .toPromise()
  }

  public localCacheAccountInfo() {
    return this.accountInformation;
  }

  public getDevicesForAccount(): Promise<any>{
    var accountUrl = `${this.local_uri}/account/${this.getCurrentAccount()}/devices`;
    return this.http.get(accountUrl, this.httpOptions())
      .toPromise()
  }

  public upsertNetworkDeviceForAccount(serial_number, data, account_id?): Promise<any>{
    var accountUrl = `${this.local_uri}/account/${account_id || this.getCurrentAccount()}/devices/${serial_number}`;
    return this.http.post(accountUrl, data, this.httpOptions())
      .toPromise()
  }

  public upsertKilnForAccount(serial_number, data): Promise<any>{
    let is_upsert = (data.external_id || serial_number)
    var accountUrl = is_upsert ? `${this.local_uri}/account/${this.getCurrentAccount()}/devices/${is_upsert}` : `${this.local_uri}/account/${this.getCurrentAccount()}/devices`;
    if(data.external_id) {
      delete data.external_id;
    }

    Object.keys(data).forEach((key) => {
      if(!data[key] || data[key] && data[key] === "") {
        data.key = undefined;
      }
    })

    if(is_upsert) {
      return this.http.put(accountUrl, data, this.httpOptions())
        .toPromise()
        // .then(response => { this.events.publish('upsertAccountKiln:success', response) })
        // .catch(error => {this.events.publish('upsertAccountKiln:error', error)});
    } else {
      return this.http.post(accountUrl, data, this.httpOptions())
        .toPromise()
        // .then(response => { this.events.publish('upsertAccountKiln:success', response) })
        // .catch(error => {this.events.publish('upsertAccountKiln:error', error)});
    }
  }

  public getUsersForAccount(): Promise<any>{
    var accountUrl = `${this.local_uri}/account/${this.getCurrentAccount()}/users`;
    return this.http.get(accountUrl, this.httpOptions())
      .toPromise()
  }

  public updateGeneral(account_id, timezone, type): Promise<any>{
    let body = {
      company: {
        timezone: timezone,
        type: type || 'Company'
      }
    };

    return this.update(account_id, body, 'General');
  }

  public updateCompanyInfo(account_id, name, weekly_summary, address, owner_name): Promise<any>{
    let body = {
      company: {
        name: name,
        weekly_summary: weekly_summary,
        address: address
      },
      account_owner: {
        owner: {first: owner_name.first_name, last: owner_name.last_name}
      }
    }

    return this.update(account_id, body, 'Company');
  }

  public updateOwnerInfo(account_id, address, owner_name): Promise<any>{
    let body = {
      account_owner: {
        owner: {first: owner_name.first_name, last: owner_name.last_name},
        address: address
      }
    };

    return this.update(account_id, body, 'Owner');
  }

  update(id, body, component): Promise<any>{
    var accountUrl = `${this.local_uri}/account/${id}`;
    return this.http.put(accountUrl, body, this.httpOptions()).toPromise()
  }

  create(body): Promise<any> {
    var accountUrl = `${this.local_uri}/account`;
    return this.http.post(accountUrl, body, this.httpOptions())
      .toPromise()
  }

  createByOwner(body): Promise<any> {
    return this.create(body)    
  }

  createNetworkDevice(account_id, body): Promise<any> { //should return promise
    var accountUrl = `${this.local_uri}/account/${account_id}/devices`;
    return this.http.post(accountUrl, body, this.httpOptions())
      .toPromise()
  }

  unlinkNetworkDevice(account_id, body): Promise<any> { //should return promise
    var accountUrl = `${this.local_uri}/account/${account_id}/devices/unlink`;
    return this.http.put(accountUrl, body, this.httpOptions())
      .toPromise()
  }

  delete(account): Promise<any> { //should return promise
    var accountUrl = `${this.local_uri}/account/${account.id}`;
    return this.http.delete(accountUrl, this.httpOptions())
      .toPromise()
  }

  httpOptions() {
    if(this.userService && this.userService.getLoginData()) {
      return {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': this.userService.getLoginData().token
        })
      }
    } else {
      return {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      }
    }

  }
}
