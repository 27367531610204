<div id="firing-history-main">
  <div id="firings-list">
    <h6 *ngIf="!small_screen">Choose A Firing</h6>
    <ion-progress-bar *ngIf="(retrieving && !selected_firing) || retrieving_more" type="indeterminate"></ion-progress-bar>
     <ion-list *ngIf="!viewingKilnlinkFreeKilns && (firing_history && firing_history.length > 0) && !small_screen" id="firing-history-list">
       <ion-item *ngFor="let firing of firing_history" class="firing ion-text-wrap"
        (click)="selectFiring(firing.id)"
        [class.selected]="selected_firing === firing.id"
        [color]="selected_firing === firing.id ? 'tertiary' : 'light'"
      >
        <label>{{firing.started | date:'mediumDate' }}</label><p class="program-name ion-text-wrap" *ngIf="firing.name">({{firing.name}})</p>
       </ion-item>
       <ion-item class="load-more" color="primary" *ngIf="hasMore && !retrieving_more" (click)="getMoreFiringHistory()">Load More </ion-item>
       <ion-item class="loading-more" *ngIf="retrieving_more">Retrieving More Firings </ion-item>
     </ion-list>
     <ion-list *ngIf="viewingKilnlinkFreeKilns" id="firing-history-list">
       <ion-item class="firing">
        Firing history is not available for KilnLink Free kilns.
       </ion-item>
     </ion-list>
    <ion-item *ngIf="small_screen" class="ion-text-wrap">
      <ion-label class="program-name" *ngIf="details">{{details.createdAt | date:'mediumDate'}} - {{details && details.program ? details.program.name : 'Name N/A'}}</ion-label>
      <ion-button (click)="showPicker()" *ngIf="!viewingKilnlinkFreeKilns && (firing_history && firing_history.length > 0) && small_screen">Choose</ion-button>
    </ion-item>
    <div *ngIf="!retrieving && !viewingKilnlinkFreeKilns && !error && (firing_history && firing_history.length == 0)">There is no firing history to show.</div>
  </div>

  <div id="historical-firing-details" *ngIf="viewingKilnlinkFreeKilns">
    <!--  <ion-list class="tabs" *ngIf="!small_screen">
       <ion-item (click)="subtab = 'program'" [class.current]="subtab == 'program'">Program </ion-item>
       <ion-item (click)="getFiringStatuses('results-graph')" [class.current]="subtab == 'results-graph'">Results Graph </ion-item>
       <ion-item (click)="getFiringStatuses('firing-data')" [class.current]="subtab == 'firing-data'">Firing Data </ion-item>
       <ion-item (click)="subtab = 'firing-notes'" [class.current]="subtab == 'firing-notes'">My Notes </ion-item>
     </ion-list> -->
    <ion-segment (ionChange)="subTabChange($event)" value="program">
      <ion-segment-button value="program">
        <ion-label>
          Program
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="results-graph">
        <ion-label>
          Graph
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="firing-data">
        <ion-label>
          Data
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="firing-notes">
        <ion-label>
          Notes
        </ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="tab-body">
      <p>You are viewing a KilnLink Free kiln. Upgrade to Premium today in order to save detailed firing history.</p>
    </div>
  </div>
  <div id="historical-firing-details" *ngIf="!viewingKilnlinkFreeKilns">
    <!-- <h6>Program Settings & End Results</h6> -->
    <!--  <ion-list class="tabs" *ngIf="!small_screen">
       <ion-item (click)="subtab = 'program'" [class.current]="subtab == 'program'">Program </ion-item>
       <ion-item (click)="subtab = 'results-graph'" [class.current]="subtab == 'results-graph'">Results Graph </ion-item>
       <ion-item (click)="subtab = 'firing-data'" [class.current]="subtab == 'firing-data'">Firing Data </ion-item>
       <ion-item (click)="subtab = 'firing-notes'" [class.current]="subtab == 'firing-notes'">My Notes </ion-item>
     </ion-list> -->
    <ion-segment (ionChange)="subTabChange($event)" value="program">
      <ion-segment-button value="program">
        <ion-label>
          Program
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="results-graph">
        <ion-label>
          Graph
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="firing-data">
        <ion-label>
          Data
        </ion-label>
      </ion-segment-button>
      <ion-segment-button value="firing-notes">
        <ion-label>
          Notes
        </ion-label>
      </ion-segment-button>
    </ion-segment>

    <div class="retreiving-firing-history" *ngIf="retrieving"><ion-spinner name="bubbles"></ion-spinner></div>
    <div class="error-firing-history" *ngIf="!retrieving && !error && (firing_history && firing_history.length === 0)">There is no available firing history to show</div>
    <p class="full-width error-firing-history" *ngIf="error">Sorry there was an error retreiving the firing history for this kiln. Please try again in a minute.</p>
    <div *ngIf="subtab == 'program' && !retrieving" class="tab-body">
      <ion-card class="header" *ngIf="details && details.program && details.config && !small_screen">        
        <ion-card-header>
          <ion-card-title>{{details.program.name || '-'}}</ion-card-title>          
          <ion-card-subtitle *ngIf="hasEnded(details)">{{ details.createdAt | date:'short' }} - {{ details.updatedAt | date:'short' }}</ion-card-subtitle>
          <ion-card-subtitle *ngIf="!hasEnded(details)">{{ details.createdAt | date:'short' }} - STILL FIRING</ion-card-subtitle>
        </ion-card-header>
      </ion-card>
      <ion-card  *ngIf="details && details.program && details.config">        
        <ion-card-header>
          <ion-card-subtitle>Details</ion-card-subtitle>          
        </ion-card-header>
        <ion-card-content>
          <ion-grid>
            <ion-row *ngIf="small_screen"><ion-col>Start Date/Time:</ion-col><ion-col>{{ details.createdAt | date:'short' }}</ion-col></ion-row>
            <ion-row *ngIf="small_screen">
              <ion-col>End Date/Time:</ion-col>
              <ion-col *ngIf="hasEnded(details)">{{ details.updatedAt | date:'short' }}</ion-col>
              <ion-col *ngIf="!hasEnded(details)">----</ion-col>
            </ion-row>            
            <ion-row *ngIf="small_screen"><ion-col>Program:</ion-col><ion-col>{{details.program && details.program.name || 'N/A'}}</ion-col></ion-row>
            <ion-row><ion-col>Elapsed Firing Time:</ion-col><ion-col>{{ getElapsedTime(details) }}</ion-col></ion-row>
            <ion-row><ion-col>Cone:</ion-col><ion-col>{{details.program.cone}}</ion-col></ion-row>
            <ion-row><ion-col>Firing Speed:</ion-col><ion-col>{{details.program.speed}}</ion-col></ion-row>
            <!-- <ion-row><ion-col>Delay:</ion-col><ion-col>TBD</ion-col></ion-row> -->
            <ion-row><ion-col>Alarm:</ion-col><ion-col>{{details.program.alarm_t}}</ion-col></ion-row>
            <ion-row><ion-col>Error Codes:</ion-col><ion-col>{{details.config.err_codes}}</ion-col></ion-row>
            <ion-row>
              <ion-col>Final Status:</ion-col>
              <ion-col *ngIf="hasEnded(details)">{{details.final_status || '-'}}</ion-col>
              <ion-col *ngIf="!hasEnded(details)">---</ion-col>
            </ion-row>
            <ion-row>
              <ion-col>Max Recorded Temperature:</ion-col>
              <ion-col>{{details.program.max_temp}}</ion-col>
            </ion-row>
            <ion-row><ion-col>Cost:</ion-col>
              <ion-col *ngIf="details.cost">{{details.cost | currency:'USD':true:'1.2-2' }}</ion-col>
              <ion-col *ngIf="!details.cost">$0.00</ion-col>
            </ion-row>
            <ion-row><ion-col>Firing Counter:</ion-col><ion-col>{{ details.statuses && details.statuses.length > 0 ? details.statuses[0].num_fire : 'N/A' }}</ion-col></ion-row>
          </ion-grid>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-header>
        <ion-card-subtitle>Program</ion-card-subtitle>        
      </ion-card-header>
      <ion-card-content>
        <ion-grid *ngIf="details && details.program && details.program.steps && details.program.steps.length > 0">
            <ion-row>
              <ion-col>Seg.</ion-col>
              <ion-col>Ramp</ion-col>
              <ion-col>Temp</ion-col>
              <ion-col>Hold</ion-col>
            </ion-row>
            <ion-row *ngFor="let step of details.program.steps; index as i;">
              <ion-col>{{step.num}}</ion-col>
              <ion-col>{{step.rt}}</ion-col>
              <ion-col>{{step.t}}</ion-col>
              <ion-col>{{getHour(details, step)}}:{{step.mn.toString().padStart(2, '0')}}</ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>

      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Steps</ion-card-subtitle>
        </ion-card-header>
        <ion-card-content>
          <ion-grid *ngIf="program_steps && program_steps.length > 0">
              <ion-row>
                <ion-col>Segment</ion-col>
                <ion-col>Start Time</ion-col>
                <ion-col>End Time</ion-col>
              </ion-row>
              <ion-row *ngFor="let step of program_steps; index as i;">
                <ion-col>{{step.name}}</ion-col>
                <ion-col *ngIf="step.start_time !== '---'">{{step.start_time | date:'short'}}</ion-col>
                <ion-col *ngIf="step.start_time === '---'">{{step.start_time}}</ion-col>
                <ion-col *ngIf="step.end_time !== '---'">{{((step.name && step.name.includes('Hold') && (program_steps[i+1] !== undefined && program_steps[i+1] !== null)) ? program_steps[i+1].start_time : step.end_time) | date:'short'}}</ion-col>
                <ion-col *ngIf="step.end_time === '---'">{{step.end_time}}</ion-col>
              </ion-row>
              <ion-row *ngIf="program_steps && program_steps.length < 4" class='empty'></ion-row>
              <ion-row *ngIf="program_steps && program_steps.length < 3" class='empty'></ion-row>
            </ion-grid>
        </ion-card-content>
      </ion-card>

    </div>

    <div *ngIf="subtab == 'results-graph' && details && details.statuses && details.statuses.length > 0" class="tab-body graph">
      <app-firing-graph [zones]="details.config ? details.config.num_zones : 1" 
        [dataLength]="details.statuses ? details.statuses.length : 0"
        [data]="details.statuses" [maxWidth]="graphDimensions.width" [maxHeight]="graphDimensions.height" [smallScreen]="small_screen">
      </app-firing-graph>
    </div>
    <div *ngIf="subtab == 'firing-data' && details"  class="details-table tab-body">
      <ion-grid class="firing-data-table" *ngIf="details.statuses && details.statuses.length > 0">
        <ion-row class="header">
          <ion-col>Date</ion-col>
          <ion-col>Fire Time</ion-col>
          <ion-col>Step</ion-col>
          <ion-col>Set Point</ion-col>
          <ion-col *ngIf="details.config.num_zones>=2">Zone 1</ion-col>
          <ion-col>Zone 2</ion-col>
          <ion-col *ngIf="details.config.num_zones>=3">Zone 3</ion-col>
          <ion-col>Board Temp</ion-col>
        </ion-row>
        <ion-row *ngFor="let status of details.statuses">
          <ion-col>{{(status.createdAt || status.date) | date:'short'}}</ion-col>
          <ion-col *ngIf="status.mode === 'Firing'">{{status.fire_hour}}h{{status.fire_min}}m</ion-col>
          <ion-col *ngIf="status.mode !== 'Firing'">-</ion-col>
          <ion-col>{{status.step}}</ion-col>
          <ion-col>{{status.set_pt}}</ion-col>
          <ion-col *ngIf="details.config.num_zones>=2">{{status.t1}}</ion-col>
          <ion-col>{{status.t2}}</ion-col>
          <ion-col *ngIf="details.config.num_zones>=3">{{status.t3}}</ion-col>
          <ion-col>{{status.board_t}}</ion-col>
        </ion-row>
      </ion-grid>
    </div>
    <div *ngIf="subtab == 'firing-notes' && selected_firing" class="tab-body">
      <form [formGroup]="firingNotesForm" class="firing-notes-form" (ngSubmit)="submitFiringNote(firingNotesForm.value)">
        <ion-item>
          <ion-label>Load Density</ion-label>
          <ion-input formControlName="load_density" ></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Load Description</ion-label>
          <ion-input formControlName="load_description"></ion-input>
        </ion-item>

        <ion-item>
          <ion-label>Comments</ion-label>
          <ion-textarea formControlName="comments"></ion-textarea>
        </ion-item>

        <div class="buttons">
          <input *ngIf="!submittingNote && editingNote" type="submit" value="Update"/>
          <input *ngIf="!submittingNote && !editingNote" type="submit" value="Submit"/>
          <button *ngIf="submittingNote"><ion-spinner name="bubbles"></ion-spinner></button>
        </div>
      </form>
    </div>
  </div>
</div>
