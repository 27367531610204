<div id="main-container">
    <ion-item color="medium"><ion-label><h4>Inbox</h4></ion-label></ion-item>
    <ion-grid *ngIf="!small_screen">
      <ion-row>
        <ion-col>From</ion-col>
        <ion-col>Subject</ion-col>
        <ion-col>Received</ion-col>
        <ion-col>Read or Mark As Read</ion-col>
      </ion-row>
      <ion-row *ngIf="viewingKilnlinkFreeKilns">
        <ion-col colspan="4">You are currently viewing a KilnLink Free kiln. Upgrade today to receive alerts.</ion-col>
      </ion-row>
      <ion-row *ngIf="!viewingKilnlinkFreeKilns && gettingAlerts">
        <ion-col colspan="4"><ion-spinner name="bubbles"></ion-spinner></ion-col>
      </ion-row>
      <ion-row *ngIf="!viewingKilnlinkFreeKilns && !gettingAlerts && sentAlerts && sentAlerts.length === 0">
        <ion-col colspan="4">No alerts have been sent for this kiln.</ion-col>
      </ion-row>
      <ion-row *ngFor="let sentAlert of sentAlerts; let i = index;"
        [class.selected]="selectedAlert && i === selectedAlert.index"
        (click)="selectAlert(i)">
        <ion-col>{{sentAlert.kiln_name}}</ion-col>
        <ion-col>{{sentAlert.subject}}</ion-col>
        <ion-col>{{sentAlert.sent_time | date:'MMM dd, yyyy hh:mm zz' }}</ion-col>
        <ion-col *ngIf="sentAlert.is_read">{{sentAlert.read_by_time.sent_time | date:'medium' }}</ion-col>
        <ion-col class="td mark-as-read" *ngIf="!sentAlert.is_read"
          [class.disabled]="marking_as_read">
          <ion-button (click)="markRead(sentAlert.type, sentAlert.alert_id)">Mark As Read</ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-item *ngIf="small_screen">
      <ion-label *ngIf="selectedAlert" class="ion-text-wrap">{{selectedAlert.subject}}</ion-label>
      <ion-button (click)="showPicker()">Choose Alert</ion-button>
    </ion-item>

  <div id="current-message">
    <ion-list>
      <ion-item>
        <ion-label><p>Alert Type:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.type"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label><p>From:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.kiln_name"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label><p>Date:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.sent_time | date:'medium'"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label><p>Email:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.email_sent_to ? selectedAlert.email_sent_to.join(', ') : 'No emails to send to'"></ion-input>
      </ion-item>
      <ion-item>
        <ion-label><p>Text Msg:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.text_sent_to ? selectedAlert.text_sent_to.join(', ') : 'No phones to send to'"></ion-input>
      </ion-item>
      <ion-item lines="no-lines" color="light-shade">
        <ion-label><p>Subject:</p></ion-label><ion-input class="ion-text-right" readonly="true" [value]="selectedAlert.subject"></ion-input>
      </ion-item>
      <ion-item id="current-message-text">
        {{selectedAlert.message}}
      </ion-item>
    </ion-list>
  <!-- </div> -->
</div>
