<div class="popup">
  <h4 class="title">Switch Accounts <ion-icon name="close" (click)="onDismiss()"></ion-icon></h4>
  <ion-list class="account-info">
    <ion-item class="vertical">
      <ion-label>Current Account:</ion-label>
      <p *ngIf="!retrievingAccountInformation">{{getAccountName(current_account)}}</p>
      <p *ngIf="retrievingAccountInformation"><ion-spinner name="bubbles"></ion-spinner></p>
    </ion-item>

    <ion-item lines="none">
      <ion-button slot="end" (click)="changingCurrent = true; changingDefault = false; updateForm(current_account);"
        *ngIf="!retrievingAccountInformation"
        class="primary" color="primary" fill="outline">
          View Different Account
      </ion-button>
    </ion-item>
  </ion-list>

  <ion-list class="account-info">
    <ion-item class="vertical">
      <ion-label>Default Account:</ion-label>
      <p *ngIf="!retrievingAccountInformation">{{getAccountName(default_account)}}</p>
      <p *ngIf="retrievingAccountInformation"><ion-spinner name="bubbles"></ion-spinner></p>
    </ion-item>

    <ion-item lines="none">
      <ion-button slot="end" (click)="changingDefault = true; changingCurrent = false; updateForm(default_account);"
        *ngIf="!retrievingAccountInformation"
        class="secondary" color="medium">
          Change Default
      </ion-button>
    </ion-item>
  </ion-list>

  <form *ngIf="changingCurrent || changingDefault" [formGroup]="accountForm" (ngSubmit)="save(accountForm.value)"
    id="switch-account-form">    
    <ion-item lines="none">
      <ion-label>
        {{changingCurrent ? 'View A Different Account' : 'Change Your Default Account'}}                
      </ion-label>      
    </ion-item>
    <ion-item>
      <ion-note *ngIf="changingCurrent"><sup>*</sup>Not a permanent change. When you login you will see your default account.</ion-note>
      <ion-note *ngIf="!changingCurrent"><sup>*</sup>This will change the account you see by default on your next login.</ion-note>
    </ion-item>
    <ion-item fill="solid">
      <ion-select formControlName="account" *ngIf="users_accounts && users_accounts.length > 0" color="medium" slot="end">
        <ion-select-option *ngFor="let acct of users_accounts" [value]="acct.id">{{acct.name}}</ion-select-option>
        <ion-select-option *ngIf="hasKilnlinkFreeKilns && changingCurrent" [value]="0">KilnLink Free Kilns</ion-select-option>
      </ion-select>
    </ion-item>
    <div *ngIf="!users_accounts || (users_accounts && users_accounts.length === 0)">You do not have access to any accounts yet.</div>
  </form>

  <ion-list>
    <ion-item slot="end" lines="none" *ngIf="changingCurrent || changingDefault">
      <ion-button class="button" color="dark" slot="end" size="default"
        (click)="changingDefault = false; changingCurrent = false;">
        Cancel
      </ion-button>
      <ion-button *ngIf="changingCurrent" (click)="save(accountForm.value)" slot="end" size="default"
        class="submit" color="tertiary" slot="end">View Account</ion-button>
      <ion-button *ngIf="changingDefault" (click)="save(accountForm.value)" slot="end" size="default"
        class="submit" color="tertiary" slot="end">Change and View Default</ion-button>
    </ion-item>
    <ion-item class="buttons" lines="none" slot="end">
      <ion-button (click)="onDismiss()" class="close" slot="end" size="default" color="light">Close</ion-button>
    </ion-item>    
  </ion-list>
</div>
