<ion-list id="change-password-form">
  <h4 class="title">Change Password <ion-icon name="close" (click)="onDismiss()"></ion-icon></h4>
  <form [formGroup]="changePwdForm" (ngSubmit)="onSubmit(changePwdForm.value)">
    <ion-item fill="outline">
      <ion-label position="floating">Current Password</ion-label>
      <ion-input type="password" formControlName="password"
        [class.has-error]="!changePwdForm.controls.password.valid && changePwdForm.controls.password.touched" ></ion-input>
    </ion-item>

    <ion-item fill="outline">
      <ion-label position="floating">New Password</ion-label>
      <ion-input type="password" formControlName="new_password"
        [class.has-error]="!changePwdForm.controls.new_password.valid && changePwdForm.controls.new_password.touched" ></ion-input>
    </ion-item>

    <ion-item fill="outline">
      <ion-label position="floating">Re-type New Password</ion-label>
      <ion-input type="password" formControlName="new_password_confirmation"
        [class.has-error]="!changePwdForm.controls.new_password_confirmation.valid && changePwdForm.controls.new_password_confirmation.touched"></ion-input>
    </ion-item>

    <ion-item class="buttons" lines="none">
      <ion-button class="cancel" slot="end" (click)="onDismiss()">Cancel</ion-button>
      <ion-button *ngIf="!updatingPassword" slot="end" [disabled]="!changePwdForm || !changePwdForm.valid" type="submit">Submit</ion-button>
      <ion-button *ngIf="updatingPassword" slot="end"><ion-spinner name="bubbles"></ion-spinner></ion-button>
    </ion-item>
  </form>
</ion-list>
