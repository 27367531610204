<ion-header class="full-page-only" [class.show_premium]="show_premium">
  <ion-toolbar>
    <ion-buttons slot="start" class="logo">
      <img class="main-img" src="assets/Skutt-Original-Horizontal-Logo_PMS-167_RegisteredTMWhiteSun.png"/>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content class="ion-padding" class="has-header home-page" [class.show_premium]="show_premium">
  <ion-img [src]="logoUrl" class="login-logo"></ion-img>
  <ion-list id="login-list">
    <ion-item id="login-username-item" name="email" (click)="focusInput('email')">
      <ion-label fixed>email<sup>*</sup></ion-label>
      <ion-input id="email" name="username" type="text" [(ngModel)]="loginEmail"></ion-input>
    </ion-item>
    <ion-item id="login-password-item" name="password" (click)="focusInput('password')">
      <ion-label fixed>password<sup>*</sup></ion-label>
      <ion-input id="password" name="password" type="password" [(ngModel)]="loginPassword"></ion-input>
    </ion-item>
    <ion-item id="login-remember-item" >
      <ion-label>Remember Me</ion-label>
      <ion-toggle name="rememberMe" [(ngModel)]="loginRemember"></ion-toggle>
    </ion-item>
    <!-- </ion-item>  <ion-toggle toggle-class="toggle-balanced" ng-checked="true" id="login-toggle2" name="rememberMe" ng-model="userRemember">Remember Me</ion-toggle> -->
    <!-- <button id="login-button1" ng-click="loginClick()" style="border-radius:0px 0px 0px 0px;" class="button button-positive button-block">{{loginBtnText}}</button> -->
  </ion-list>
  <div class="login" [class.disabled]="isLoggingIn || loginBtnText != 'Sign In'"
    [class.show_premium]="show_premium">
    <ion-button type="submit" size='full' [disabled]="isLoggingIn || loginBtnText != 'Sign In'"
      (click)="loginClick()">
      <span *ngIf="!isLoggingIn">{{loginBtnText}}</span>
      <ion-spinner name="bubbles" *ngIf="isLoggingIn"></ion-spinner>
    </ion-button>
    <ion-button size='full' *ngIf="isLoggingIn" (click)="cancelLogin()">
      <span>Cancel Login</span>
    </ion-button>
  </div>

  <div id="forgot-password" [class.show_premium]="show_premium">
    <span><a (click)="forgotPassword()">Forgot Password</a></span>
    <span><a (click)="createUser()">Sign Up</a></span>
  </div>

  <div id="login-hint">
    <!-- <div><sup>**</sup>&nbsp;MySkutt users: Please enter your username and password and we will work to link that username with an email and password of your choice.</div> -->
    <div><ion-icon name="alert"></ion-icon>&nbsp;Emails are case sensitive. We recommend using lowercase for emails.</div>
  </div>
  <div id="login-markdown3" *ngIf="errorText" class="error-box"
     [class.show_premium]="show_premium">
    <p>{{errorText}}</p>
  </div>
</ion-content>
