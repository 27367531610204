import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';

import momentTimezones from 'moment-timezone';
import moment from 'moment';
import { PaymentService } from '../services/payment.service';
import { SubscriptionService } from '../services/subscription.service';
import { AccountPaymentComponent } from '../account-payment/account-payment.component';
import { AlertController } from '@ionic/angular';

@Component({
  selector: 'app-account-settings-general',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
})
export class AccountSettingsGeneralComponent implements OnInit {
  @Input() accountInformation;
  @Input() canAdminister;

  @Output() pending = new EventEmitter<Object>();
  @Output() buttonText = new EventEmitter<Object>();
  @Output() changeSection = new EventEmitter<String>();

  public generalAccountInfoForm: UntypedFormGroup;
  public type;
  public numberKilns;
  public expiration = 'N/A'; 
  public isExpired = false;
  public editing = false;
  public loading = false;

  private account_id;
  public payment;
  public paymentExpiredOrNonExistent = false;
  public formLoaded = false;
  private existingSubscriptionId = false;
  
  @ViewChild('paymentsForm', { read: AccountPaymentComponent }) paymentForm;

  constructor(private accountService: AccountService, 
    private paymentService: PaymentService,
    private subscriptionService: SubscriptionService,
    public alertController: AlertController,) {}

  ngOnInit() {
    this.loading = true;    
    this.account_id = this.accountInformation.general.external_id;
    this.type = (this.accountInformation && this.accountInformation.general) ? this.accountInformation.general.type : 'N/A';
    this.numberKilns = (this.accountInformation && this.accountInformation.general) ? this.accountInformation.general.kiln_limit : 'N/A';
    this.formLoaded = false;

    this.subscriptionService.get(this.account_id).then((subscription) => {
      if(subscription) this.existingSubscriptionId = subscription['id']
      this.createForm(subscription);
      this.expiration = subscription ? subscription['expirationDate'] : (this.accountInformation && this.accountInformation.general) ? this.accountInformation.general.expiration : 'N/A';
      let now = moment(new Date())
      let exp = moment(this.expiration)
      this.isExpired = now.isAfter(exp);   
            
      this.paymentService.getPaymentInfo(this.account_id).then((payment_response) => {
        this.payment = payment_response.payment
        this.paymentExpiredOrNonExistent = !payment_response.payment ? true : false;
      });    
    }).catch((e) => {
      this.createForm();
      this.expiration = (this.accountInformation && this.accountInformation.general) ? this.accountInformation.general.expiration : 'N/A';
      this.isExpired = moment(new Date()).isAfter(moment(this.expiration));   
      this.paymentService.getPaymentInfo(this.account_id).then((payment_response) => {
        this.payment = payment_response.payment
        this.paymentExpiredOrNonExistent = !payment_response.payment ? true : false;
      });    
    })    
  }

  async successfulUpdate() {
    this.editing = false;
    this.pending.emit({
      is_pending: false,
      message: 'Account general information updated.',
      success: true
    });
  }

  createForm(subscription = undefined) {
    if(!this.generalAccountInfoForm && this.accountInformation) {
      var guess_time_zone = momentTimezones.tz.guess();
      let account_has_time_zone = this.accountInformation && this.accountInformation.general && this.accountInformation.general.timezone
      let time_zone = (account_has_time_zone) ? this.accountInformation.general.timezone : guess_time_zone;
      let timezone = new UntypedFormControl(time_zone, [Validators.required]);

      let freq = 'kiln_annual'
      let diffFreq = 1;
      if(subscription) {
        freq = (subscription.planId || 'kiln_annual')      
        let difference = moment.duration(moment(subscription.paidThroughDate).diff(moment(subscription.firstBillingDate)))
        diffFreq = Math.round(freq.includes('annual') ? difference.asYears() : difference.asMonths())
      }
      
      this.generalAccountInfoForm = new UntypedFormGroup({
        type: new UntypedFormControl(this.type, [Validators.required]),
        numberControllers: new UntypedFormControl(this.numberKilns > 0 ? this.numberKilns : 1, [Validators.required, Validators.min(1)]),
        frequency: new UntypedFormControl(freq, [Validators.required]),
        renewals: new UntypedFormControl(diffFreq || 1, [Validators.required]),
        timezone: timezone,
      });

      this.formLoaded = true;
    }
  }

  getTopUSTimezones() {
    return momentTimezones.tz.names().filter((name) => {
      let default_tzs = [
        'America/New_York', 'America/Chicago',
        'America/Denver', 'America/Los_Angeles',
        'America/Anchorage', 'Pacific/Honolulu'
      ]

      return default_tzs.includes(name);
    });
  }

  getTimezones() {
    return momentTimezones.tz.names();
  }

  public async save() {
    try {
      this.pending.emit({is_pending: true});
      await this.paymentForm.save();     
      await this.accountService.updateGeneral(this.accountInformation.general.external_id, this.generalAccountInfoForm.value.timezone, this.generalAccountInfoForm.value.type)        
      if(this.paymentService.getNonce()) {
        if(this.existingSubscriptionId) {
          await this.subscriptionService.update(this.account_id, this.existingSubscriptionId, this.generalAccountInfoForm.value)    
        } else {
          await this.subscriptionService.create(this.account_id, this.generalAccountInfoForm.value)    
        }        
      }
      this.successfulUpdate();
    } catch(e) {
      this.pending.emit({
        is_pending: false,
        message: 'Sorry, we could not update your account information at this time',
        success: false
      });
    }
  }

  public async delete() {
    const alert = await this.alertController.create({
      header: 'Delete Confirmation',
      message: 'Are you sure you want to delete your subscription?',
      buttons: [
        {
          text: 'No, Keep My Subscription',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (data) => {}
        }, {
          text: 'Yes, Cancel Subscription',
          handler: () => {
            this.subscriptionService.delete(this.account_id, this.existingSubscriptionId)
          }
        }]
      });

      alert.present();
  }

  public isValid() {
    return this.generalAccountInfoForm && this.generalAccountInfoForm.touched && this.generalAccountInfoForm.valid && this.paymentForm && this.paymentForm.isValid();
  }

  public isEditingPayment() {
    return this.paymentForm ? this.paymentForm.editing() : false;
  }

  startEditing(forceEdit = null) {
    this.editing = this.editing ? false : true;
    this.buttonText.emit({editButton: this.editing ? 'Cancel' : 'Edit'});
    if(forceEdit) {
      this.editing = true;
    }
    
    if(this.editing && !forceEdit) {
      this.paymentForm && this.paymentForm.showPaymentForm();
    }
  }
}
