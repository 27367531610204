import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AccountService } from 'src/app/services/account.service';
import { ToastController } from '@ionic/angular';

import { AccountSettingsCompanyComponent } from '../account-settings/company.component';
import { AccountSettingsGeneralComponent } from '../account-settings/general.component';
import { AccountSettingsOwnerComponent } from '../account-settings/owner.component';
import { AccountSettingsUsersComponent } from '../account-settings/users.component';

@Component({
  selector: 'app-update-account-info',
  templateUrl: './update-account-info.component.html',
  styleUrls: ['./update-account-info.component.scss'],
})
export class UpdateAccountInfoComponent implements OnInit {
  @Input() onDismiss;

  @ViewChild('companyForm', { read: AccountSettingsCompanyComponent }) companyForm;
  @ViewChild('generalForm', { read: AccountSettingsGeneralComponent }) generalForm;
  @ViewChild('ownerForm', { read: AccountSettingsOwnerComponent }) ownerForm;
  @ViewChild('usersForm', { read: AccountSettingsUsersComponent }) usersForm;
  
  public mainSection = 'users';
  public canAdminister = false;
  public loggedInUser;
  public accountInformation;
  public retreivingInfo = false;
  public updatePending;
  public buttonText = "Update";

  constructor(public userService: UserService,
    public accountService: AccountService,
    public toastController: ToastController)
  {
    this.loggedInUser = userService.getLoginData();
    this.retreivingInfo = true;
  }

  ngOnInit() {
    this.loggedInUser = this.userService.getLoginData();
    this.retreivingInfo = true;
    this.accountService.getAccountInformation()
        .then((data) => {
          this.retreivingInfo = false;
          this.accountInformation = data;
          this.setUpCanAdminister(data);
        })
        .catch((error) => this.onAccountError(error));
    
    this.accountService.getDevicesForAccount()
        .then((data) => {
          let kilns = data && data.kilns.sort((a, b) => (a.name > b.name) ? 1 : -1);
          this.setKilns(kilns);
        }).catch((error) => {
          this.onAccountError(error);
        });
  }

  onAccountError(error) {
    this.retreivingInfo = false;
  }

  setUpCanAdminister(data) {
    if(this.accountInformation && this.accountInformation.users && this.loggedInUser) {
      let loggedInUserPermissions = this.accountInformation.users.find((u) => {
        if(this.loggedInUser.hasOwnProperty('loggedInAs') && this.loggedInUser.loggedInAs) {
          return u.email === this.loggedInUser.loggedInAs.email;
        } else {
          return u.email === this.loggedInUser.email;
        }
      })
      this.canAdminister = loggedInUserPermissions ? (loggedInUserPermissions.type === 'admin') : false;
    }
  }

  setKilns(data) {
    if(this.accountInformation) {
      this.accountInformation.kilns = data;
    } else {
      this.accountInformation = {
        kilns: data
      };
    }
  }

  getCurrentForm() {
    let formToSelectedTab = {
      'users': this.usersForm,
      'accountOwner': this.ownerForm,
      'company': this.companyForm,
      'general': this.generalForm
    }

    return formToSelectedTab[this.mainSection];
  }

  save() {
    console.log(`save in component`)
    let currentForm = this.getCurrentForm()
    console.log(currentForm)
    currentForm && currentForm.save();
  }

  currentFormIsValid() {
    let currentForm = this.getCurrentForm();
    return currentForm ? currentForm.isValid() : true;
  }

  setButtonName(text) {
    this.buttonText = text;
  }

  setPending(data) {
    if(data.message) {
      if(data.success) {
        this.successfulUpdate(data.message);
      } else {
        this.failedUpdate(data.message);
      }

      if(data.dismiss && data.dismiss === true) {
        this.onDismiss();
      }
    }

    this.updatePending = data.is_pending;
  }

  async successfulUpdate(message) {
    let toast = await this.toastController.create({
     message: message || "The update was successful.",
     duration: 1000,
     position: 'middle'
    });

    await toast.present();
  }

  async failedUpdate(message) {
    let toast = await this.toastController.create({
     message: message || "Sorry the update did not succeed, please try again or contact help.",
     duration: 1000,
     position: 'middle'
    });

    await toast.present();
  }

  changeSection(event) {
    this.mainSection = event;
  }
}
