<p class="error"><span  *ngIf="error">{{error}}</span></p>
<ul id="user-info-container">
  <li>
    <label>Email:</label><p>{{email}}</p>
  </li>
  <!-- <li class="premium-upgrade">
    <label>Phone:</label>
    <input type="phone"/>
  </li>
  <li class="premium-upgrade">
    <label>Verified for Alerts</label>
    <ion-icon name="checkmark-done-outline"></ion-icon>
  </li> -->
  <li>
    <button (click)="deleteAccount()">Delete Account</button>
  </li>

  <div class="premium-upgrade" *ngIf="trialAvailable()">
    <p>Premium features available. Try today! </p>
    <button (click)="startTrial()">Start Trial</button>
  </div>
</ul>
