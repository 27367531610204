<div class="maintenance-tab-main" *ngIf="small_screen && retrievingNotes">
  <ion-spinner name="bubbles"></ion-spinner>
</div>
<div class="maintenance-tab-main" *ngIf="!small_screen">
  <h4>Maintenance Notes</h4>
   <div id="maintenance-list" *ngIf="!viewingKilnlinkFreeKilns && maintenance_notes && maintenance_notes.length > 0">
     <ion-grid class="table" *ngIf="maintenance_notes && maintenance_notes.length > 0">
        <ion-row class="header">
          <ion-col>Date </ion-col>
          <ion-col>{{small_screen ? 'Tech' : 'Technician'}} </ion-col>
          <ion-col size="4">Type </ion-col>
          <ion-col size="4">Notes </ion-col>
          <ion-col size="1" [class.small-screen]="small_screen">&nbsp; </ion-col>
          <ion-col size="1" *ngIf="!small_screen">&nbsp; </ion-col>
          <!-- <ion-col size="1" *ngIf="!small_screen">&nbsp; </ion-col> -->
        </ion-row>
        <ion-row *ngFor="let note of maintenance_notes">
          <ion-col *ngIf="!small_screen">{{note.note_date | date:'mediumDate':'+0000' }} </ion-col>
          <ion-col *ngIf="small_screen">{{note.note_date | date:'mediumDate':'+0000' }} </ion-col>
          <ion-col>{{note.technician}} </ion-col>
          <ion-col size="4">{{ getFriendlyType(note.type) }} </ion-col>
          <ion-col size="4">{{note.notes}} </ion-col>
          <ion-col size="1" *ngIf="!small_screen" (click)="loadToMaintenanceForm(note)">
            <ion-button color="warning" class="edit">Edit</ion-button> 
          </ion-col>
          <ion-col size="1" *ngIf="!small_screen" (click)="deleteNote(note)">
            <ion-button class="delete">Delete</ion-button>
          </ion-col>
          <ion-col size="1" *ngIf="small_screen">
            <ion-icon (click)="loadToMaintenanceForm(note)" name="create-outline"></ion-icon>
            <ion-icon (click)="deleteNote(note)" class="delete" name="trash-outline"></ion-icon>
          </ion-col>
       </ion-row>
     </ion-grid>
    </div>
</div>
<p id="no-notes-message" *ngIf="!viewingKilnlinkFreeKilns && maintenance_notes && maintenance_notes.length == 0">
  There are no maintenance notes available at this time. Use the form below to add one.
</p>
<p id="no-notes-message" *ngIf="viewingKilnlinkFreeKilns && maintenance_notes && maintenance_notes.length == 0">
  You are viewing a KilnLink Free kiln. Upgrade today to create, view and edit maintenance notes for your kiln(s).
</p>
<div class="maintenance-tab-main" *ngIf="small_screen && !viewingKilnlinkFreeKilns && maintenance_notes && maintenance_notes.length > 0">
  <ion-item lines="none" id="small-screen-actions">
    <ion-label slot="start">Actions</ion-label>
    <ion-label slot="end" class="action-buttons">
      <ion-button (click)="showPicker()">{{selectedMaintenanceNote ? 'Choose' : 'View'}}</ion-button>
      <ion-button (click)="creatingNew = true; selectedMaintenanceNote = undefined;">New</ion-button>
    </ion-label>
  </ion-item>
  <ion-card *ngIf="selectedMaintenanceNote">
    <ion-card-header>
      <ion-card-title>{{selectedMaintenanceNote.note_date | date:'mediumDate':'+0000' }}</ion-card-title>
      <ion-card-subtitle>{{getFriendlyType(selectedMaintenanceNote.type)}}</ion-card-subtitle>
    </ion-card-header>
    <ion-card-content>
      <h6>Technician: {{selectedMaintenanceNote.technician}}</h6>
      <p>{{selectedMaintenanceNote.notes}}</p>
      <ion-item lines="none">
        <ion-label slot="end">
          <ion-button class="edit" (click)="loadToMaintenanceForm(selectedMaintenanceNote)" color="warning">Edit</ion-button> 
          <ion-button class="delete" (click)="deleteNote(selectedMaintenanceNote)" color="primary">Delete</ion-button>
        </ion-label>
      </ion-item>
    </ion-card-content>
  </ion-card>
</div>
<div class="maintenance-tab-main" *ngIf="!viewingKilnlinkFreeKilns && maintenance_notes && maintenance_notes.length > 0 && (small_screen ? ((creatingNew && !selectedMaintenanceNote) || (is_editing)) : true)">
  <h4>Maintenance Log Record</h4>
  <form [formGroup]="maintenanceNoteForm" (submit)="onSubmit(maintenanceNoteForm.value)">
      <ion-item>
        <ion-label>Date:  </ion-label>
        <ion-input slot="end" type="date" formControlName="date" fill="solid"></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>Technician:  </ion-label>
        <ion-input slot="end" type="text" formControlName="technician" fill="solid" placeholder="Technician Name" ></ion-input>
      </ion-item>

      <ion-item>
        <ion-label>Type:  </ion-label>
        <ion-select slot="end" formControlName="type">
          <ion-select-option *ngFor="let option of maintenance_types" [default]="option.value === 'replace_one_element'" value="{{option.value}}">{{option.option_text}}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-label>Notes:  </ion-label>
        <ion-textarea rows="6" cols="50"  formControlName="notes" fill="solid" placeholder="Enter notes here"></ion-textarea>
      </ion-item>

      <ion-item class="buttons">
        <ion-label slot="end">
          <ion-button *ngIf="small_screen" (click)="creatingNew = false; is_editing = false;" color="medium" fill="solid">Cancel</ion-button>
          <ion-button *ngIf="!small_screen" (click)="clearForm()" color="medium" fill="solid">Clear Form</ion-button>
          <ion-button type="submit" color="tertiary" *ngIf="!viewingKilnlinkFreeKilns">{{is_editing ? 'Edit Note': 'Create Note'}}</ion-button>
          <ion-button readonly="true" disabled="true" color="tertiary" type="submit" *ngIf="viewingKilnlinkFreeKilns">{{is_editing ? 'Edit Note': 'Create Note'}}</ion-button>
        </ion-label>
      </ion-item>
  </form>
</div>