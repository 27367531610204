import { Component, OnInit, Input } from '@angular/core';
import { NavController, ToastController, Platform, PickerController } from '@ionic/angular';
import { KilnService, kilnInfo, alarmType } from 'src/app/services/kiln.service';
import { UserService } from 'src/app/services/user.service';
import { NavigationExtras, ActivatedRoute } from '@angular/router';
import moment from 'moment';

@Component({
  selector: 'app-controller-diagnostics-tab',
  templateUrl: './controller-diagnostics-tab.component.html',
  styleUrls: ['./controller-diagnostics-tab.component.scss'],
})
export class ControllerDiagnosticsTabComponent implements OnInit {
  @Input() kilnId;
  @Input() kiln_name;
  @Input() viewingKilnlinkFreeKilns;
  public email;
  public kiln_id;
  public token;
  public hasElevatedPermissions = false;
  public alarm: alarmType = {
    active: false,
    text:'',
    class:''
  };
  public kiln = {
    name: '',
    serial_number: '',
    status: {
      firing: {fire_hour: '', fire_min: '', set_pt: '', step: '', hold_hour: '', hold_min: '', cost: ''},
      mode: 'Not Connected',
      errorText: '',
      t1: '', t2: '', t3: '',
      error: null,
      diag: {a1: '', a2: '', a3: '', nl: '', fl: '', v1: '', v2: '', v3: '', updatedAt: undefined}
    },
    config: {t_scale: 'F', num_zones: 1},
    program: {name: ''},
    updatedAt: '',
    mode: 'default'
  }
  public errorText = '';
  public role = 'user';
  public gotData = false;
  public kilnName;
  public notConnected = false;
  public kiln_diagnostics = [];
  public diagnostics_picker = [];
  public selected_diagnostic;
  public small_screen;

  constructor(public navCtrl: NavController,
      public toastCtrl: ToastController,
      public kilnService: KilnService,
      public userService: UserService,
      private route: ActivatedRoute,
      public platform: Platform,
      private pickerController: PickerController
  ) {
    this.hasElevatedPermissions = this.userService.hasElevatedPermissions();
    this.alarm = this.kilnService.selectedKilnAlarm;
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb')) : false;
    this.small_screen = this.platform.width() < 875 && is_device;
  }

  getDiagData() {
    if(!this.viewingKilnlinkFreeKilns) {
      this.getKilnData();
    } else {
      this.kiln_diagnostics = [{a1: '--', a2: '--', a3: '--', nl: '--', fl: '--', v1: '--', v2: '--', v3: '--', date: moment(), status: 'Sample Data'}]      
    }
  }

  ngOnInit() {
    this.getDiagData()
  }

  ngOnChanges() {
    this.getDiagData()
  }

  ngOnLeave() {
    this.errorText = "";
    this.kiln = undefined;
    this.kiln_diagnostics = [];
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        this.selected_diagnostic = selection.diagnostics.value;
      },
    },
  ];

  async showPicker() {
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'diagnostics',
        options: this.diagnostics_picker
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }

  public getKilnData() {
    if(this.kilnId) {
      this.gotData = false;
      this.kilnService.getDiagnosticInformation(this.kilnId)
          .then((data) => {
            this.gotData = true;
            this.kiln_diagnostics = data.sort((diag1, diag2) => {
              let diag1_greater = moment(diag1.date).isAfter(moment(diag2.date));
              let diag2_greater = moment(diag2.date).isAfter(moment(diag1.date));

              if(diag1_greater) { return -1 }
              if(diag2_greater) { return 1 }
              return 0;
            });
            this.selected_diagnostic = this.kiln_diagnostics[0];
            this.diagnostics_picker = this.kiln_diagnostics.map((diag) => { return {text: moment(diag.date).format('MM/DD/YY h:mm a').toString(), value: diag}})
          })
          .catch((error) => {this.gotData = true;})

      this.kiln_id = this.kilnId;
      if(this.kiln_name) {
        this.kilnName = this.kiln_name;
        if(this.kiln) {
          this.kiln.name = this.kiln_name;
        } else {
          this.kiln = {
            name: this.kiln_name,
            serial_number: this.kilnId,
            status: {
              firing: {fire_hour: '', fire_min: '', set_pt: '', step: '', hold_hour: '', hold_min: '', cost: ''},
              mode: '',
              errorText: '',
              t1: '', t2: '', t3: '',
              error: null,
              diag: undefined
            },
            config: {t_scale: 'F', num_zones: 1},
            program: {name: ''},
            updatedAt: '',
            mode: 'default'
          }
        }
      }
    }
  }
}
