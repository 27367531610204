import {Component, OnInit, ViewChild, Input, ElementRef, AfterViewInit} from '@angular/core';
import { Platform } from '@ionic/angular';
import { KilnService } from 'src/app/services/kiln.service';
import moment from 'moment';

@Component({
  selector: 'app-firing-status-graph',
  templateUrl: './firing-status-graph.component.html',
  styleUrls: ['./firing-status-graph.component.scss'],
})
export class FiringStatusGraphComponent implements OnInit, AfterViewInit {
  @Input() kilnId;
  @Input() kiln_name;
  @Input() kiln;
  @Input() viewingKilnlinkFreeKilns;
  @Input() smallScreen = false;
  @Input() dataUpdateTime;

  @ViewChild('container') private container: ElementRef;
  @ViewChild('noGraph') private noGraphContainer: ElementRef;
  public retrievingData = true;
  public notConnected;
  public error = false;
  private subscribed_to;
  public maxHeight;
  public maxWidth;

  public testData = {"statuses": [], "config": {num_zones: 1}, "program": {'steps': [{'t':0,'hr':0,'mn':0,'rt':0}]}};
  public historicalData = {"statuses": [], "config": {}, "program": {'steps': [{'t':0,'hr':0,'mn':0,'rt':0}]}};
  private svg;
  private margin = 30;
  private width;
  private height;
  private firingDataRefreshTime;

  private defaultTestDataStructure = {"statuses": [], "config": {num_zones: 1}, "program": {'steps': [{'t':0,'hr':0,'mn':0,'rt':0}]}};

  constructor(
    public kilnService: KilnService,    
    public platform: Platform
  ) {
    if(this.kiln && !this.kiln.noStatusFromKilnApi && !this.viewingKilnlinkFreeKilns) {
      let firing_number = this.kiln.latest_firing_start_time || this.kiln.firings_count;
      // this.events.subscribe(`getFiringDetails${this.kiln.mac_address}_${this.kiln.serial_number}_${firing_number}:success`, (data) => {
      //   this.onGetFiringData(data)
      // });
      // this.events.subscribe(`getFiringDetails${this.kiln.mac_address}_${this.kiln.serial_number}_${firing_number}:error`, (error) => this.onGetKilnDataError(error));
      this.subscribed_to = `${this.kiln.mac_address}_${this.kiln.serial_number}_${firing_number}`;
      if(this.retrievingData) console.log(`${this.retrievingData ? 'retrieving data - constructor' : 'has data'}`)
      this.retrievingData = true;
      this.error = false;
    } else {
      this.notConnected = true;
      this.retrievingData = false;
    }
  }

  ngAfterViewInit(): void {
    this.updateGraphDimensions()
  }

  updateGraphDimensions() {
    let baseWidth = this.platform.width();    
    if((this.container && this.container.nativeElement) || (this.noGraphContainer && this.noGraphContainer.nativeElement)) {
      let c = (this.container && this.container.nativeElement && !this.container.nativeElement.className.includes('none')) ? this.container : this.noGraphContainer;
      let boundingRect = c.nativeElement.getBoundingClientRect()
      baseWidth = boundingRect.width;
    }

    if(this.platform.width() < 850) {
      this.margin = 15;
    }

    this.width = baseWidth - this.margin*2;
    this.height = (this.platform.is('mobile') || baseWidth < 600 || this.platform.isPortrait()) ? (baseWidth * 1.1) : ((baseWidth / 1.75) - this.margin*2);
    this.maxHeight = this.height;
    this.maxWidth = this.width;
  }

  ngOnInit() {
    if(this.kiln && !this.kiln.noStatusFromKilnApi && !this.viewingKilnlinkFreeKilns) {
      this.notConnected = false;
      this.getUpdatedFiringInfo();
      this.onGetKilnDataSuccess();
      this.retrievingData = true;
      this.error = false;

      let firing_number = this.kiln.latest_firing_start_time || this.kiln.firings_count;
      this.subscribed_to = `${this.kiln.mac_address}_${this.kiln.serial_number}_${firing_number}`;

      if(!this.isNotConnected(this.kiln.updatedAt)) {
        this.firingDataRefreshTime = this.dataUpdateTime;
        this.kilnService.getFiringDetails(this.subscribed_to, this.kiln.config ? this.kiln.config.t_scale : 'F', true)
            .then((response) => { this.onGetFiringData(response); })
            .catch((error) => { this.onGetKilnDataError(error); })
      }
    } else {
      this.notConnected = true;
      this.retrievingData = false;
    }
  }

  ngOnChanges(changes) {    
    this.retrievingData = false;
    if(changes && (changes.kiln || changes.dataUpdateTime)) {      
      let prev = changes.kiln ? changes.kiln.previousValue : this.kiln;
      let curr = changes.kiln ? changes.kiln.currentValue : this.kiln;
      let prev_and_current_are_same = prev && curr && prev.serial_number === curr.serial_number;
      if(changes.kiln && curr && prev_and_current_are_same) {
        this.notConnected = curr.updatedAt ? this.isNotConnected(curr.updatedAt) : undefined;
      }

      let firing_number = curr ? (curr.latest_firing_start_time || curr.firings_count) : undefined;
      let new_subscribe_to = curr ? `${curr.mac_address}_${curr.serial_number}_${firing_number}` : undefined;
      if(prev && !prev_and_current_are_same && this.subscribed_to && this.subscribed_to !== new_subscribe_to) {
        firing_number = prev.latest_firing_start_time || prev.firings_count;
      }

      let dataUpdateChanged = changes.dataUpdateTime ? changes.dataUpdateTime.previousValue !== changes.dataUpdateTime.currentValue : false;
      var dataUpdatePassed = moment.duration(moment(this.dataUpdateTime).diff(moment(this.firingDataRefreshTime)));
      var threeMinutes = moment.duration(3, 'minutes');
      if(curr && (!prev_and_current_are_same && !curr.noStatusFromKilnApi && !this.viewingKilnlinkFreeKilns) || (dataUpdateChanged && dataUpdatePassed > threeMinutes)) {
        this.subscribed_to = new_subscribe_to;
        firing_number = curr.latest_firing_start_time || curr.firings_count;
        let external_id = `${curr.mac_address}_${curr.serial_number}_${firing_number}` 
        if(curr.mac_address && curr.serial_number && firing_number && !this.isNotConnected(curr.updatedAt)) {
          this.retrievingData = true;
          this.error = false;
          this.kilnService.getFiringDetails(external_id, this.kiln.config ? this.kiln.config.t_scale : 'F', true)
              .then((response) => { this.onGetFiringData(response); })
              .catch((error) => { this.onGetKilnDataError(error); })
          this.onGetKilnDataSuccess();
        }
      }

      this.updateGraphDimensions()
    }
  }

  getUpdatedFiringInfo() {    
    this.notConnected = !this.kiln || this.kiln.noStatusFromKilnApi;
    this.retrievingData = (this.kiln && !this.notConnected && !this.retrievingData);
    // if(this.retrievingData) { console.log(`retrieving data - getUpdatedFiringInfo!`) }
  }

  onGetFiringData(data) {
    // if (data.statuses){
    //   data.statuses.forEach(status => {
    //     if (status.mode != 'Firing'){
    //       status.set_pt = 0; //clear the set point after the firing is complete
    //     }
    //   });
    // }
    if(data && data.external_id === this.subscribed_to) {
      this.updateStatusGraph(data);
      this.error = false;
      this.retrievingData = false;
      this.notConnected = false;
    }
  }

  onGetKilnDataSuccess() {
    var startTime = moment();
    startTime.set({hour:0,minute:0,second:0,millisecond:0})
    // const startTime = moment.now();
    var progTime = moment(startTime);
    var progTemp = 70;
    var i;

    // this.kiln = data;
    let isNotConnected;
    if(this.kiln) {
      this.notConnected = this.isNotConnected(this.kiln['updatedAt'])
    } else {
      this.notConnected = true;
    }

    if(this.notConnected) {
      this.retrievingData = false;
    }
  }

  public updateStatusGraph(data: any) {    
    if(data && (data.external_id.includes(this.kiln.serial_number))) {
      var startTime = moment();
      startTime.set({hour:0,minute:0,second:0,millisecond:0})
      // const startTime = moment.now();
      var progTime = moment(startTime);
      var progTemp = 70;
      var progPtr = {time: progTime, temp: progTemp};
      var i;

      // console.log('graph update...');
      // console.log('sel kiln data: ' + JSON.stringify(this.kiln));
      if (this.kiln && this.kiln.status && (this.kiln.status.mode == 'Firing' || this.kiln.status.mode == 'Complete')){
        //kiln is firing
        this.testData = undefined;
        this.testData = data || this.defaultTestDataStructure;
        this.testData.program = this.kiln.program;
        this.testData.config = this.kiln.config;
        //add the unfired transition points
        if (data && data.statuses) {
          if(data.statuses.length === 0) {
            let firing_number = this.kiln.latest_firing_start_time || this.kiln.firings_count;
            let external_id = `${this.kiln.mac_address}_${this.kiln.serial_number}_${firing_number}`
            this.kilnService.getPaginatedStatuses(external_id, (this.kiln.config ? this.kiln.config.t_scale : 'F'))
                .then((response) => { this.onGetFiringData(response) })
                .catch((error) => { this.onGetKilnDataError(error) })
          }

          var statuses = data.statuses;
          var lastStatus = statuses[statuses.length-1];
          var stepAry;
          var stage:number = undefined;
          var firingInfo = this.kiln;
          var holdRem = {hr: firingInfo.status.firing.hold_hour, min: firingInfo.status.firing.hold_min};
          // console.log('hold rem: ' + JSON.stringify(holdRem));
          // console.log('fir info: ' + JSON.stringify(firingInfo));
          stepAry = lastStatus && lastStatus.step && lastStatus.step.split(' ');
          // console.log('stepAry: ' + JSON.stringify(stepAry));
          if (stepAry && stepAry.length == 4) {
            if (stepAry[0].startsWith('Ramp')){
              stage = (stepAry[1]-1) * 2;
            } else if (stepAry[0].startsWith('Hold')){
              stage = (stepAry[1]-1) * 2 + 1;
            }
            if (this.kiln.status.mode == 'Firing'){ // && stage !== undefined
              //we know where we are in the program so add the rest of the transition points
              progPtr = {time: lastStatus.date, temp: lastStatus.t2};
              this.addProgramSteps(this.testData, this.testData.program.steps, stage, progPtr, holdRem);
              this.testData.statuses.push({'date':progPtr.time, 'set_pt':0, 'step': 'Complete', 'err_text': 'Projected'}); //ending data point
              // console.log("testData: " + JSON.stringify(this.testData));
            }
          }
        }
      } else {
        //kiln is not firing so show program profile
        this.testData = this.defaultTestDataStructure;
        this.testData.program = this.kiln.program;
        this.testData.config = this.kiln.config;
        this.testData.statuses = []; //clear the status array
        this.testData.statuses.push({'date':moment(progPtr.time).toISOString(), 'set_pt':progPtr.temp}); //starting data point
        //get the transition points from the program
        this.addProgramSteps(this.testData, this.testData.program.steps, 0, progPtr, {});
        this.testData.statuses.push({'date':moment(progPtr.time).toISOString(), 'set_pt':0, 'step': 'Complete', 'err_text': 'Projected'}); //ending data point
        // console.log("2nd testData: " + JSON.stringify(this.testData));
      }
    } else {
      // console.log(`serial number not in external id: ${data.external_id.includes(this.kiln.serial_number)}`);
    }
    // console.log("testData: " + JSON.stringify(this.testData));

    this.retrievingData = false;
  }

  public addProgramSteps(data: any, steps, startStage, progPtr, holdRem){
    //Add future program set points to data.statuses array starting at progTime and progTemp
    var i;
    var stepStr;

    if(!steps) { steps = 0; }
    for (i=startStage; i<(steps.length*2); i++){
      if (i%2==0){
        //even stage is the ramp
        progPtr.time = moment(progPtr.time).add((Math.abs(steps[i/2].t - progPtr.temp) / steps[i/2].rt), 'hours');
        progPtr.temp = steps[i/2].t;
        stepStr = "Ramp " + (Math.floor(i/2)+1) + " of " + steps.length;
        data.statuses.push({'date':moment(progPtr.time).toISOString(), 'set_pt':progPtr.temp, 'step': stepStr, 'err_text': 'Projected'});
      } else {
        //odd stage is the hold
        if (i==startStage && (holdRem.hr || holdRem.min)){
          //starting with hold in firing so use remaining hold time
          progPtr.time = moment(progPtr.time).add(holdRem.hr,'hours').add(holdRem.min,'minutes');
        } else {
          //otherwise use program hold time
          progPtr.time = moment(progPtr.time).add(steps[(i-1)/2].hr,'hours').add(steps[(i-1)/2].mn,'minutes');
        }
        stepStr = "Hold " + (Math.floor(i/2)+1) + " of " + steps.length;
        data.statuses.push({'date':moment(progPtr.time).toISOString(), 'set_pt':progPtr.temp, 'step': stepStr, 'err_text': 'Projected'});
      }
      // console.log('add point: ' + JSON.stringify(data.statuses[data.statuses.length - 1]));
    }
  }

  onGetKilnDataError(error) {
    this.retrievingData = false;
    this.error = true;
    // this.notConnected = true;
    if(this.notConnected || !this.kiln.program || this.kiln.program && (!this.kiln.program.steps || (this.kiln.program.steps && this.kiln.program.steps.length === 0))) {
      this.testData = undefined;
    } else {
      var startTime = moment();
      startTime.set({hour:0,minute:0,second:0,millisecond:0})
      // const startTime = moment.now();
      var progTime = moment(startTime);
      var progTemp = 70;
      var progPtr = {time: progTime, temp: progTemp};
      this.testData = {"statuses": [], "config": this.kiln.config, "program": {steps: []}};
      this.addProgramSteps(this.testData, this.kiln.program.steps, 0, progPtr, {});
    }
  }

  public isNotConnected(updatedAt) {
    if(updatedAt) {
      var timePassed = moment.duration(moment().diff(moment(updatedAt)));
      var fiveMinutes = moment.duration(5, 'minutes');
      return timePassed > fiveMinutes;
    } else {
      return true;
    }
  }
}
