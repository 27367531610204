<h4 class="title">Login As <ion-icon name="close" (click)="dismiss()"></ion-icon></h4>
<div id="login-as-popup">
  <div *ngIf="retrieving">
    <ion-spinner name="bubbles"></ion-spinner>
  </div>
  <div class="subtitle">
    <h6>Users</h6>
    <button *ngIf="!searching" (click)="startSearch()" class="search"><ion-icon name="search"></ion-icon></button>
    <form [formGroup]="searchForm" *ngIf="searching" (ngSubmit)="filter(searchForm.value)">
      <input *ngIf="searching" formControlName="searchTerm" autofocus="true" #searchTermId
        (keyup.esc)="cancelSearch()" type="text" class="search-input" placeholder="Search"/>
      <button type="button" (click)="cancelSearch()"><ion-icon name="close"></ion-icon></button>
      <button type="submit" (click)="filter(searchForm.value)"><ion-icon name="search"></ion-icon></button>
    </form>
  </div>
  <ion-list *ngIf="!retrieving" id="all-users">
    <ion-item *ngIf="getUsersToShow() && getUsersToShow().length === 0 || !users">No users retrieved.</ion-item>
    <ion-item-group *ngFor="let company of getUsersToShow()">
      <ion-item-divider color="secondary">
        <ion-label>{{company.name || 'No Account'}}</ion-label>
      </ion-item-divider>
      <ion-list>
        <ion-item *ngFor="let user of company.users">
          <ion-label>
            <h2>Contact
              <span *ngIf="user && user.role && (user.role === 'admin')">&nbsp;<ion-icon name="star-outline" *ngIf="user && user.role && (user.role === 'admin')"></ion-icon></span>:&nbsp;
              {{user.first_name}}&nbsp;{{user.last_name}}
            </h2>
            <h3>Email:&nbsp;{{user.email}}</h3>              
            <p>Username:&nbsp;{{user.username}}</p>                
          </ion-label>              
          <ion-button *ngIf="company.account_id" (click)="loginAs(user, company.account_id)">Login As</ion-button>
          <ion-button *ngIf="!company.name || !company.account_id" (click)="showUserNotAssociatedToAccountMessage()">Login As</ion-button>          
        </ion-item>
      </ion-list>
    </ion-item-group>
  </ion-list>
</div>
<div class="buttons">
  <button type="button" class="close" (click)="dismiss()">Close</button>
  <button type="submit" class="primary" (click)="exitLoginAs()">Re-Connect As Me</button>
</div>
