<form>
  <ion-list id="user-list" *ngIf="!small_screen">
    <ion-item-divider sticky="true" color="dark" class="header">
      <ion-label slot="start"><h4>User List</h4></ion-label>
      <ion-button size="small" color="tertiary" *ngIf="!gettingUserData && canAdminister" (click)="addUser()"><ion-icon name="create-outline" class="icon" ></ion-icon>Add User</ion-button> 
    </ion-item-divider>

     <ion-item *ngIf="gettingUserData"><ion-progress-bar type="indeterminate"></ion-progress-bar> </ion-item>
     <ion-item-divider sticky="true" color="medium" class="type" *ngIf="canAdminister" (click)="hideAdmins = !hideAdmins">
      <ion-icon *ngIf="!hideAdmins" name="remove"></ion-icon>
      <ion-icon *ngIf="hideAdmins" name="add"></ion-icon>
      <span>Administrator</span>
     </ion-item-divider>
     <!-- <ion-item > -->
       <ion-list class="sublist" *ngIf="userInfo.admin && userInfo.admin.length > 0 && !gettingUserData && canAdminister"
          [class.display-none]="hideAdmins" [class.three-categories]="userInfo.admin.length > 0 && userInfo.basic.length > 0 && userInfo.readOnly.length > 0">
         <ion-item *ngFor="let admin of userInfo.admin; index as i" (click)="selectUser(admin, i)"
          [class.selected]="selected_user && ((admin.email ? admin.email === selected_user.email : admin.username === selected_user.username) && selected_index === i)">
            <ion-label>
              <h3 class="username">{{admin.email || admin.username}}{{!admin.email ? '(username)' : ''}}<sup *ngIf="admin.is_owner">*</sup></h3>
              <p class="first_last">{{admin.first_name}}&nbsp;{{admin.last_name}}</p>
            </ion-label>
         </ion-item>
       </ion-list>
     <!-- </ion-item> -->

     <ion-item-divider sticky="true" color="medium" class="type" *ngIf="userInfo.basic && userInfo.basic.length > 0" (click)="hideBasics = !hideBasics">
      <ion-icon *ngIf="!hideBasics" name="remove"></ion-icon>
      <ion-icon *ngIf="hideBasics" name="add"></ion-icon>
      <span>Basic User</span>
     </ion-item-divider>
     <!-- <ion-item > -->
       <ion-list class="sublist" *ngIf="userInfo.basic && userInfo.basic.length > 0 && !gettingUserData && (basicUsers().length > 0 || canAdminister)"
        [class.display-none]="hideBasics" [class.three-categories]="userInfo.admin.length > 0 && userInfo.basic.length > 0 && userInfo.readOnly.length > 0" >
         <ion-item *ngFor="let user of userInfo.basic; index as i" (click)="selectUser(user, i)"
          [class.selected]="selected_user && ((user.email ? user.email === selected_user.email : user.username === selected_user.username) && selected_index === i)">
            <ion-label>
              <h3 class="username">{{user.email || user.username}}{{!user.email ? '(username)' : ''}}<sup *ngIf="user.is_owner">*</sup></h3>
              <p class="first_last">{{user.first_name}}&nbsp;{{user.last_name}}</p>
            </ion-label>
         </ion-item>
       </ion-list>
     <!-- </ion-item> -->

     <ion-item-divider sticky="true" color="medium" class="type" *ngIf="userInfo.readOnly && userInfo.readOnly.length > 0" (click)="hideReadOnly = !hideReadOnly">
      <ion-icon *ngIf="!hideReadOnly" name="remove"></ion-icon>
      <ion-icon *ngIf="hideReadOnly" name="add"></ion-icon>
      <span>ReadOnly User</span>
     </ion-item-divider>
     <!-- <ion-item > -->
       <ion-list class="sublist" *ngIf="userInfo.readOnly && userInfo.readOnly.length > 0 && !gettingUserData && readOnlyUsers().length > 0"
        [class.display-none]="hideReadOnly" [class.three-categories]="userInfo.admin.length > 0 && userInfo.basic.length > 0 && userInfo.readOnly.length > 0">
         <ion-item *ngFor="let user of userInfo.readOnly; index as i" (click)="selectUser(user, i)"
          [class.selected]="selected_user && ((user.email ? user.email === selected_user.email : user.username === selected_user.username) && selected_index === i)">
            <ion-label>
              <h3 class="username">{{user.email || user.username}}{{!user.email ? '(username)' : ''}}</h3>
              <p class="first_last">{{user.first_name}}&nbsp;{{user.last_name}}</p>
            </ion-label>
         </ion-item>
       </ion-list>
     <!-- </ion-item> -->
  </ion-list>

  <ion-item *ngIf="small_screen" class="selecting-user-small-screen" lines="no-lines">
    <ion-label *ngIf="selected_user">Selected: {{selected_user.email}}</ion-label>
    <ion-button slot='end' (click)="showPicker()">Choose</ion-button>
  </ion-item>
  <ion-item *ngIf="small_screen" class="selecting-user-small-screen">
    <ion-button slot='end' (click)="addUser()">Add User</ion-button>
  </ion-item>
  <div id="user-account-details">
    <ion-item lines="none" class="header">
      <ion-segment class='tabs' [value]="selected_tab">
        <ion-segment-button value="details" (click)="selected_tab = 'details'" [class.selected]="selected_tab === 'details'">
            <ion-label class="item-text-wrap">User Details</ion-label>
        </ion-segment-button>
        <ion-segment-button value="security" (click)="selected_tab = 'security'" *ngIf="selected_user && canAdminister"
          [class.selected]="selected_tab === 'security'">
            <ion-label>Security</ion-label>
        </ion-segment-button>
        <ion-segment-button value="phone" (click)="selected_tab = 'phone'" *ngIf="selected_user"
          [class.selected]="selected_tab === 'phone'">
            <ion-label>Phone</ion-label>
        </ion-segment-button>
        <!--  <ion-item (click)="selected_tab = 'email'" *ngIf="selected_user"
          [class.selected]="selected_tab === 'email'">
          Email
        </ion-item> -->        
      </ion-segment>
      <ion-button size="small" *ngIf="!gettingUserData && canAdminister && selected_user" (click)="removeUser()" class="ion-text-wrap" expand="block">
        <ion-icon name="trash-outline"></ion-icon>
        <ion-label>Delete User</ion-label>
      </ion-button>
    </ion-item>

    <div class="gettingData" *ngIf="gettingUserData"><ion-progress-bar type="indeterminate"></ion-progress-bar></div>
    <form class="main usersForm" *ngIf="!gettingUserData && selected_tab === 'details'" [formGroup]="usersForm">
      <!-- <div>
         <ion-label>Username </ion-label>
        <input type="text" *ngIf="selected_user" disabled readonly value="{{selected_user && selected_user.username}}"/>
        <input type="text" *ngIf="!selected_user"
          [class.invalid]="!selected_user && (!usersForm.controls.username.valid && usersForm.controls.username.touched)"
          formControlName="username" />
      </div> -->

      <ion-item line="nones">
        <ion-label class="default-email">
          <h4>Default Email Address<sup>*</sup></h4>          
        </ion-label>
        <ion-input class="ion-text-right"  formControlName="email" type="email" placeholder="Email"></ion-input>
      </ion-item>

      <ion-item *ngIf="selected_user && selected_user.email && (!selected_user.email_verified && !selected_user.email_verification_code)"
        class="small">
        <ion-button slot="end" (click)="verifyEmail()" class="align-top">Send Verification</ion-button>
      </ion-item>
      <ion-item lines='none'  *ngIf="selected_user && !selected_user.email_verified && selected_user.email_verification_code" class="email-verification">
        <ion-input class="ion-text-right" formControlName="verification_code" type="text" placeholder="Verification Code"></ion-input>
        <ion-label slot="end">
          <ion-button (click)="confirmEmailVerification()" type="button">Complete Verification</ion-button>
          <ion-button (click)="verifyEmail()" type="button" class="align-top">Re-Send Code</ion-button>
        </ion-label>
      </ion-item>
      <ion-item *ngIf="selected_user && selected_user.email_verified">
         <ion-label>Your email is verified for alerts. </ion-label>
      </ion-item>

      <!-- <div *ngIf="canAdminister">
         <ion-label>User Status <span *ngIf="usersForm.controls.enabled.errors && usersForm.controls.enabled.errors.required" class="invalid"> This field is required</span> </ion-label>
        <div class="radio-button-group" [class.invalid]="usersForm.controls.enabled.errors && usersForm.controls.enabled.errors.required">
           <ion-label [class.current]="usersForm.value.enabled === true">Enabled:
            <input type="radio" formControlName="enabled"
              [value]="true" [checked]="usersForm.value.enabled">
           </ion-label>

           <ion-label [class.current]="usersForm.value.enabled === false">Disabled:
            <input type="radio" formControlName="enabled"
              [value]="false" [checked]="usersForm.value.enabled === false">
            </ion-label>
         </div>
      </div> -->

      <ion-item *ngIf="canAdminister">
         <ion-label>User Type<sup>*</sup> </ion-label>
        <ion-select formControlName="type" [class.invalid]="!selected_user && (!usersForm.controls.type.valid && usersForm.controls.type.touched)">
          <ion-select-option value="admin">Administrator</ion-select-option>
          <ion-select-option value="basic">Basic User</ion-select-option>
          <ion-select-option value="readonly">Readonly User</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
         <ion-label>First Name<sup>*</sup> </ion-label>
        <ion-input class="ion-text-right" type="text" [class.required]="!selected_user && (!usersForm.controls.first_name.valid && usersForm.controls.first_name.touched)"
          formControlName="first_name"></ion-input>
      </ion-item>

      <ion-item>
         <ion-label>Last Name<sup>*</sup> </ion-label>
        <ion-input class="ion-text-right" type="text" [class.invalid]="!selected_user && (!usersForm.controls.last_name.valid && usersForm.controls.last_name.touched)"
          formControlName="last_name"></ion-input>
      </ion-item>
    </form>

    <form class="main" id="user-security-modal-tab" *ngIf="selected_tab === 'security' && canAdminister && kilnSecurityForm" [formGroup]="kilnSecurityForm">
      <div class="shrink">
         <ion-label>Allow User to See: </ion-label>
        <ion-select (change)="allowUsersChange($event)" formControlName="allowUsersOverallPolicy">
          <ion-select-option value="all">All Kilns</ion-select-option>
          <ion-select-option value="selectNetworkDevices">All Kilns at Selected Location</ion-select-option>
          <ion-select-option value="selectKilns">Select Kilns</ion-select-option>
        </ion-select>
      </div>
      <ng-container formArrayName="network_devices">
        <div class="kiln_and_network_device_options" [class.disabled]="kilnSecurityForm.value.allowUsersOverallPolicy === 'all'">
           <ul>
             <li *ngFor="let network_dev of kilnSecurityFormNetworkDevices(); index as network_index" [formGroupName]="network_index">
               <label *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy !== 'selectNetworkDevices' || (network_dev.value.id !== '-1' && kilnSecurityForm.value.allowUsersOverallPolicy === 'selectNetworkDevices')">
                <input *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy === 'all' && kilnSecurityForm.value.allowUsersOverallPolicy !== 'selectKilns'" formControlName="checked"
                  type="checkbox" [attr.disabled]="true" [attr.readonly]='true' />
                <input *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy !== 'all' && kilnSecurityForm.value.allowUsersOverallPolicy !== 'selectKilns'" formControlName="checked"
                  type="checkbox" />
                <input formControlName="name" type="text" disabled='true' readonly/>
               </label>

               <ul *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy !== 'selectNetworkDevices' && getKilnsForNetworkDevice(network_index) && getKilnsForNetworkDevice(network_index).length > 0" formArrayName="kilns">
                 <li *ngFor="let kiln of getKilnsForNetworkDevice(network_index); index as i" [formGroupName]="i">
                   <label>
                    <input *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy === 'all'" formControlName="checked"
                      type="checkbox" [attr.disabled]="true" [attr.readonly]='true' />
                    <input *ngIf="kilnSecurityForm.value.allowUsersOverallPolicy !== 'all'"
                      formControlName="checked" type="checkbox" />
                    <input formControlName="name" type="text" disabled='true' readonly/>
                   </label>
                  </li>
                </ul>
              </li>
            </ul>
        </div>
      </ng-container>
    </form>

    <form id="phone-tab" [formGroup]="phonesForm" class="main" *ngIf="selected_tab === 'phone'">
      <div class="phone-form primary">
        <ion-list>
          <ion-item class="row" [class.invalid]="phonesForm.controls.primary_number.errors && phonesForm.controls.primary_number.errors.invalidNumber">
             <ion-label>Primary Phone Number: </ion-label>
            <ion-input class="ion-text-right" formControlName="primary_number" type="tel" placeholder="1-XXX-XXX-XXXX"></ion-input>
          </ion-item>
          <ion-item class="row" [class.invalid]="!selected_user && (!phonesForm.controls.primary_type.valid && phonesForm.controls.primary_type.touched)">
             <ion-label>Type: </ion-label>
            <ion-select formControlName="primary_type">
              <ion-select-option *ngFor="let opt of phone_type_options" [value]="opt">{{opt}}</ion-select-option>
            </ion-select>
          </ion-item>

          <ion-item lines="none" class="row checkbox" [class.invalid]="!selected_user && (!phonesForm.controls.primary_alerts_enabled.valid && phonesForm.controls.primary_alerts_enabled.touched)">
             <ion-label>Enable for Alerts: </ion-label>
            <ion-checkbox formControlName="primary_alerts_enabled"></ion-checkbox>
          </ion-item>
          <ion-item *ngIf="selected_user.primary_phone ? (selected_user.primary_phone.call_number && !selected_user.primary_phone.verified && !selected_user.primary_phone.verification_code): false">
            <ion-button slot="end" class="phone-verify row" 
              (click)="verifyPhone(false)" class="align-top">Send Verification</ion-button>
          </ion-item>
          <ion-item class="phone-verification" *ngIf="selected_user.primary_phone ? selected_user.primary_phone.verification_code : false">
            <ion-input class="ion-text-right" formControlName="verification_code" placeholder="Verification Code" type="text"></ion-input>
            <ion-button (click)="confirmPhoneVerification(false)" class="align-top">Complete Verification</ion-button>
            <ion-button (click)="verifyPhone(false)" class="align-top">Re-send Verification</ion-button>
          </ion-item>          
          <ion-item class='row' *ngIf="selected_user.primary_phone && selected_user.primary_phone.call_number">
            <ion-label class="row" *ngIf="selected_user.primary_phone && selected_user.primary_phone.verified">
              <ion-note>Primary phone already verified</ion-note>
            </ion-label>
            <ion-button slot="end" (click)="deletePhone(false)" class="align-top">Delete Phone</ion-button>
          </ion-item>
        </ion-list>
      </div>

      <div class="phone-form">
        <ion-list>
          <ion-item class="row" [class.invalid]="phonesForm.controls.secondary_number.errors && phonesForm.controls.secondary_number.errors.invalidNumber">
             <ion-label>Secondary Phone Number: </ion-label>
            <ion-input class="ion-text-right" formControlName="secondary_number" type="tel" placeholder="1-XXX-XXX-XXXX" ></ion-input>
          </ion-item>

          <ion-item class="row" [class.invalid]="!selected_user && (!phonesForm.controls.secondary_type.valid && phonesForm.controls.secondary_type.touched)">
             <ion-label>Type: </ion-label>
            <ion-select formControlName="secondary_type">
              <ion-select-option *ngFor="let opt of phone_type_options" [value]="opt">{{opt}}</ion-select-option>
            </ion-select>
          </ion-item>
          <ion-item lines="none" class="row checkbox" [class.invalid]="!selected_user && (!phonesForm.controls.secondary_alerts_enabled.valid && phonesForm.controls.secondary_alerts_enabled.touched)">
             <ion-label>Enable for Alerts: </ion-label>
            <ion-checkbox class="ion-text-right" formControlName="secondary_alerts_enabled" type="checkbox" ></ion-checkbox>
          </ion-item>
          <ion-item *ngIf="selected_user.secondary_phone ? (selected_user.secondary_phone.call_number && !selected_user.secondary_phone.verified && !selected_user.secondary_phone.verification_code) : false">
            <ion-button slot="end" class="phone-verify row"
              (click)="verifyPhone(true)" class="align-top">Send Verification</ion-button>
          </ion-item>
          <ion-item class="phone-verification" *ngIf="selected_user.secondary_phone ? selected_user.secondary_phone.verification_code : false">
            <ion-input class="ion-text-right" formControlName="verification_code" placeholder="Verification Code" type="text" ></ion-input>
            <ion-button (click)="confirmPhoneVerification(true)" class="align-top">Complete Verification</ion-button>
            <ion-button (click)="verifyPhone(true)" class="align-top">Re-send Verification></ion-button>
          </ion-item>          
          <ion-item lines="none" class="row" *ngIf="selected_user.secondary_phone && selected_user.secondary_phone.call_number">
            <ion-label class="row" *ngIf="selected_user.secondary_phone && selected_user.secondary_phone.verified">
              <ion-note>Secondary phone already verified</ion-note>
            </ion-label>
            <ion-button slot="end" (click)="deletePhone(true)" class="align-top" type="button">Delete Phone</ion-button>
          </ion-item>
        </ion-list>
      </div>
    </form>

    <form class="main" *ngIf="selected_tab === 'email'" [formGroup]="emailForm" >
      <ion-list>
        <ion-item>
           <ion-label>Email Address: </ion-label>
          <ion-input class="ion-text-right" formControlName="email" type="email" placeholder="email@a.com"></ion-input>
        </ion-item>

        <ion-button *ngIf="selected_user && (!selected_user.email_verified && !selected_user.email_verification_code)" (click)="verifyEmail()" type="button" class="align-top">Send Verification</ion-button>
        <ion-item *ngIf="selected_user && !selected_user.email_verified && selected_user.email_verification_code" class="vertical">
          <ion-input class="ion-text-right" formControlName="verification_code" type="text"></ion-input>
          <ion-button (click)="confirmEmailVerification()" class="align-top" type="button">Complete Verification</ion-button>
        </ion-item>
        <ion-note *ngIf="selected_user && selected_user.email_verified">
          Your email is verified for alerts.
        </ion-note>
      </ion-list>
    </form>
  </div>
</form>
