import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { environment } from '../../environments/environment';
import { UserService } from 'src/app/services/user.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class PaymentService {
    private userApiUrl = environment.claimApi;
    constructor(private http: HttpClient, private userService: UserService) {}
    private nonce;

    public getToken(account_id): Promise<any> {
        var optionsUrl = `${this.userApiUrl}/account/${account_id}/payment/token`;
        return this.http.get(optionsUrl, this.headersWithAuthToken(false)).toPromise();
    }

    public getPaymentInfo(account_id): Promise<any> {
      var optionsUrl = `${this.userApiUrl}/account/${account_id}/payment/info`;
      return this.http.get(optionsUrl, this.headersWithAuthToken(false)).toPromise();
    }

    public update(account_id, nonce): Promise<any> {
      var optionsUrl = `${this.userApiUrl}/account/${account_id}/payment/info`;
      this.nonce = nonce;      
      return this.http.put(optionsUrl, {nonce: nonce}, this.headersWithAuthToken(false)).toPromise();
    }

    public getNonce() {
      return this.nonce;
    }

    public setNonce(nonce) {
      this.nonce = nonce;
    }

    private headersWithAuthToken(includeAppNameToken = true) {
      const loginData = this.userService.getLoginData();
  
      if(loginData) {
        var headers = {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': loginData.token
        };
  
        if(includeAppNameToken) { headers['x-app-name-token'] = 'kiln-link'; }
        const httpOptions = {
          headers: new HttpHeaders(headers)
        }
  
        return httpOptions;
      } 
    }
}