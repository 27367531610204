import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/services/user.service';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class MaintenanceNoteService {
  public elasticbeanstalk_uri = environment.claimApi;
  public local_uri = "http://localhost:5000";
  private noteUrl = environment.claimApi;

  constructor(public http: HttpClient,
    public userService: UserService
  ) {
    this.noteUrl = this.elasticbeanstalk_uri;
  }

  list(kiln_id): Promise<any> {
    var url = `${this.noteUrl}/premium/kilns/${kiln_id}/notes`;
    return this.http.get(url, this.headersWithAuthToken(false, 'getUser:failure'))
        .toPromise()
  }

  update(kiln_id, values): Promise<any> {
    var url = `${this.noteUrl}/premium/kilns/${kiln_id}/notes/${values.note_id}`;
    return this.http.put(url, {
      technician: values.technician,
      type: values.type,
      note_date: values.date,
      notes: values.notes
    }, this.headersWithAuthToken(false, 'getUser:failure'))
        .toPromise()
  }

  create(kiln_id, values): Promise<any> {
    var url = `${this.noteUrl}/premium/kilns/${kiln_id}/notes`;
    return this.http.post(url, {
      technician: values.technician,
      type: values.type,
      note_date: values.date,
      notes: values.notes
    }, this.headersWithAuthToken(false, 'getUser:failure'))
        .toPromise()
  }

  delete(kiln_id, note_id): Promise<any> {
    var url = `${this.noteUrl}/premium/kilns/${kiln_id}/notes/${note_id}`;
    return this.http.delete(url, this.headersWithAuthToken(false, 'getUser:failure'))
        .toPromise()
  }

  private headersWithAuthToken(includeAppNameToken = true, failureEvent = '') {
    const loginData = this.userService.getLoginData();

    if(loginData) {
      var headers = {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': loginData.token
      };

      if(includeAppNameToken) { headers['x-app-name-token'] = 'kiln-link'; }
      const httpOptions = {
        headers: new HttpHeaders(headers)
      }

      return httpOptions;
    } else {
      console.log("no login data?")
    }
  }
}
