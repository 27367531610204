import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { UserService } from 'src/app/services/user.service';
import { ToastController, AlertController, PickerController, Platform } from '@ionic/angular';
import { Options } from './manage-network-options'
import moment from 'moment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-manage-network',
  templateUrl: './manage-network.component.html',
  styleUrls: ['./manage-network.component.scss'],
})
export class ManageNetworkComponent implements OnInit {
  @Input() onDismiss;
  public selected_tab = 'kilns';

  public kilns = [];
  public selectedKiln = null;

  public network_devices = [];
  public selectedNetworkDevice = null;

  public manageKilnForm: UntypedFormGroup;
  public manageNetworkDeviceForm: UntypedFormGroup;

  public updating;
  public loginData;
  public hasElevatedPermissions;
  public kilnLimitMet;
  public new_kiln = false;
  public kiln_limit = 0;

  private accountInformation;
  public canAdminister;
  public retrievingInformation;

  public model_options = Options.all;
  public phase_options = ["Single Phase", "Three Phase"];
  public voltage_options = ["115V", "208V", "240V"];
  @Input() small_screen = false;

  constructor(public accountService: AccountService,
    public userService: UserService,
    public toastCtrl: ToastController,
    private alertController: AlertController,
    private pickerController: PickerController,
    private platform: Platform,
    private router: Router
  ) {
      let login_data = this.userService.getLoginData();
      this.loginData = (login_data.hasOwnProperty('loggedInAs') && login_data.loggedInAs) ? login_data.loggedInAs : login_data;
      this.retrievingInformation = true;

      this.hasElevatedPermissions = this.userService.hasElevatedPermissions();
      this.accountService.getAccountInformation().then((response) => {
        this.accountInformation = response;
        this.setUpCanAdminister(response);
        this.kiln_limit = response ? (response.general && response.general.kiln_limit) : 0;
        if(this.kilns) {
          this.kilnLimitMet = (this.kilns.length >= this.kiln_limit);
        }

        let current_user_permissions_for_account = response.users ? response.users.find((u) => { return u.email === this.loginData.email }) : {type: 'basic'}
        this.hasElevatedPermissions = current_user_permissions_for_account && current_user_permissions_for_account.type && current_user_permissions_for_account.type === 'admin';
      })
      let accountInfo = this.accountService.localCacheAccountInfo();
      this.kiln_limit = accountInfo ? (accountInfo.general && accountInfo.general.kiln_limit) : 0;
      this.setSmallScreen();
  }

  ngOnInit() {
    this.getAccountDevices();
    this.hasElevatedPermissions = this.userService.hasElevatedPermissions();
    this.updating = false;
    this.new_kiln = false;

    let accountInfo = this.accountService.localCacheAccountInfo();
    this.kiln_limit = accountInfo ? (accountInfo.general && accountInfo.general.kiln_limit) : 0;
    this.setSmallScreen();
  }

  setSmallScreen() {
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb')) : false;
    this.small_screen = this.platform.width() < 875 || is_device;
  }

  private getAccountDevices() {
    this.accountService.getDevicesForAccount()
        .then((data) => {
          this.kilns = data.kilns.sort((a, b) => (a.name > b.name) ? 1 : -1);
          // console.log(this.kilns)
          this.kilnLimitMet = (this.kilns.length >= this.kiln_limit);
          // console.log(`kiln limit info: current - ${this.kilns.length}, allowed - ${this.kiln_limit}`);
          this.network_devices = data.network_devices.filter((nd) => { return nd.id !== '-1'}).sort((a, b) => (a.name > b.name) ? 1 : -1);
          this.selectKiln(this.kilns[0]);
          this.selecteNetworkDevice(this.network_devices[0]);
          this.retrievingInformation = false;
        }).catch( (error) => { this.retrievingInformation = false; console.log(error); })
  }

  selectKiln(kiln) {
    this.selectedKiln = kiln;
    let name = new UntypedFormControl(kiln ? kiln.name : 'Please enter a kiln name', [Validators.required]);
    let model = new UntypedFormControl(kiln ? kiln.model : 'Model', []);
    let voltage = new UntypedFormControl(kiln ? kiln.voltage : 'Voltage', []);
    let phase = new UntypedFormControl(kiln ? kiln.phase : 'Phase', []);
    let serial_number = new UntypedFormControl(kiln ? kiln.serial_number : 'Serial No.', []);
    let kiln_id = new UntypedFormControl(kiln ? kiln.kiln_id : 'Kiln ID', [Validators.min(1)]);
    let install_date = new UntypedFormControl((kiln && kiln.install_date) ? moment(kiln.install_date).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD"), []);
    let nd = new UntypedFormControl(kiln && (kiln.network_device_id), []);
    let kiln_serial_number = new UntypedFormControl(kiln && (kiln.kiln_serial_number), []);

    this.manageKilnForm = new UntypedFormGroup({
      name: name,
      model: model,
      voltage: voltage,
      phase: phase,
      serial_number: serial_number,
      kiln_id: kiln_id,
      install_date: install_date,
      network_device: nd,
      is_kmt: new UntypedFormControl(kiln ? (kiln.is_kmt || false) : false, [Validators.required]),
      mac_address: new UntypedFormControl(kiln ? kiln.mac_address : "", []),
      external_id: new UntypedFormControl(kiln ? kiln.id : null),
      kiln_serial_number: kiln_serial_number
    });
  }

  selecteNetworkDevice(network_device) {
    this.new_kiln = false;
    this.selectedNetworkDevice = network_device;
    let name = new UntypedFormControl(network_device ? network_device.name : 'Please enter a device name', [Validators.required]);
    let mac_address = new UntypedFormControl(network_device ? network_device.mac_address : 'Mac Address', [Validators.required]);
    let model = new UntypedFormControl(network_device ? network_device.model : 'Model', [Validators.required]);
    let serial_number = new UntypedFormControl(network_device ? network_device.serial_number : 'Serial Number', [Validators.required]);
    let device_manufacturer = new UntypedFormControl(network_device ? network_device.device_manufacturer : 'Device Manufacturer', [Validators.required]);

    this.manageNetworkDeviceForm = new UntypedFormGroup({
      name: name,
      mac_address: mac_address,
      model: model,
      serial_number: serial_number,
      device_manufacturer: device_manufacturer
    });
  }

  submitKiln(formValue) {
    this.updating = true;
    let serial = this.new_kiln ? undefined : formValue.external_id;
    this.accountService.upsertKilnForAccount(serial, {...{type: "kiln"}, ...formValue})
        .then((response) => {
          this.userService.setCurrentAccount(this.accountInformation.general.external_id);
          this.upsertSuccess(`Kiln was ${this.new_kiln ? 'created' : 'updated'}`);
        })
        .catch((error) => {
          this.upsertFailed(`Could not ${this.new_kiln ? 'create' : 'update'} the kiln`);
        })
  }

  submitNetworkDevice(formValue) {
    this.updating = true;
    this.accountService.upsertNetworkDeviceForAccount(formValue.serial_number, {...{type: "network_device"}, ...formValue})
        .then((response) => {
          this.userService.setCurrentAccount(this.accountInformation.general.external_id);
          this.upsertSuccess(`Network device was ${this.new_kiln ? 'created' : 'updated'}`);
        })
        .catch((error) => {
          this.upsertFailed(`Could not ${this.new_kiln ? 'create' : 'update'} the network device.`);
        })
  }

  submit() {
    // if(this.selected_tab === 'network') {
    //   this.submitNetworkDevice(this.manageNetworkDeviceForm.value);
    // } else {
      this.submitKiln(this.manageKilnForm.value);
    // }
  }

  async upsertSuccess(message) {
    this.new_kiln = false;
    this.getAccountDevices();
    this.updating = false;
    this.showMessage(message || "Update successful");
  }

  async upsertFailed(error) {
    this.updating = false;
    this.showMessage(error || "Update failed");
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'middle',
      cssClass: 'success'
    });

    await toast.present();
  }

  addKiln() {
    this.new_kiln = true;
    this.manageKilnForm = new UntypedFormGroup({
      name: new UntypedFormControl("", [Validators.required]),
      model: new UntypedFormControl("", [Validators.required]),
      voltage: new UntypedFormControl("", []),
      phase: new UntypedFormControl("", []),
      serial_number: new UntypedFormControl("", []),
      kiln_id: new UntypedFormControl("", [Validators.required, Validators.min(1)]),
      install_date: new UntypedFormControl(moment().format("YYYY-MM-DD"), []),
      network_device: new UntypedFormControl("", []),
      is_kmt: new UntypedFormControl(false, [Validators.required]),
      mac_address: new UntypedFormControl("", []),
      kiln_serial_number: new UntypedFormControl("", [])
    });
  }

  async removeKiln() {
    const alert = await this.alertController.create({
      header: 'Delete confirmation',
      message: `Are you sure you want to remove the kiln, ${this.selectedKiln.name}, from this account? This action cannot be reversed.`,
      buttons: [
        {
          text: 'Yes, Delete',
          role: 'delete',
          cssClass: 'primary',
          handler: (data) => {
            this.accountService.unlinkNetworkDevice(this.accountInformation.id, {id: this.selectedKiln.id})
                .then((response) => {
                  this.userService.setCurrentAccount(this.accountInformation.general.external_id);
                  this.showMessage("The device was removed from your account.");
                  this.getAccountDevices();
                  this.onDismiss();
                })
                .catch((response) => { this.upsertFailed("Could not remove the kiln. Please try again."); })
          }
        }, {
          text: "No, Cancel",
          cssClass: 'secondary',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  addNetworkDevice() {}

  setUpCanAdminister(data) {
    if(this.accountInformation && this.accountInformation.users && this.loginData) {
      let loggedInUserPermissions = this.accountInformation.users.find((u) => {
        return u.email ? (u.email === this.loginData.email) : (u.username === this.loginData.username)
      })

      this.canAdminister = loggedInUserPermissions ? (loggedInUserPermissions.type === 'admin') : false;
    }
  }

  getNdName(nd) {
    return nd.name ? nd.name : `Name Unavailable (MAC: ${nd.mac_address})`
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        if(this.selected_tab.includes('kilns')) {
          this.selectKiln(selection.devices.value);
        } else {
          this.selecteNetworkDevice(selection.devices.value);
        }        
      },
    },
  ];

  async showPicker() {
    let options = []
    if(this.selected_tab.includes('kilns')) {
      options = this.kilns.map((k) => { return {text: k.name, value: k}})
    } else {
      options = this.network_devices.map((nd) => { return {text: nd.name, value: nd}})
    }        
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'devices',
        options: options
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }

  goBack() {
    this.router.navigate(["/kiln-list"]);
  }
}
