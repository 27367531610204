import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {BehaviorSubject, Observable, timer} from "rxjs";
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class UserService {
  private claimUrl = environment.claimApi;
  private controllerUrl = environment.kilnApi;
  public userLoginData;
  public loggedInAsUserLoginData;
  public isLoggenIn = false;
  private updateUserInfoTimer: any;
  public updateTime = 100;
  private loginAs;
  public loginAsInformation = new BehaviorSubject<any>({});
  public loginStatus = new BehaviorSubject<any>({});
  public storageUpdate = new BehaviorSubject<any>({});
  public currentAccountUpdate = new BehaviorSubject<any>({})
  private redirect;

  constructor(
    public http: HttpClient,
    private storageService: StorageService
    ) {
    this.startTimerSecond();
  }

  public startTimerSecond() { // This timer is used to flash messages and as the time base for the update timer
    if(!this.updateUserInfoTimer) {
      let TimerSecond = timer(10000, 100000);
      this.updateUserInfoTimer = TimerSecond.subscribe(t => this.onTickTimerSecond(t));
    }
  }

  public killTimerSecond() {
    if(this.updateUserInfoTimer) {
      this.updateUserInfoTimer.unsubscribe();
    }
  }

  public onTickTimerSecond(tick) {
    if(this.LoggedIn()) {
      this.updateTime--;
      if (this.updateTime <= 0) {
        this.updateLoginData()
      }
    }
  }

  public setTimerSecond(seconds) { this.updateTime = seconds; }

  public addKilnSuccess(data) {
    var id = data.serial_number ? data.serial_number : data.external_id;
    var already_has_kiln = this.userLoginData.kiln_info.some((kiln) => { return kiln.id == id});
    if(!already_has_kiln) {
      this.userLoginData.kiln_info.push({id: id, name: data.name});
      // console.log(this.userLoginData.kiln_info);
    }
  }

  public unclaim(kilnId, unclaim_for_user) {
    if(!unclaim_for_user || this.userLoginData.email == unclaim_for_user) {
      this.userLoginData.kiln_info = this.userLoginData.kiln_info.filter((kiln) => { return kiln.id != kilnId});
    }
  }

  public getStoredEmail() {
    this.storageService.get('email').then((val) => {
      this.storageUpdate.next({...this.storageUpdate.getValue(), ...{email: val}});
    });
  }

  public getStoredPassword() {
    this.storageService.get('password').then((val) => {
      this.storageUpdate.next({...this.storageUpdate.getValue(), ...{email: val}})
    });
  }

  public setStoredUserData(email, password) {
    this.storageService.set('email', email);
    this.storageService.set('password', password);
  }

  public removeStoredUserData() {
    this.storageService.remove('email');
    this.storageService.remove('password');
    this.isLoggenIn = false;
  }

  setLoginAs(user, account_id): Promise<any> {
    this.loginAs = user;
    return this.getLoginAsInformation(user.email, account_id, user.username);
  }

  unsetLoginAs() {
    this.loginAs = undefined;
    this.loggedInAsUserLoginData = undefined;
    this.userLoginData.loggedInAs = undefined;
    delete this.userLoginData.loggedInAs;
    this.loginAsInformation.next(this.loggedInAsUserLoginData)
  }

  public getLoginAsInformation(email: string, account_id?, username?): Promise<any> {
    var loginUrl = this.claimUrl + "/premium/admin/login";
    var data = {email: email && email.trim()}
    if(!email && username) {
      data["username"] = username
    }
    if(account_id && account_id !== '0') { data["account_id"] = account_id }

    return new Promise((resolve, reject) => {
      let loginAsPromise = this.http.post(loginUrl, data, this.headersWithToken())
      loginAsPromise.subscribe(
        res => {
          if(this.loginAs) {
            this.loggedInAsUserLoginData = {...this.loggedInAsUserLoginData, ...res};
            this.loggedInAsUserLoginData.email = email
            if(username) { this.loggedInAsUserLoginData.username = username }
            this.loginAsInformation.next(this.loggedInAsUserLoginData)
          }

          resolve(this.loggedInAsUserLoginData);
        });
    })
  }

  public getUsersAsAdmin(): Promise<any> {
    var loginUrl = this.claimUrl + "/premium/admin/users";
    return this.http.get(loginUrl, this.headersWithToken()).toPromise();
  }

  public adminConfirmUser(email): Promise<any> {
    var confirmationUrl = `${this.claimUrl}/admin/confirm/user`;
    const httpOptions = { headers: this.genericHeaders() }
    let data = {
      email: email
    };

    return this.http.post(confirmationUrl, data, httpOptions).toPromise()
  }

  public searchUser(email) {
    var loginUrl = this.claimUrl + "/admin/user/search";
    return this.http.post(loginUrl, {value: email}, this.headersWithToken()).toPromise();
  }

  public adminGetUser(email) {
    var loginUrl = this.claimUrl + "/admin/user";
    return this.http.post(loginUrl, {email: email}, this.headersWithToken()).toPromise();
  }

  public setTemporaryPassword(email, password) {
    var loginUrl = this.claimUrl + "/admin/user/set";
    return this.http.post(loginUrl, {email: email, temporaryPassword: password}, this.headersWithToken()).toPromise();
  }

  public adminResetPassword(email) {
    var loginUrl = this.claimUrl + "/admin/user/password";
    return this.http.post(loginUrl, {email: email}, this.headersWithToken()).toPromise();
  }

  public adminDeleteUser(email) {
    var loginUrl = this.claimUrl + "/admin/user/delete";
    return this.http.post(loginUrl, {email: email}, this.headersWithToken()).toPromise();
  }

  public setRedirect(redirect, fragment) {
    this.redirect = {path: redirect, fragment: fragment};
  }
  public clearRedirect() { this.redirect = null;} 
  public getRedirect() {return this.redirect; }

  public tryLogin(email: string, password: string, username: string = null): Observable<any> {
    if(email !== password) {
      var loginUrl = this.claimUrl + "/users/login";
      let data = {
       "email": email && email.trim(),
       "password": password
      }

      // console.log(`tryLogin, ${!email && username ? 'adding username': 'only email'}`)
      if(!email && username) { data["username"] = username }
      const httpOptions = {
        headers: this.genericHeaders()
      }

      let loginPromise = this.http.post(loginUrl, data, httpOptions)
      loginPromise.subscribe(
          res => {
            this.userLoginData = {...this.userLoginData, ...(res)};
            if (email) {
              this.userLoginData.email = email
            }
            if (this.userLoginData.status = 'ok') {
              this.isLoggenIn = true;
              if (res["newPasswordRequired"]) {
                this.loginStatus.next({...this.userLoginData, ...{newPasswordRequired: true}})
                // this.events.publish('login:newPasswordRequired', this.userLoginData);
              } else if (res["needs_terms_accepted"]) {
                this.loginStatus.next({...this.userLoginData, ...{needsToAgreeToTermsAgain: true}})
              } else {
                let return_response = this.userLoginData;
                if (this.loginAs) {
                  return_response = {...return_response, ...{loggedInAs: this.loggedInAsUserLoginData.email}}
                }
                this.loginStatus.next(return_response)
              }
            } else {
              this.isLoggenIn = false;
            }
          }
      );

      return loginPromise;
    } else {
      return new Observable();
    }
  }
  public updateLoginData(): Promise<any> {
    if(this.userLoginData && this.userLoginData.account) {
      return this.setCurrentAccount(this.userLoginData.account);
    } else {
      return new Promise((resolve, reject) => {
        var loginUrl = this.claimUrl + "/users";
        let updateLoginPromise = this.http.get(loginUrl, this.headersWithToken())
        updateLoginPromise.subscribe(res => {
          console.log(res)
          this.userLoginData = {...this.userLoginData, ...res};
          resolve(this.userLoginData)
        });
      })
    }
  }

  public tryLogOut(token = null): Promise<any> {
    this.unsetLoginAs();
    var logoutUrl = this.claimUrl + "/users/logout";
    var data = {
      "x-access-token": token || this.userLoginData.token
    }

    const httpOptions = {
      headers: this.genericHeaders()
    }

    this.isLoggenIn = false;
    return this.http.post(logoutUrl, data, httpOptions).toPromise()
  }
  public saveToken(token): Promise<any> {
    if(this.isLoggenIn) {
      var loginUrl = this.claimUrl + `/users/token.json?user_email=${this.userLoginData.email}&user_token=${this.userLoginData.token}`;
      var data = {
        "push_token": {
          token: token
        }
      }

      const httpOptions = {
        headers: this.genericHeaders()
      }
      return this.http.post(loginUrl, data, httpOptions).toPromise()
    }
  }

  public acceptTerms(email: string, token: string): Promise<any> {
    // TODO: do we need this for skutt?
    var acceptUrl = this.claimUrl + "/users/terms/accept?user_email=" + email + "&user_token=" + token;

    const httpOptions = {
      headers: this.genericHeaders()
    }

    this.userLoginData.needs_to_accept_new_terms_and_conditions = false;
    return this.http.get(acceptUrl, httpOptions).toPromise();
  }

  public hasElevatedPermissions() {
    if(this.userLoginData) {
      return ["super-admin", "tech"].includes(this.userLoginData.role)
    } else {
      return false;
    }
  }

  public accountExpired() {    
    let expired = false;
    if(this.loginAs) {
      if(this.loggedInAsUserLoginData) {
        expired = this.loggedInAsUserLoginData.premium === false && this.loggedInAsUserLoginData.account !== undefined && (this.loggedInAsUserLoginData.account !== 0 && this.loggedInAsUserLoginData.account !== '0');
      } 
    } else {
      expired = this.userLoginData.premium === false && this.userLoginData.account !== undefined && (this.userLoginData.account !== 0 && this.userLoginData.account !== '0');
    }

    return expired;
  }

  public setInPremiumTrial() {
    this.userLoginData.role = "premium-user"
    this.userLoginData.premium = true;
  }

  public isPremium() {
    if(this.userLoginData && !this.isLoggedInAsAnotherUser()) {
      return ["super-admin", "tech"].includes(this.userLoginData.role) || ("premium-user" === this.userLoginData.role && this.userLoginData.premium);
    } else if(this.isLoggedInAsAnotherUser()) {
      let loginAsVal = this.loginAsInformation.getValue()
      return loginAsVal && loginAsVal.premium;
    } else {
      return false;
    }
  }

  public createForgotPassword(username: string): Promise<any> {
    var newUserUrl = this.claimUrl + '/users/password/forgot';
    const httpOptions = {
      headers: this.genericHeaders()
    }

    return this.http.post(newUserUrl, {email: username.trim() }, httpOptions).toPromise()
  }

  public createNewUser(user): Promise<any> {
    var newUserUrl = `${this.claimUrl}/users/`;
    const httpOptions = {
      headers: this.genericHeaders()
    }
    let data = {
      email: user.email && user.email.trim(),
      password: user.password,
      password_confirmation: user.password
    };

    //createUser:
    return this.http.post(newUserUrl, data, httpOptions).toPromise()
  }

  public createNewKilnLinkPremiumAndCognigotoUser(user): Promise<any> {
    var newUserUrl = `${this.claimUrl}/users/`;
    const httpOptions = {
      headers: this.genericHeaders()
    }
    let data = {
      email: user.email && user.email.trim(),
      password: user.password,
      password_confirmation: user.password
    };

    return this.http.post(newUserUrl, data, httpOptions).toPromise()
  }

  public linkMySkutt(username, email): Promise<any> {
    var emailUpdateUrl = `${this.claimUrl}/users/myskuttlink/${username}`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    }

    return new Promise((resolve, reject) => {
      let linkPromise = this.http.put(emailUpdateUrl, {email: email}, httpOptions)
      linkPromise.subscribe(() => {
        this.storageService.set('myskutt_username', undefined);
        resolve({});
      })
    })
  }

  public checkCognitoEmail(email): Promise<any> {
    var emailUpdateUrl = `${this.claimUrl}/users/myskuttlink`;
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      })
    }
    return this.http.post(emailUpdateUrl, {email: email}, httpOptions).toPromise()
  }

  public headersWithToken() {
    if(this.userLoginData) {
      return {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': this.userLoginData.token
        })
      }
    } else {
      return {
        headers: new HttpHeaders({
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        })
      }
    }
  }

  public isReadonlyUser() {
    let loginData = this.getLoginData();
    let is_logged_in_as = this.isLoggedInAsAnotherUser();
    if(is_logged_in_as) {
      return loginData.loggedInAs && loginData.loggedInAs.user_type_for_account === 'readonly';
    } else {
      return loginData.user_type_for_account ? (loginData.user_type_for_account === 'readonly') : true;
    }
  }

  public premiumUser(user, isUpdate = undefined, account_id): Promise<any> {
    let is_logged_in_as = this.isLoggedInAsAnotherUser();
    let loginInfo = this.getLoginData();
    var newUserUrl = `${this.claimUrl}/account/${account_id}/users/premium${is_logged_in_as && loginInfo && loginInfo.loggedInAs ? `?loginAs=${loginInfo.loggedInAs.email || loginInfo.loggedInAs.username}` : ''}`;

    let data = {
      email: user.email && user.email.trim(),
      enabled: user.enabled,
      type: user.type,
      first_name: user.first_name,
      last_name: user.last_name
    };

    if(isUpdate) {
      data['user_id'] = isUpdate.id
      // console.log(isUpdate)
      return this.http.put(newUserUrl, data, this.headersWithToken()).toPromise()
    } else {
      return this.http.post(newUserUrl, data, this.headersWithToken()).toPromise()
    }
  }

  public updatePhone(selected_user, phones): Promise<any> {
    let username = selected_user.username || selected_user.id;
    var phoneUpdateUrl = `${this.claimUrl}/users/premium/${username}/phone`;
    return this.http.post(phoneUpdateUrl, {phones: phones}, this.headersWithToken()).toPromise()
  }

  public updateEmail(selected_user, email): Promise<any> {
    let username = selected_user.username;
    var emailUpdateUrl = `${this.claimUrl}/users/premium/${username}/email`;
    //premiumEmailUpdate:
    return this.http.post(emailUpdateUrl, {email: email}, this.headersWithToken()).toPromise()
  }

  public updateKilnSecurity(username, account_id, data): Promise<any> {
    var emailUpdateUrl = `${this.claimUrl}/premium/account/${account_id}/kilns/access`;
    return this.http.put(emailUpdateUrl, {...{user_id: username}, ...data}, this.headersWithToken()).toPromise()
  }

  public removeUserFromAccount(account_id, user_id): Promise<any> {
    var removeUserUrl = `${this.claimUrl}/account/${account_id}/users/premium/${user_id}`;
    return this.http.delete(removeUserUrl, this.headersWithToken())
        .toPromise()
        // .then(response => {
        //   console.log("removed user success: " + user_id)
        //   this.events.publish('premiumUserRemove:success', {...{removed: user_id}, ...response})
        // })
        // .catch(error => this.events.publish('premiumUserRemove:error', error));
  }

  public resetPassword(val): Promise<any> {
    var resetUrl = `${this.claimUrl}/users/password/reset`;
    var httpOptions;
    if(this.userLoginData) {
      httpOptions = this.headersWithToken()
    } else {
      httpOptions = {
        headers: this.genericHeaders()
      }
    }

    return this.http.post(resetUrl, {user: val}, httpOptions).toPromise()
  }

  public updatePassword(val, old_password, email, userAttributes): Promise<any> {
    if(!email) {
      email = this.userLoginData.email;
    }

    var updateUrl = `${this.claimUrl}/users/password/update`;
    const httpOptions = {
      headers: this.genericHeaders()
    }
    return this.http.post(updateUrl, {
      email: email,
      old_password: old_password,
      userAttributes: userAttributes,
      password: val
    }, httpOptions).toPromise()
  }

  public confirmUser(val): Promise<any> {
    var newUserUrl = `${this.claimUrl}/users/confirm`;
    const httpOptions = {
      headers: this.genericHeaders()
    }
    return this.http.post(newUserUrl, val, httpOptions).toPromise()
  }

  public LoggedIn() {
    return this.isLoggenIn;
  }

  // public refreshLogin(): Promise<any> {
//     this.storageService.get('email').then((val) => {
//       const savedEmail = val;
//       this.storageService.get('password').then((pwdVal) => {
//         var loginUrl = this.claimUrl + "/users/login";
//         let data = {
//          "email": savedEmail && savedEmail.trim(),
//          "password": pwdVal
//         }
//
//         const httpOptions = { headers: this.genericHeaders() }
//         this.http.post(loginUrl, data, httpOptions)
//           .toPromise()
//           .then(res => this.events.publish('refeshLogin:success'))
//           .catch(error => this.events.publish('refeshLogin:error'));
//       });
//     })
  // }

  public getLoginData() {
    if(!this.userLoginData || !this.userLoginData.token) {
    this.storageService.get('email').then((val) => {
      const savedEmail = val;
      this.storageService.get('email').then((pwdVal) => {
        const savedPwd = val;
        this.tryLogin(savedEmail, savedPwd, '');
      })
    });
    } else {
      let loginDta = this.userLoginData;
      if(this.loginAs) {
        loginDta = {...this.userLoginData, ...{loggedInAs: this.loggedInAsUserLoginData}}
      }

      return loginDta;
    }
  }

  public isLoggedInAsAnotherUser() {
    return this.loginAs !== undefined;
  }

  public nameAndCompany() {
    let loginData = this.getLoginData();
    // console.log(loginData)
    if(loginData && (loginData.company || loginData.name || (this.loggedInAsUserLoginData && (this.loggedInAsUserLoginData.company || this.loggedInAsUserLoginData.name)))) {
      return {
        name: this.loginAs ? this.loggedInAsUserLoginData.name : loginData.name,
        email: this.loginAs ? (this.loggedInAsUserLoginData.email || this.loggedInAsUserLoginData.username) : loginData.email,
        company: this.loginAs ? this.loggedInAsUserLoginData.company : loginData.company
      }
    } else {
      return loginData ? loginData.email : "email unavailable";
    }
  }

  public setCurrentAccount(account_id): Promise<any> {
    let loginData = this.getLoginData();
    return new Promise((resolve, reject) => {      
      if(loginData) {        
        try {
          loginData.account = account_id;
          var loginUrl = this.claimUrl + "/premium/admin/login";
          let data = {email: loginData.email, account_id: account_id}
          let setAccountPromise = this.http.post(loginUrl, data, this.headersWithToken())
          setAccountPromise.subscribe(res => {
            if(res && res.hasOwnProperty('role')) {
              delete res["role"];
            }
            
            this.userLoginData = {...this.userLoginData, ...res};
            // console.log(`set current - ${JSON.stringify(this.userLoginData)}`)
            this.currentAccountUpdate.next(this.isLoggedInAsAnotherUser() ? this.loginAsInformation.getValue() : this.userLoginData)
            resolve(this.userLoginData);
          })
        } catch(e) {
          console.log(e)
        }
      } else { reject({}); }      
    })
  }

  public hasKilnlinkFreeKilns() {
    return this.userLoginData ? this.userLoginData.has_kilnlink_free_kilns : false;
  }

  public isInFreeTrial() {
    return true;
    // return this.userLoginData ? this.userLoginData.free_trial : false;
  }

  public genericHeaders() {
    return new HttpHeaders({
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    });
  }

  public updateKilnName(id, name) {
    this.userLoginData.kiln_info = this.userLoginData.kiln_info.map((k) => {
      if(k.id == id) {
        k.name = name;
      }
      return k;
    })
  }

  public HasAcceptedTerms(){
    if (this.userLoginData.needs_to_accept_new_terms_and_conditions !== undefined){
      return !this.userLoginData.needs_to_accept_new_terms_and_conditions;
    } else {
      return false;
    }
  }

  public getAuthToken() {
    return this.userLoginData ? this.userLoginData.token : null;
  }

  public deleteUser(): Promise<any> {
    var updateUrl = `${this.claimUrl}/users/self`;
    let headers = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': this.userLoginData.token,
        'x-arch-token': this.userLoginData.access
      })
    }
    return this.http.delete(updateUrl, headers).toPromise()
  }
}
