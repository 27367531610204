import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-diagnostic-data-point',
  templateUrl: './diagnostic-data-point.component.html',
  styleUrls: ['./diagnostic-data-point.component.css'],
})
export class DiagnosticDataPointComponent implements OnInit {
  public retrieving_diagnostics = false;
  public diagnostics_data = {
    date: { title: "Last Updated", value: "", label: ""},
    a1: { title: "Amperage 1",  value: "", label: "A" },
    a2: { title: "Amperage 2",  value: "", label: "A" },
    a3: { title: "Amperage 3",  value: "", label: "A" },
    nl: { title: "No Load",  value: "", label: "V" },
    fl: { title: "Full Load",  value: "", label: "V" },
    v1: { title: "Voltage 1",  value: "", label: "V" },
    v2: { title: "Voltage 2",  value: "", label: "V" },
    v3: { title: "Voltage 3",  value: "", label: "V" },
    vs: { title: "Voltage S",  value: "", label: "V" },
    board_t: { title: "Board Temperature",  value: "", label: "F" },
    last_err: { title: "Last Error", value: "", label: "" },
  };

  @Input() diagnostics;

  constructor() { }

  ngOnInit() {
    if (this.diagnostics) {
      if (this.diagnostics.last_err === 255) {
        this.diagnostics.last_err = "No Error";
      } 

      this.retrieving_diagnostics = false;
      for (const [key, value] of Object.entries(this.diagnostics)) {
        const voltages = ['v1', 'v2', 'v3', 'vs'];

        if(voltages.includes(key)){
          this.diagnostics_data[key]["value"] = this.diagnostics[key]/100
        } else if (key === "date"){
          this.diagnostics_data["date"]["value"] = moment(value).format('MM/DD/YYYY, h:mm a')
        } else {
          this.diagnostics_data[key]["value"] = value;
        }
      }
    }
  }

  diagnosticKeys() {
    return Object.keys(this.diagnostics_data);
  }
}
