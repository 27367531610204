import moment from 'moment';
import { Component, OnInit, Input } from '@angular/core';
import { AlertService } from 'src/app/services/alert.service';
import { PickerController, Platform, ToastController } from '@ionic/angular';

@Component({
  selector: 'app-kiln-alerts',
  templateUrl: './kiln-alerts.component.html',
  styleUrls: ['./kiln-alerts.component.scss'],
})
export class KilnAlertsComponent implements OnInit {
  @Input() kilnId;
  @Input() viewingKilnlinkFreeKilns;
  public sentAlerts = [];
  public selectedAlert;
  public gettingAlerts;
  public marking_as_read;
  public current_kiln_id;
  @Input() small_screen;

  constructor(
    public alertService: AlertService,
    public toastCtrl: ToastController,
    private pickerController: PickerController,
    public platform: Platform
  ) {
    this.setSmallScreen();
    if(!this.viewingKilnlinkFreeKilns) {
      this.selectAlert(0);
      this.current_kiln_id = this.kilnId;
    }
  }

  ngOnInit() {
    this.marking_as_read = false;
    this.gettingAlerts = true;
    if(!this.viewingKilnlinkFreeKilns) {
      this.sentAlerts = [];
      this.getAlertsForKiln(this.current_kiln_id);
      this.setSmallScreen();
    }
  }

  ngOnChanges(changes) {
    this.marking_as_read = false;
    let prev = changes && changes.kilnId && changes.kilnId.previousValue;
    let curr = changes && changes.kilnId && changes.kilnId.currentValue;
    let has_kiln_id_change = prev != curr;
    if(!this.viewingKilnlinkFreeKilns && has_kiln_id_change) {
      this.gettingAlerts = true;
      this.sentAlerts = [];
      this.current_kiln_id = curr;
      this.getAlertsForKiln(this.current_kiln_id);
    }
  }

  setSmallScreen() {
    let is_device = this.platform ? (this.platform.is('mobile') || this.platform.is('tablet') || this.platform.is('phablet') || this.platform.is('mobileweb')) : false;
    this.small_screen = this.platform.width() < 875 || is_device;
  }

  selectAlert(index) {
    if(this.sentAlerts) {
      this.selectedAlert = this.sentAlerts[index];
      if(!this.selectedAlert) { this.selectedAlert = {}; }
      this.selectedAlert.index = index;
    }
  }

  markRead(type, alert_id) {
    this.marking_as_read = true;
    this.alertService.markRead(this.current_kiln_id, type, alert_id)
        .then((data) => {
          this.marking_as_read = false;
          this.showMessage("The alert was marked as read");
          if(!this.viewingKilnlinkFreeKilns) {
            this.getAlertsForKiln(this.current_kiln_id);
          }
        })
        .catch((error) => {
          this.marking_as_read = false;
          this.showMessage("There was an error marking the alert as read");
        })
  }

  getAlertsForKiln(kiln_id) {
    this.alertService.getAlerts(kiln_id)
        .then((data) => {
          this.sentAlerts = data;
          this.selectAlert(0);
          this.gettingAlerts = false;
        })
        .catch((error) => {
          console.log(error);
          this.gettingAlerts = false;
        })
  }

  async showMessage(message) {
    let toast = await this.toastCtrl.create({
      message: message,
      duration: 3000,
      position: 'middle'
    });

    toast.present();
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        this.selectAlert(selection.alert.value);        
      },
    },
  ];

  async showPicker() {
    let options = this.sentAlerts.map((alert, index) => { 
      return {text: `${moment(alert.sent_time).format('MM/DD/YY')} ${alert.subject.replace('KilnLink Alert: ', '')}`, value: index}
    })
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'alert',
        options: options
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }
}
