import { NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ComponentsModule } from './components.module';
import { AccountSettingsComponentModule } from './account-settings/account-settings-components.module';
import { HttpErrorInterceptor } from './http-error.interceptor';
// import { GlobalErrorHandler } from './global-error-handler';

import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { IonicStorageModule } from '@ionic/storage-angular';
import { LocalNotifications } from '@awesome-cordova-plugins/local-notifications/ngx';
import { Insomnia } from '@awesome-cordova-plugins/insomnia/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AlertSettingsPopupPage } from './alert-settings-popup/alert-settings-popup.page';

import { UsersMenuComponent } from './users-menu/users-menu.component';
import { AppPremiumPopupComponent } from './app-premium-popup/app-premium-popup.component';
import { Keyboard } from '@awesome-cordova-plugins/keyboard/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { FiringGraphComponent } from '../app/firing-graph/firing-graph.component'
import { NativeHttpInterceptor } from './native-http-interceptor';

@NgModule({
    declarations: [
        AppComponent,
        UsersMenuComponent,
        AppPremiumPopupComponent,
        AlertSettingsPopupPage
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        ComponentsModule,
        AccountSettingsComponentModule,
        HttpClientModule,
        IonicStorageModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        AccountSettingsComponentModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        LocalNotifications,
        Insomnia,
        Device,
        BarcodeScanner,
        Keyboard,
        FiringGraphComponent,
        HTTP,
        AppVersion,
        // {
        //   provide: ErrorHandler,
        //   useClass: GlobalErrorHandler
        // },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NativeHttpInterceptor,
            multi: true
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {}
