<ion-header class="alert-settings-header">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button class="icon back"></ion-back-button>
    </ion-buttons>
    <ion-title>
      Alert Settings
    </ion-title>
  </ion-toolbar>
</ion-header>

<h4 class="title">Alert Settings <ion-icon name="close" (click)="onDismiss ? onDismiss() : goBack()"></ion-icon></h4>
<ion-list id="alert-settings-popup">
  <form [formGroup]="alertSettingsForm" (submit)="saveAlertSettings(alertSettingsForm.value)">  
    <ion-item>
      <ion-label>1. Choose a Kiln</ion-label>
      <ion-note *ngIf="!kilns || (kilns && kilns.length === 0)">
        Sorry, we didn't fetch any kilns for your account.
      </ion-note>
      <ion-select placeholder="Choose a Kiln" *ngIf="kilns && kilns.length > 0" formControlName="selectedKiln" (ionChange)="selectKilnFromDropdown($event)">
        <ion-select-option *ngFor="let kiln of kilns" [value]="kiln"   
          [class.selected]="selectedKiln ? (selectedKiln.serial_number ? (selectedKiln.serial_number === kiln.serial_number) : (selectedKiln.kiln_id === kiln.kiln_id)) : false" >
          {{kiln.name}}
        </ion-select-option>
      </ion-select>    
    </ion-item>
    <ion-item>
      <ion-label>2. Choose an Alert</ion-label>
      <ion-select formControlName="selectedAlert" (ionChange)="selectAlert()">
        <ion-select-option *ngFor="let alert of alerts" [value]="alert.type"
          [class.selected]="selectedAlert && selectedAlert.type === alert.type">
          <ion-icon></ion-icon>
          <span>{{alert.name}}</span>
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-item lines="none">
      <ion-label slot="start">
        3. Configure Your Alert
        <p *ngIf="selectedAlert">({{selectedAlert.name}})</p>
      </ion-label>
    </ion-item>
    <ion-item *ngIf="showTempVariable() && canAdminister" class="temp-variable">
      <ion-label>Temperature Variable (˚F)<sup>*</sup></ion-label>
      <ion-input type="number"
        [class.invalid]="(alertSettingsForm.controls.temp_variable && alertSettingsForm.controls.temp_variable.errors && alertSettingsForm.controls.temp_variable.errors.required) || !alertSettingsForm.controls.temp_variable"
        formControlName="temp_variable"></ion-input>
      <ion-note
        *ngIf="(alertSettingsForm.controls.temp_variable && alertSettingsForm.controls.temp_variable.errors && alertSettingsForm.controls.temp_variable.errors.required) || !alertSettingsForm.controls.temp_variable">
        Temperature variable cannot be empty.
      </ion-note>
    </ion-item>
    <ion-item *ngIf="showTempVariable() && !canAdminister" class="temp-variable">
      <ion-label>Temperature Variable (˚F)<sup>*</sup></ion-label>
      <ion-input type="number" readonly="true" disabled="true" [class.readonly]="true"
        [class.invalid]="(alertSettingsForm.controls.temp_variable && alertSettingsForm.controls.temp_variable.errors && alertSettingsForm.controls.temp_variable.errors.required) || !alertSettingsForm.controls.temp_variable"
        formControlName="temp_variable"></ion-input>
    </ion-item>

    <ion-item id="user-select-label">
      <ion-label slot="start">Select Users:</ion-label>      
    </ion-item>
    <ion-item id="user-select-list">
      <ion-note *ngIf="gettingUsers"><ion-spinner name="bubbles"></ion-spinner></ion-note>
      <ion-note *ngIf="!gettingUsers && selectedKiln && (!selectedKiln.users || selectedKiln.users && selectedKiln.users.length === 0) && (alertSettingsForm)"
        class="error-users">
        No users selected to send alerts to.
      </ion-note>        
      <ion-list *ngIf="!gettingUsers && selectedKiln" [slot]="" formArrayName="enabled_users" class="enable-disable-users-for-alert" [multiple]="true">
        <ion-item-group *ngFor="let user of enabledUsers().controls; index as user_index" [formGroupName]="user_index" class="checkbox-list-item">
          <ion-item-divider>
            {{user.value.username}}
          </ion-item-divider>
          <ion-item class="checkboxes ion-no-padding" *ngIf="userHasEmail(user_index)">
            <ion-checkbox slot="start" *ngIf="alertSettingsForm && !emailCanAlert(user_index)" [readonly]="true" type="checkbox" readonly='true' ></ion-checkbox>
            <ion-checkbox slot="start" *ngIf="alertSettingsForm && emailCanAlert(user_index)" type="checkbox" formControlName="email_checked"
              (change)="checkboxChange($event, user_index)" ></ion-checkbox>
              <ion-label slot="end">{{user.value.email_label}}</ion-label>
          </ion-item>

          <ng-container formArrayName="phones" *ngIf="userPhoneControls(user_index) && userPhoneControls(user_index).length > 0">
            <ion-item *ngFor="let phone of userPhoneControls(user_index); let phone_index = index;" [formGroupName]="phone_index" class="checkboxes ion-no-padding">
              <ion-checkbox slot="start" class="readonly" *ngIf="alertSettingsForm && (phone && phone.value && !phone.value.can_be_checked)" type="checkbox" readonly='true' ></ion-checkbox>
              <ion-checkbox slot="start" *ngIf="alertSettingsForm && (phone && phone.value && phone.value.can_be_checked)" type="checkbox" formControlName="checked"
                (change)="checkboxChange($event, user_index)" ></ion-checkbox>
              <ion-label slot="end">{{phone.value.label}}</ion-label>
            </ion-item>
          </ng-container>
        </ion-item-group>
      </ion-list>        
    </ion-item>
    <ion-item lines="none" class="verification">
      <ion-note slot="end"><span><sup>*</sup>Email or phone has not been verified or is not enabled for alerts. Please verify and enable before the contact method can be used.</span></ion-note>
      <ion-note *ngIf="!selectedKiln" slot="end">Please select a kiln to configure.</ion-note>
    </ion-item>

    <ion-item class="test-alert-button-container" lines="none">
      <ion-button slot="end" type="button" (click)="testAlert(selectedKiln.mac_address, selectedKiln.serial_number, selectedAlert.type)">Test Alert</ion-button>
      <ion-button slot="end" type="submit">Save</ion-button>
    </ion-item>        
  </form>  
</ion-list>

<ion-item class="buttons">
  <ion-button slot="end" (click)="onDismiss ? onDismiss() : goBack()" class="close">Close</ion-button>
  <ion-button slot="end" *ngIf="!savingSettings" [class.disabled]="!alertSettingsForm.valid" class="primary" (click)="saveAlertSettings(alertSettingsForm.value)">Save</ion-button>
  <ion-button slot="end" *ngIf="savingSettings"><ion-spinner name="bubbles"></ion-spinner></ion-button>
</ion-item>
