<ion-spinner name="bubbles" *ngIf="!gotData"></ion-spinner>
<div *ngIf="kiln && gotData && notConnected" class="main-status not-connected">
  Your kiln is not connected at this time.
</div>
<div *ngIf="kiln && gotData && !notConnected" class="main-status">  
  <p class="ion-text-wrap error-text" *ngIf="errorText || kiln.status && kiln.status.mode == 'Error' && gotData">
    {{(kiln.status && kiln.status.error && kiln.status.error.err_text) || errorText}}
  </p>
  <div *ngIf="alarm.active" class="alarm"
    [ngClass]="alarmFlashClass">
    {{alarm.text}}
  </div>
   <ion-list *ngIf="kiln.status || kiln.program">
      <ion-item-divider color="light-shade">Current Controller Settings <span *ngIf="updating"><ion-spinner name="bubbles" color='light'></ion-spinner></span></ion-item-divider>
     <ion-item *ngIf="kiln.program && kiln.program.name">
       <ion-label>Program Name </ion-label>
      <p>{{kiln.program.name}} {{((kiln.program.speed && kiln.program.speed !== 'na') && (kiln.program.name && !kiln.program.name.includes(kiln.program.speed) && !kiln.program.name.toLowerCase().includes('custom'))) ? kiln.program.speed : ''}}</p>
     </ion-item>

    <!--  <ion-item *ngIf="kiln.program && kiln.program.steps">
       <ion-label>Preheat </ion-label>
      <p>{{kiln.program.steps[0].hr}}:{{kiln.program.steps[0].mn.toString().padStart(2, '0')}}</p>
     </ion-item> -->

     <ion-item *ngIf="kiln.program && kiln.program.cone && kiln.program.speed">
       <ion-label>Cone </ion-label>
      <p>{{kiln.program.cone}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.program && kiln.program.cone && kiln.program.speed">
       <ion-label>Speed </ion-label>
      <p>{{kiln.program.speed}}</p>
     </ion-item>

     <ion-item *ngIf="kiln && kiln.status && kiln.status.firing && (kiln.status.firing.start_hour || kiln.status.firing.start_min)">
       <ion-label>Delay </ion-label>
      <p>{{getDelayStartTime(kiln)}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing && kiln.status.firing.step && kiln.status.firing.step.substring(0,4) == 'Hold'">
       <ion-label>Hold Remaining </ion-label>
      <p>{{kiln.status.firing.hold_hour}}:{{(kiln.status.firing.hold_min.toString().length == 2) ? kiln.status.firing.hold_min : '0'+kiln.status.firing.hold_min}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.program && kiln.program.alarm_t">
       <ion-label>Alarm </ion-label>
      <p>{{kiln.program.alarm_t}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing">
       <ion-label>Firing Time </ion-label>
      <p *ngIf="kiln.status.mode === 'Firing' || kiln.status.mode === 'Complete' || kiln.status.mode.includes('Error') || kiln.status.mode.includes('Fail')">{{kiln.status.firing.fire_hour}}:{{(kiln.status.firing.fire_min.toString().length == 2) ? kiln.status.firing.fire_min : '0' + kiln.status.firing.fire_min}}</p>
      <p *ngIf="kiln.status.mode !== 'Firing' && kiln.status.mode !== 'Complete' && !kiln.status.mode.includes('Error') && !kiln.status.mode.includes('Fail')">---</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing && kiln.status.mode == 'Firing'">
       <ion-label>Estimated Time Remaining </ion-label>
      <p>{{kiln.status.firing.etr}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing && kiln.status.mode == 'Complete'">
       <ion-label>Cost </ion-label>
      <p>{{kiln.status.firing.cost}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing && kiln.status.mode == 'Firing'">
       <ion-label>Set Point </ion-label>
      <p>{{kiln.status.firing && kiln.status.firing.set_pt}}°{{kiln.config.t_scale}}</p>
     </ion-item>

     <ion-item *ngIf="kiln.status && kiln.status.firing && kiln.status.mode == 'Firing'">
       <ion-label>Segment </ion-label>
      <p>{{kiln.status.firing.step}}</p>
     </ion-item>
   </ion-list>
  <ion-grid *ngIf="kiln.program && kiln.program.steps">
     <ion-row class="header">
       <ion-col>Segment </ion-col>
       <ion-col>Ramp </ion-col>
       <ion-col>Temp </ion-col>
       <ion-col>Hold </ion-col>
     </ion-row>
     <ion-row *ngFor="let step of kiln.program.steps; index as i;">
       <ion-col>{{step.num}} </ion-col>
       <ion-col>{{step.rt}} </ion-col>
       <ion-col>{{step.t}} </ion-col>
       <ion-col>{{step.hr}}:{{step.mn.toString().padStart(2, '0')}} </ion-col>
     </ion-row>    
  </ion-grid>
</div>

<div *ngIf="hasElevatedPermissions" class="raw-kiln-status">
  <button (click)="viewRawKilnStatus()">View Raw Kiln Status</button>
</div>
