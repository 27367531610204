import { Component, ViewChild } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { HomePage } from './home/home.page';
import { UserService } from 'src/app/services/user.service';
import { KilnService } from 'src/app/services/kiln.service';
import { StorageService } from './services/storage.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  rootPage:any = HomePage;
  @ViewChild('myNav') nav;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private splashScreen: SplashScreen,
    private userService: UserService,
    private kilnService: KilnService,
    private storageService: StorageService
  )
  {
    platform.ready().then(() => {
      statusBar.styleDefault();
      statusBar.overlaysWebView(false);
      // statusBar.backgroundColorByHexString('#ffffff');
      splashScreen.hide();
    });
  }

  setPlatformListener() {
    this.platform.resume.subscribe(async () => {
      this.userService.getStoredEmail();
      this.userService.getStoredPassword();
      this.kilnService.startTimerSecond(); //start the second timer which will always run

      this.storageService.get('email').then((email_val) => {
        this.storageService.get('password').then((pwd_val) => {
          if(email_val && pwd_val) {
            this.userService.tryLogin(email_val, pwd_val).subscribe(() => {
              this.kilnService.setTimerSecond(1);
              this.userService.setTimerSecond(1);
            });
          }
        });
      });
    });
  }
}
