import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CustomValidators } from 'ng2-validation';
import { UserService } from 'src/app/services/user.service';
import { NavController, ToastController } from '@ionic/angular';
import { PasswordStrengthProvider } from 'src/app/services/password-strength';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  @Input() onDismiss;

  public changePwdForm: UntypedFormGroup;
  public errorText;
  public updatingPassword;

  constructor(public userService: UserService,
    public toastCtrl: ToastController,
    public passwordStrength: PasswordStrengthProvider,
    private navCtrl: NavController
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.createForm();
    this.updatingPassword = false;
  }

  createForm() {
    if(!this.changePwdForm) {
      let password = new UntypedFormControl('', [Validators.required]);
      let new_password = new UntypedFormControl('', [Validators.required]);
      let new_password_confirmation = new UntypedFormControl('', [Validators.required, CustomValidators.equalTo(new_password)])

      this.changePwdForm = new UntypedFormGroup({
        password: password,
        new_password: new_password,
        new_password_confirmation: new_password_confirmation
      });
    }
  }

  onSubmit(values) {
    if(this.changePwdForm.valid && values) {
      var isStrong = this.passwordStrength.check(values.password)
      var new_passwords_match = values.new_password === values.new_password_confirmation
      if(!isStrong.strong || !new_passwords_match) {
        let message = !new_passwords_match ? 'Passwords do not match' : '';
        if(isStrong.errors) {
          message = `Error(s): ${isStrong.errors.join(" ")}`
        }
        this.onResetPasswordError({message: message})
      } else {
        this.errorText = null;
        this.updatingPassword = true;
        this.userService.updatePassword(values.new_password, values.password, undefined, {})
            .then((response) => { this.onResetPasswordSuccess(response) })
            .catch((error) => { this.onResetPasswordError(error) })
      }
    }
  }

  async onResetPasswordSuccess(data) {
    this.updatingPassword = false;
    let toast = await this.toastCtrl.create({
      message: 'Password was reset. Please log in again with your new password.',
      duration: 3000,
      position: 'middle',
      cssClass: 'success'
    });

    await toast.present();
    this.onDismiss();
    this.userService.tryLogOut();
    this.navCtrl.navigateForward('/');
  }

  async onResetPasswordError(error) {
    this.updatingPassword = false;
    let toast = await this.toastCtrl.create({
      message: error.message ? error.message : "We could not update your password. Please check the information you submitted and try again.",
      duration: 3000,
      position: 'middle',
      cssClass: 'error'
    });

    await toast.present();
  }
}
