<!-- <ion-spinner *ngIf="drawingGraph"></ion-spinner>
<div class='tooltip'>Tooltip text</div>
<figure id="bar"></figure> -->

<div class="button-container">
    <ion-button (click)="zoomIn()" size="small">Zoom In</ion-button>
    <ion-button (click)="zoomOut()" size="small">Zoom Out</ion-button>
    <ion-button (click)="reset()" size="small">Reset</ion-button>
</div>
<div class="container">
    <figure id="bar">
        <div class="tooltip">
            Tooltip text
        </div>
    </figure>
  </div> 