import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormArray, Validators } from '@angular/forms';
import { AccountService } from 'src/app/services/account.service';
import { AlertService } from 'src/app/services/alert.service';
import { UserService } from 'src/app/services/user.service';
import { KilnService } from 'src/app/services/kiln.service';
import { AlertController, NavController, PickerController } from '@ionic/angular';

import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';

@Component({
  selector: 'app-account-settings-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class AccountSettingsUsersComponent implements OnInit, OnChanges {
  @Input() accountInformation;
  @Input() canAdminister;
  @Input() small_screen = false;

  @Output() pending = new EventEmitter<Object>();
  @Output() buttonText = new EventEmitter<Object>();

  public usersForm: UntypedFormGroup;
  public phonesForm: UntypedFormGroup;
  public emailForm: UntypedFormGroup;
  public kilnSecurityForm: UntypedFormGroup;

  public selected_tab = 'details';
  public selected_user = null;
  public selected_index = null;

  public hideAdmins = false;
  public hideBasics = false;
  public hideReadOnly = false;

  public network_devices = [];
  public kilns_by_network_device = {};
  public allowUsers = "all";

  public gettingUserData = false;
  public all_kilns = [];
  public phone_type_options = ['Home', 'Mobile', 'Work'];
  private phoneUtil;
  private loggedInUser;
  private selected_user_email;
  private is_changing_email = false;
  public userInfo = {admin: [], basic: [], readOnly: []};

  constructor(public accountService: AccountService,
    public alertService: AlertService,
    public userService: UserService,
    public kilnService: KilnService,
    public alertController: AlertController,
    public navCtrl: NavController,
    private pickerController: PickerController
  ) {
    let login_data = this.userService.getLoginData();
    let loggedInAs = login_data.loggedInAs;
    this.loggedInUser = (login_data.hasOwnProperty('loggedInAs') && login_data.loggedInAs) ? (loggedInAs.email || loggedInAs.username) : (login_data.email || login_data.username);
    this.phoneUtil = PhoneNumberUtil.getInstance();    
  }

  getUserAndDeviceInformation() {
    this.gettingUserData = true;
    this.getAccountUsers();
    this.accountService.getDevicesForAccount().then( (data) => {
      this.network_devices = [];
      this.all_kilns = [];
      this.kilns_by_network_device = {};
      this.network_devices = data && data.network_devices;
      let kilns = data && data.kilns.length > 0 ? data.kilns.sort((a, b) => (a.name > b.name) ? 1 : -1) : [];
      this.all_kilns = kilns;

      if(kilns) {
        kilns = kilns.filter((k) => k)
        this.kilns_by_network_device = kilns.reduce((accumulator, val) => {
          let network_device_id = val.network_device_id || '-1';

          if(!val.network_device_id && !accumulator.hasOwnProperty('-1') && !this.network_devices.find((nd) => nd.name === "Not Connected to Network Device")) {
            this.network_devices.push({id: '-1', name: "Not Connected to Network Device"})
          }

          if(accumulator.hasOwnProperty(network_device_id)) {
            accumulator[network_device_id].push(val);
          } else {
            accumulator[network_device_id] = [val];
          }

          return accumulator;
        }, {})
      } else {
        this.kilns_by_network_device = {};
      }

      this.kilnSecurityForm = undefined;
      this.createKilnSecurityForm();
    });
  }

  ngOnInit() {
    this.is_changing_email = false;
    this.network_devices = [];
    this.all_kilns = [];
    this.kilns_by_network_device = {};
    let login_data = this.userService.getLoginData();
    let loggedInAs = login_data.loggedInAs;
    this.loggedInUser = (login_data.hasOwnProperty('loggedInAs') && login_data.loggedInAs) ? (loggedInAs.email || loggedInAs.username) : (login_data.email || login_data.username);
    this.gettingUserData = true;
    this.selected_user = null;
    this.createForm();
    this.createPhonesForm();
    this.createEmailForm();
    this.getUserAndDeviceInformation();
  }

  ngOnChanges() {
    this.is_changing_email = false;
    this.getUserAndDeviceInformation();
  }

  verificationSuccess() {
    this.getAccountUsers();
    this.pending.emit({is_pending: false,message: "Please check for your device for a code to complete device verification.",success: true});
  }
  verificationFails() {this.pending.emit({is_pending: false,message: "Something went wrong sending your device a verification code",success: false});}

  deviceConfirmSuccess() {
    this.getAccountUsers();
    this.pending.emit({is_pending: false,message: "Your device has been verified",success: true});
  }
  deviceConfirmFails() {
    this.pending.emit({is_pending: false,message: "Your device could not be verified at this time", success: false});
  }

  getAccountUsers() {
    this.accountService.getUsersForAccount()
        .then((response) => {
          this.accountInformation.users = response.sort((a, b) => {
            return (a.email > b.email) ? 1 : -1;
          });
          this.setUpCanAdminister(response);
          if(this.canAdminister) {
            this.selectUser(this.accountInformation.users && this.accountInformation.users[0], 0)
          } else {
            let current = this.accountInformation.users.findIndex((user) => { return user.email === this.loggedInUser || user.username === this.loggedInUser});
            this.selectUser(this.accountInformation.users[current], current);
          }
          this.userInfo = {
            admin: this.admins(),
            basic: this.basicUsers(),
            readOnly: this.readOnlyUsers()
          }

          this.gettingUserData = false;
        })
        .catch((error) => {
          this.gettingUserData = false;
          console.log(`error getting account users: ${error}`)
        })
  }

  setUpCanAdminister(data) {
    if(this.accountInformation && this.accountInformation.users && this.loggedInUser) {
      let loggedInUserPermissions = this.accountInformation.users.find((u) => {
        return u.email ? (u.email === this.loggedInUser || u.mail_to === this.loggedInUser) : (u.username === this.loggedInUser);
      })

      this.canAdminister = loggedInUserPermissions ? (loggedInUserPermissions.type === 'admin') : false;
    } else {
      this.canAdminister = false;
    }
  }

  private createForm(user?) {
    let emptyFormControl = (default_value?) => { return new UntypedFormControl((default_value === undefined ? '' : default_value), [Validators.required, Validators.minLength(2)]); }
    let emptyFormControlNotRequired = (default_value?) => { return new UntypedFormControl(((default_value === undefined) ? "" : default_value)); }

    this.selected_user_email = user && user.email;
    this.usersForm = new UntypedFormGroup({
      email: emptyFormControl(user && user.email),
      verification_code: new UntypedFormControl(''),
      type: emptyFormControl((user && user.type) || 'basic'),
      first_name: emptyFormControl(user && user.first_name),
      last_name: emptyFormControl(user && user.last_name)
    });

    if(!user) {
      this.buttonText.emit({submitButton: "Create"});
    }
  }

  clearCurrentForm() {
    let getFormToCheck = {
      "details": this.usersForm,
      "phone": this.phonesForm,
      "email": this.emailForm,
      "security": this.kilnSecurityForm
    }

    let currentForm = getFormToCheck[this.selected_tab];
    currentForm.reset();
  }

  private createPhonesForm() {
    let primary = this.getPhoneInformation();
    let secondary = this.getPhoneInformation(true);

    let isPhoneNumber = input => {
      try {
        let ISD_CODES = [93, 355, 213, 1684, 376, 244, 1264, 672, 1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975, 591, 387, 267, 55, 246, 1284, 673, 359, 226, 257, 855, 237, 1, 238, 1345, 236, 235, 56, 86, 61, 61, 57, 269, 682, 506, 385, 53, 599, 357, 420, 243, 45, 253, 1767, 1809, 1829, 1849, 670, 593, 20, 503, 240, 291, 372, 251, 500, 298, 679, 358, 33, 689, 241, 220, 995, 49, 233, 350, 30, 299, 1473, 1671, 502, 441481, 224, 245, 592, 509, 504, 852, 36, 354, 91, 62, 98, 964, 353, 441624, 972, 39, 225, 1876, 81, 441534, 962, 7, 254, 686, 383, 965, 996, 856, 371, 961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 222, 230, 262, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 95, 264, 674, 977, 31, 599, 687, 64, 505, 227, 234, 683, 850, 1670, 47, 968, 92, 680, 970, 507, 675, 595, 51, 63, 64, 48, 351, 1787, 1939, 974, 242, 262, 40, 7, 250, 590, 290, 1869, 1758, 590, 508, 1784, 685, 378, 239, 966, 221, 381, 248, 232, 65, 1721, 421, 386, 677, 252, 27, 82, 211, 34, 94, 249, 597, 47, 268, 46, 41, 963, 886, 992, 255, 66, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 1340, 256, 380, 971, 44, 1, 598, 998, 678, 379, 58, 84, 681, 212, 967, 260, 263],
          //extract numbers from string
          thenum = input.match(/[0-9]+/g).join(""),
          totalnums = thenum.length,
          last10Digits = parseInt(thenum) % 10000000000,
          ISDcode = thenum.substring(0, totalnums - 10);

        //phone numbers are generally of 8 to 16 digits
        if (totalnums >= 8 && totalnums <= 16) {
          if (ISDcode) {
            if (ISD_CODES.includes(parseInt(ISDcode))) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        }
      } catch (e) {}

      return false;
    }

    let validatePhoneNumber = (num) => {
      if(num && num.value && num.value !== "") {
        try {
          if(!num.value.includes("+")) { num.value = `+${num.value}`}
          const number = this.phoneUtil.parseAndKeepRawInput(num.value);
          return this.phoneUtil.isValidNumber(number);
        } catch(e) {
          // console.log(`error formatting phone number: ${JSON.stringify(e)}`)
          return {invalidNumber: {value: num.value}};
        }
      } else {
        return num.value !== "" ? {invalidNumber: {value: num.value}} : null;
      }
    }

    let emptyFormControl = (default_value, additional_validators?) => {
      let validators = [];
      if(additional_validators) {
        validators.push(additional_validators);
      }

      return new UntypedFormControl(((default_value === undefined) ? '' : default_value), validators);
    }

    this.phonesForm = new UntypedFormGroup({
      primary_number: emptyFormControl(primary.call_number, validatePhoneNumber),
      primary_type: emptyFormControl(primary.type),
      primary_alerts_enabled: emptyFormControl(primary.enable_for_alerts),
      secondary_number: emptyFormControl(secondary.call_number, validatePhoneNumber),
      secondary_type: emptyFormControl(secondary.type || 'Mobile'),
      secondary_alerts_enabled: emptyFormControl(secondary.enable_for_alerts),
      verification_code: emptyFormControl('')
    })
  }

  private createEmailForm() {
    let email = new UntypedFormControl((this.selected_user && this.selected_user.email) || '', [Validators.required]);
    this.emailForm = new UntypedFormGroup({
      email: email,
      verification_code: new UntypedFormControl('')
    })
  }

  public kilnSecurityFormNetworkDevices() {
    if(this.kilnSecurityForm) {
      return (this.kilnSecurityForm.get('network_devices') as UntypedFormArray) ? (this.kilnSecurityForm.get('network_devices') as UntypedFormArray).controls : [];
    } else {
      return [];
    }
  }

  public getKilnsForNetworkDevice(index) {
    let nds = this.kilnSecurityFormNetworkDevices();
    if(nds.length > 0) {
      return (nds[index].get('kilns') as UntypedFormArray) ? (nds[index].get('kilns') as UntypedFormArray).controls : [];
    } else { return []; }
  }

  private createKilnSecurityForm() {
    this.kilnSecurityForm = undefined;
    if(this.network_devices && this.kilns_by_network_device && this.selected_user) {
      let user_has_access_to_whole_account = (this.selected_user.network_devices && this.selected_user.network_devices.every((nd) => !nd.id)) && (this.selected_user.kilns && this.selected_user.kilns.every((k) => !k.id))
      let network_device_form_groups = this.network_devices.map((nd) => {
        if(nd) {
          let user_has_access_to_nd = this.selected_user && this.selected_user.network_devices && this.selected_user.network_devices.find((user_accessible_nd) => { return user_accessible_nd.id === nd.id })
          let kilns_to_map = this.kilns_by_network_device[nd.id] || [];
          return new UntypedFormGroup({
            checked: new UntypedFormControl(user_has_access_to_whole_account || (user_has_access_to_nd !== undefined)),
            name: new UntypedFormControl(nd.name || `Name Unavailable (MAC: ${nd.mac_address})`),
            id: new UntypedFormControl(nd.id),
            kilns: new UntypedFormArray(kilns_to_map.map((k) => {
              let user_has_access_to_kiln = this.selected_user && this.selected_user.kilns && this.selected_user.kilns.find((user_accessible_k) => { return user_accessible_k.id === k.id })
              return new UntypedFormGroup({
                name: new UntypedFormControl(k.name),
                checked: new UntypedFormControl(user_has_access_to_whole_account || (user_has_access_to_kiln !== undefined)),
                id: new UntypedFormControl(k.id)
              });
            }))
          })
        } else {
          return new UntypedFormGroup({
            checked: new UntypedFormControl(false),
            name: new UntypedFormControl("Not Available"),
            id: new UntypedFormControl("na"),
            kilns: new UntypedFormArray([])
          })
        }
      });

      this.kilnSecurityForm = new UntypedFormGroup({
        allowUsersOverallPolicy: new UntypedFormControl(user_has_access_to_whole_account ? 'all' : 'selectKilns'),
        network_devices: new UntypedFormArray(network_device_form_groups)
      })
    } else {
      this.kilnSecurityForm = undefined;
    }
  }

  basicUsers() {
    if(!this.accountInformation) { return []; } else {
      if(this.canAdminister) {
        return this.accountInformation.users.filter((user) => { return user.type === 'basic' || !user.type });
      } else {
        return this.accountInformation.users.filter((user) => { return (user.email === this.loggedInUser || user.username === this.loggedInUser) && user.type === 'basic'});
      }
    }
  }

  admins() {
    return this.accountInformation ? this.accountInformation.users.filter((user) => { return user.type === 'admin'}) : [];
  }

  readOnlyUsers() {
    if(!this.accountInformation) { return []; } else {
      if(this.canAdminister) {
        return this.accountInformation.users.filter((user) => { return user.type === 'readonly' || !user.type });
      } else {
        return this.accountInformation.users.filter((user) => { return (user.email === this.loggedInUser || user.username === this.loggedInUser) && user.type === 'readonly'});
      }
    }
  }

  selectUser(user, index) {
    this.selected_tab = 'details';
    this.selected_user = user;
    this.selected_index = index;
    this.createForm(this.selected_user)
    this.createPhonesForm();
    this.createEmailForm();
    this.createKilnSecurityForm();
    this.buttonText.emit({submitButton: "Create"});
  }

  getPhoneInformation(is_secondary = false) {
    let default_phone = {call_number: "", type: "Home"};
    let is_empty = (phone) => {
      return phone ? (!phone.carrier && !phone.type && !phone.call_number) : true;
    }

    if(this.selected_user) {
      let secondary = is_empty(this.selected_user.secondary_phone) ? default_phone : this.selected_user.secondary_phone;
      let primary = is_empty(this.selected_user.primary_phone) ? default_phone : this.selected_user.primary_phone;

      return is_secondary ? secondary : primary;
    } else {
      return default_phone;
    }
  }

  allowUsersChange(event) {
    this.allowUsers = event.target.value;
    if(this.allowUsers === 'all') {
      this.createKilnSecurityForm();
    }
  }

  public save() {
    this.pending.emit({is_pending: true});
    if(this.selected_tab === 'details') {
      let formValue = this.usersForm.value
      formValue.enabled = true;
      if(formValue.email && this.selected_user_email && (formValue.email.trim() === this.selected_user_email.trim())) {
        delete formValue.email;
      } else {
        this.is_changing_email = true;
      }

      this.userService.premiumUser(formValue, this.selected_user, this.accountInformation.general.external_id)
          .then((response) => {
            if(this.selected_user) {
              if(this.is_changing_email && !this.userService.isLoggedInAsAnotherUser()) {
                this.userService.tryLogOut();
                this.kilnService.logOut();
                this.navCtrl.navigateForward('/');

                this.getUserAndDeviceInformation();
                this.pending.emit({
                  is_pending: false,
                  message: 'Your information was updated. Please log in with your updated email address.',
                  success: true,
                  dismiss: true
                });
              } else {
                this.pending.emit({
                  is_pending: false,
                  message: 'User successfully updated.',
                  success: true
                });

                if(this.is_changing_email) {
                  this.gettingUserData = true;
                  this.getAccountUsers();
                }

                this.getUserAndDeviceInformation();
                let userIndex = this.accountInformation.users.findIndex((u) => { return (u.email === response.email || u.username === response.username) });
                this.accountInformation.users.splice(userIndex, 1, response);
                this.selectUser(response, userIndex);
              }
            } else {
              this.pending.emit({
                is_pending: false,
                message: 'User successfully created.',
                success: true
              });

              this.getUserAndDeviceInformation();
              this.accountInformation.users.push(response);
              this.selectUser(response, (this.accountInformation.users.length - 1));
            }
          })
          .catch(function() {
            if(this.selected_user) {
              this.pending.emit({
                is_pending: false,
                message: 'Sorry, we could not update the user at this time',
                success: false
              });
            } else {
              this.pending.emit({
                is_pending: false,
                message: 'Sorry, we could not create the user at this time',
                success: false
              });
            }
          })

    } else if(this.selected_tab === 'security' && this.canAdminister) {
      let formValue = this.kilnSecurityForm.value;
      let to_submit = formValue.network_devices.reduce((accumulator, value) => {
        if(value.checked && formValue.allowUsersOverallPolicy === "selectNetworkDevices") {
          accumulator.network_devices.push(value.id);
        }

        if(formValue.allowUsersOverallPolicy === "selectKilns") {
          accumulator.kilns = accumulator.kilns.concat(value.kilns.filter((k) => { return k.checked }).map(k => k.id));
        } else {
          accumulator.kilns = [];
        }

        return accumulator;
      }, {network_devices: [], kilns: []})
      this.userService.updateKilnSecurity((this.selected_user && this.selected_user.id), this.accountInformation.general.external_id, to_submit)
          .then((success) => {
            this.getUserAndDeviceInformation();
            this.pending.emit({is_pending: false,message: "The user's kiln access was updated successfully.",success: true});
          })
          .catch((error) => { this.pending.emit({is_pending: false,message: "Something went wrong updating the user's kiln access",success: false}); })

    } else if(this.selected_tab === 'phone') {
      let formValue = this.phonesForm.value;
      let call_numbers = []
      if(formValue.primary_number !== "") {
        if(!formValue.primary_number.includes("+")) { formValue.primary_number = `+${formValue.primary_number}`}
        let primary_number = this.phoneUtil.parseAndKeepRawInput(formValue.primary_number, 'US');
        call_numbers.push({
          call_number: this.phoneUtil.format(primary_number, PhoneNumberFormat.INTERNATIONAL),
          type: formValue.primary_type,
          carrier: "",
          primary: true,
          secondary: false,
          enable_for_alerts: formValue.primary_alerts_enabled
        });
      }

      if(formValue.secondary_number !== "") {
        if(!formValue.secondary_number.includes("+")) { formValue.secondary_number = `+${formValue.secondary_number}`}
        let secondary_number = this.phoneUtil.parseAndKeepRawInput(formValue.secondary_number, 'US');
        call_numbers.push({
          call_number: this.phoneUtil.format(secondary_number, PhoneNumberFormat.INTERNATIONAL),
          type: formValue.secondary_type,
          carrier: "",
          primary: false,
          secondary: true,
          enable_for_alerts: formValue.secondary_alerts_enabled
        })
      }

      if(call_numbers.length === 0) {
        this.pending.emit({
          is_pending: false,
          message: 'Please update a phone number before saving',
          success: false
        });
      } else {
        this.userService.updatePhone(this.selected_user, call_numbers)
            .then((response) => {
              this.getUserAndDeviceInformation();
              this.accountInformation.users = this.accountInformation.users.map((u) => {
                if(u.username !== response.username && response.phones) {
                  u.primary_phone = response.phones.find((p) => !p.secondary)
                  u.secondary_phone = response.phones.find((p) => p.secondary)
                }

                return u;
              });
              this.pending.emit({is_pending: false, message: 'Phones updated successfully.', success: true});              
            })
            .catch((error) => { this.pending.emit({is_pending: false,message: 'Something went wrong updating phone numbers',success: false}); })
      }
    } else if(this.selected_tab === 'email') {
      let formValue = this.usersForm.value;
      this.userService.updateEmail(this.selected_user, {email: formValue.email})
          .then((response) => {
            this.getUserAndDeviceInformation();
            this.pending.emit({is_pending: false, message: 'Email updated successfully.', success: true});
            this.accountInformation.users = this.accountInformation.users.map((u) => {
              if(u.username !== response.username) { u.email = response.email; }
              return u;
            });
          })
          .catch((error) => { this.pending.emit({is_pending: false,message: 'Something went wrong updating the user email',success: false}); })
    }
  }

  public addUser() {
    this.selected_user = null;
    this.createForm();
    this.createPhonesForm();
    this.createEmailForm();
    this.buttonText.emit({submitButton: "Create"});
  }

  async removeUser() {
    let username = this.selected_user.email || this.selected_user.username || "this user";
    const alert = await this.alertController.create({
      header: 'Delete confirmation',
      message: `Are you sure you want to remove ${username} from this account? This action cannot be reversed.`,
      buttons: [
        {
          text: 'Yes, Delete',
          role: 'delete',
          cssClass: 'primary',
          handler: (data) => {
            this.pending.emit({is_pending: true});
            this.userService.removeUserFromAccount(this.accountInformation.general.external_id, this.selected_user.id)
                .then((response) => {
                  this.pending.emit({
                    is_pending: false,
                    message: 'User successfully removed.',
                    success: true
                  });

                  this.accountInformation.users = this.accountInformation.users.filter((u) => { return u.id !== response.removed});
                  this.selected_user = undefined;
                  this.clearCurrentForm();                  
                })
          }
        }, {
          text: "No, Cancel",
          cssClass: 'secondary',
          role: 'cancel'
        }
      ]
    });

    await alert.present();
  }

  deletePhone(secondary) {
    let phone = secondary ? (this.selected_user.secondary_phone && this.selected_user.secondary_phone.call_number) : (this.selected_user.primary_phone && this.selected_user.primary_phone.call_number);
    this.alertService.deletePhone(phone)
        .then((success) => {
          this.getAccountUsers();
          this.pending.emit({is_pending: false,message: "The phone number was deleted",success: true});
        })
        .catch((err) => {
          this.pending.emit({is_pending: false,message: "Something went wrong deleting your phone number",success: false});
        })
  }

  verifyPhone(secondary) {
    let val = this.phonesForm.value;
    let call_number = secondary ? val.secondary_number : val.primary_number;
    this.alertService.verifyDevice(this.selected_user, "phone", {phone: call_number})
        .then((response) => {this.verificationSuccess() })
        .catch((error) => { this.verificationFails() });
  }

  confirmPhoneVerification(secondary) {
    let val = this.phonesForm.value;
    let call_number = secondary ? val.secondary_number : val.primary_number;
    this.alertService.confirmVerification("phone", {phone: call_number, verification_code: val.verification_code})
        .then((response) => { this.deviceConfirmSuccess() })
        .catch((error) => { this.deviceConfirmFails() })
  }

  verifyEmail() {
    let val = this.usersForm.value;
    this.alertService.verifyDevice(this.selected_user, "email", {email: val.email})
        .then((response) => { this.verificationSuccess() })
        .catch((error) => { this.verificationFails() })
  }

  confirmEmailVerification() {
    let val = this.usersForm.value;
    this.alertService.confirmVerification("email", {email: val.email, verification_code: val.verification_code})
        .then((response) => { this.deviceConfirmSuccess() })
        .catch((error) => { this.deviceConfirmFails() })
  }

  public pickerButtons = [
    {
      text: 'Cancel',
      role: 'cancel',
    },
    {
      text: 'Confirm',
      handler: (selection) => {
        this.selectUser(selection.users.value.user, selection.users.value.index);
      },
    },
  ];

  async showPicker() {
    let picker = await this.pickerController.create({
      columns: [
      {
        name: 'users',
        options: this.accountInformation.users.sort((u1, u2) => {
          let val = 0;
          if((u1.type === 'admin' && u2.type !== 'admin') || (u1.type === 'basic' && u2.type === 'readonly')) val = -1;
          if(u1.type === 'readonly') val = 1;
          return val;
        }).map((user, i) => { 
          let typeLabels = {'admin': "Admin", 'basic': "Basic", 'readonly': "Readonly"}
          return {          
            text: `${user.email} - ${typeLabels[user.type]}`, value: {user: user, index: i}
          }})
      }],
      buttons: this.pickerButtons
    });

    await picker.present();
  }

  public isValid() {
    if(this.selected_tab) {
      let getFormToCheck = {
        "details": this.usersForm,
        "phone": this.phonesForm,
        "email": this.emailForm,
        "security": this.kilnSecurityForm
      }

      let currentForm = getFormToCheck[this.selected_tab];
      return (currentForm && currentForm.touched) ? currentForm.valid : true;
    } else {
      return true;
    }
  }
}
