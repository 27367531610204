<ion-progress-bar *ngIf="!notConnected && retrievingData" type="indeterminate"></ion-progress-bar>
<div [class.display-none]="!notConnected || !retrievingData" id="not-reported-firing-graph-main-container">  
  <div [class.display-none]="viewingKilnlinkFreeKilns && !notConnected" id="graph-not-available" #noGraph>
    Your controller has not reported in a while, please check it's connection.
  </div>
</div>
<div id="firing-graph-main-container" #container [class.display-none]="notConnected || !kilnId">
  <div *ngIf="!notConnected && !viewingKilnlinkFreeKilns && kiln && testData && testData.statuses && !error" id="firing-graph">
    <app-firing-graph [zones]="testData.config ? testData.config.num_zones : 1" [smallScreen]="smallScreen" 
                      [dataLength]="testData.statuses ? testData.statuses.length : 0"
                      [data]="testData.statuses"  [maxWidth]="maxWidth" [maxHeight]="maxHeight"></app-firing-graph>
  </div>
  <div *ngIf="viewingKilnlinkFreeKilns && !notConnected">You are viewing a KilnLink Free kiln. Upgrade today to get detailed firing graphs.</div>
  <div *ngIf="!viewingKilnlinkFreeKilns && !notConnected && (!kiln || (kiln && !testData) || (testData && !testData.statuses || (testData.statuses.length === 0)))" id="graph-not-available">
    The graph for this kiln is not available at this time.
  </div>
</div>
